import React, { useState } from 'react';
import Header from '../components/layout/Header'
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import 'animate.css';
import SocialMediaAppDevelopment from '../assets/images/SocialMediaAppDevelopment.png';
import customsocialnetworking from '../assets/images/customsocialnetworking.png';
import instantmessaging from '../assets/images/instantmessaging.png';
import socialgaming from '../assets/images/socialgaming.png';
import mediasharing from '../assets/images/mediasharing.png';
import OurSocialNetworkingAppDevelopment from '../assets/images/OurSocialNetworkingAppDevelopment.png';
import communityappdevelopment from '../assets/images/communityappdevelopment.png';
import datingappdevelopment from '../assets/images/datingappdevelopment.png';
import socialnetworking from '../assets/images/socialnetworking.png';
import messagingapp from '../assets/images/messagingapp.png';
import mediasharings from '../assets/images/mediasharings.png';
import easysignup from '../assets/images/easysignup.png';
import contentposting from '../assets/images/contentposting.png';
import arfilters from '../assets/images/arfilters.png';
import mediaediting from '../assets/images/mediaediting.png';
import reviewmechanism from '../assets/images/reviewmechanism.png';
import realtimechat from '../assets/images/realtimechat.png';
import SocialMediaAppFeaturesddd from '../assets/images/SocialMediaAppFeaturesddd.png';
import smartsearches from '../assets/images/smartsearches.png';
import advertismentplacement from '../assets/images/advertismentplacement.png';
import performanceanalysis from '../assets/images/performanceanalysis.png';
import streaminglive from '../assets/images/streaminglive.png';
import socialecommerce from '../assets/images/socialecommerce.png';
import paymentintegrationdf from '../assets/images/paymentintegrationdf.png';
import transparencyprocess from '../assets/images/transparencyprocess.png';
import agiledevelopmentapproachas from '../assets/images/agiledevelopmentapproachas.png';
import competitivepricing from '../assets/images/competitivepricing.png';
import highcustomersatisfactiondfg from '../assets/images/highcustomersatisfactiondfg.png';
import integritytransparency from '../assets/images/integritytransparency.png';
import flexibleengagement from '../assets/images/flexibleengagement.png';
const SocialNetworking = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What is the cost of building a social media app?",
            answer:
                "Logistics software development is the solution to automate shipping and freight forwarding activities and allow transport companies to simplify freight operations, save time, reduce costs and improve customer services. A widely used solution for this is a transportation management system (TMS).",
            open: false
        },
        {
            question: "Can you build a social media app for both iOS and Android?",
            answer: "The four types of logistics are inbound logistics, outbound logistics, reverse logistics, and third-party logistics (3PL) or fourth-party logistics (4PL).",
            open: false
        },
        {
            question:
                "How do you ensure the confidentiality of my app idea?",
            answer: "It provides more transparency to the supply chain:1. Offering a unified platform for fleet management",
            open: false
        },
        {
            question:
                "What platforms does BitFront support for social media app development?",
            answer: "Yes, we do provide assistance to upgrade your existing system with our latest solutions.",
            open: false
        },
        {
            question:
                "Does BitFront offer customization of social media apps?",
            answer: "Yes we kept it confidential and provide assurance for the same.",
            open: false
        },
        {
            question:
                "How long does it take to create a mobile app for social media?",
            answer: "The final cost of developing a logistics software depends on various factors. However, usually, the average cost of logistics software and mobile app development ranges between $20,000 to $500,000.",
            open: false
        },
        {
            question:
                "Why choose BitFront for your social media app development project?",
            answer: "The time required to develop a logistics software system varies based on factors like technology stack, front and back end development processes, application type, platform, team size, and more",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div>
            <Header />
            <div className="max-w-7xl h-[580px]	 bg-white rounded-2xl m-auto mt-[40px] ">
                <div className="grid grid-cols-2 items-center gap-[5rem] justify-center p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]">Social Media</span> App Development</h1>
                        <p className="text-[18px]">
                            Our team of social media app experts excels in building solutions that connect people
                            with the world to share ideas, connect, and build a community.
                        </p>
                        <li className="text-[18px] pt-[10px]">Social Media Analysis</li>
                        <li className="text-[18px] pt-[10px]">Audio-based Networking</li>
                        <li className="text-[18px] pt-[10px]">Instant Messaging</li>
                        <li className="text-[18px] pt-[10px]">Social Gaming App Development</li>
                        <li className="text-[18px] pt-[10px]">Video-Based Apps</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[250px] mt-10 h-[42px]">TALK TO OUR DEVELOPERS</button>
                    </div>
                    <img alt='Social Media App Development' className="w-[446px] h-[414px]" src={SocialMediaAppDevelopment} />
                </div>
            </div>

            <div className="h-[148.8px] ml-[300px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>

            <div className="w-[1100px] m-auto">
                <div className="grid grid-cols-2 justify-center items-center gap-[5rem]">
                    <div>
                        <h1 className="text-[40px] font-semibold">Our <span className="text-[#0652dd]">Social Media</span> App Development Services</h1>
                        <p className="space-y-5 mb-[20px] text-[18px] text-[#192734]">
                            From custom social networking website design to an extensive development process, our social app developers
                            are skilled in connecting the world with a suite of services.
                        </p>
                        <p className="space-y-5 text-[18px] text-[#192734]">
                            BitFront stands out as a leading firm in social media app development. We've created highly successful and
                            innovative social media apps for various needs. Our focus on users and advanced technology has earned us
                            recognition as a trusted agency for social media app development in India. Our approach benefits both users
                            and business owners alike, delivering impactful results. Let us assist you in the future of social media
                            interaction and increase your digital presence.
                        </p>
                    </div>
                    <div>
                        <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#988c33] border-[2px]">
                            <div className="flex items-center gap-1">
                                <img alt='custom social networking' src={customsocialnetworking} />
                                <h4 className=" text-[20px] font-bold">Custom Social Networking</h4>
                            </div>
                            <p className="text-[17px] pl-2">
                                We specialize in creating social networking apps that enable secure, real-time connections among users. Additionally, our team of social media app developers
                                integrates a robust mechanism for non-intrusive revenue generation, ensuring profitability from day one.
                            </p>
                        </div>
                        <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#339882] border-[2px]">
                            <div className="flex items-center gap-1">
                                <img alt='instant messaging' src={instantmessaging} />
                                <h4 className=" text-[20px] font-bold">Instant Messaging</h4>
                            </div>
                            <p className="text-[17px] pl-2">
                                The digital-age messaging architecture has made person-to-person communication engaging and real-time.
                                At BitFront, we design messaging apps on strict encryption modules packed with features like media and
                                document sharing, video and voice calls, group messaging, and more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center ">
                <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#984133] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='social gaming' src={socialgaming} />
                        <h4 className=" text-[20px] font-bold">Social Gaming</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We excel at creating microgames that can be played on social media platforms. Our team of social media app developers
                        makes it easy to play games with others on the platform, share the scores on their profiles, and invite friends.
                        We help clients build lightweight, interactive games that are both enjoyable to play and share with friends.
                    </p>
                </div>
                <div className=" ml-[40px] mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#337898] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='media sharing' src={mediasharing} />
                        <h4 className=" text-[20px] font-bold">Media Sharing</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        At BitFront, we've developed apps similar to Instagram and Pinterest, simplifying media sharing—both images and
                        videos—among users on the platform. Our applications facilitate users in exploring their creativity and sharing
                        it securely with the world on a hack-proof platform.
                    </p>
                </div>
            </div>

            <h1 className=" w-[490px] m-auto text-[35px] font-semibold text-center mt-10">Our <span className="text-[#0652dd]">Social Networking</span> App Development Solutions</h1>
            <p className="text-[18px] w-[1340px] m-auto font-medium text-center">Our social networking app development solutions can expand your business network, enhance brand credibility,
                boost conversion rates and revenue, and improve customer service and satisfaction.</p>



            <div className="flex justify-center items-center gap-[5rem] mt-[40px]">
                <img alt='Our Social Networking App Development' className="w-[624px] h-[592px]" src={OurSocialNetworkingAppDevelopment} />
                <div>
                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#afe5a6] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='community app development' src={communityappdevelopment} />
                            <h1 className="text-[20px] font-medium">Community App Development</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#a6e5e3] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='dating app development' src={datingappdevelopment} />
                            <h1 className="text-[20px] font-medium">Dating & Matrimony App Development</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#a6b7e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='social networking' src={socialnetworking} />
                            <h1 className="text-[20px] font-medium">Social Networking App Development</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#c0a6e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='messaging app' src={messagingapp} />
                            <h1 className="text-[20px] font-medium">Messaging App Development</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#e5a6aa] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='media sharings' src={mediasharings} />
                            <h1 className="text-[20px] font-medium">Media Sharing App Development</h1>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className=" mt-8 text-[38px] font-semibold text-center"><span className="text-[#0652dd]">Social Media </span>
                App Features</h1>
            <p className="text-[18px] font-medium text-center m-auto w-[930px]">What makes us a reliable social media app development
                agency is that we carry extensive knowledge of what differentiates an average social media app from a viral one.
                We then pack that knowledge into your app as inbuilt features.</p>
            <div className="flex justify-center gap-[5rem] mt-[60px]">
                <div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='easy signup' src={easysignup} />
                        <h1 className="text-[18px] font-medium">
                            Easy sign-up</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='content posting' src={contentposting} />
                        <h1 className="text-[18px] font-medium">
                            Multi-format content posting</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='arfilters' src={arfilters} />
                        <h1 className="text-[18px] font-medium">
                            AR-filters</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='media editing' src={mediaediting} />
                        <h1 className="text-[18px] font-medium">
                            In-app media editing</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='review mechanism' src={reviewmechanism} />
                        <h1 className="text-[18px] font-medium">
                            Stringent content review mechanism</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='real time chat' src={realtimechat} />
                        <h1 className="text-[18px] font-medium">Real-time chat with friends</h1>
                    </div>
                </div>
                <img alt='Social Media App Featuresddd' className="w-[416px] h-[379px]" src={SocialMediaAppFeaturesddd} />

                <div className="w-[400px]">
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='smartsearches' src={smartsearches} />
                        <h1 className="text-[18px] font-medium">
                            Smart search</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='advertismentplacement' src={advertismentplacement} />
                        <h1 className="text-[18px] font-medium">
                            Non-disruptive advertisement placement</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='performanceanalysis' src={performanceanalysis} />
                        <h1 className="text-[18px] font-medium">AI-based performance analysis</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='streaminglive' src={streaminglive} />
                        <h1 className="text-[18px] font-medium">
                            Live streaming</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='socialecommerce' src={socialecommerce} />
                        <h1 className="text-[18px] font-medium">Social eCommerce</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='paymentintegrationdf' src={paymentintegrationdf} />
                        <h1 className="text-[18px] font-medium">Payment integration</h1>
                    </div>
                </div>
            </div>



            <h1 className="font-bold text-[40px] text-center mt-[20px]">
                <span className="text-[#0652dd]">Social Media</span> App Development Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 w-[1100px]">
                Every element of your social media application development services process is backed by market research and customers’ needs.
            </p>
            <div className="flex gap-1 items-center justify-center mt-8">
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
            </div>

            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className=" flex justify-center items-center gap-[5rem] mt-[30px]">
                <div className="w-[600px]">
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        Our combination of multi-skill expertise and in-depth knowledge of the social media sector makes us one of the go-to social media app development companies.
                    </p>
                </div>
                <div>

                    <div className="flex gap-[5rem]">
                        <div className="w-[300px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='transparencyprocess' src={transparencyprocess} />
                                <h1 className="text-[18px] font-medium">100% Customer Satisfaction</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='agiledevelopmentapproachas' src={agiledevelopmentapproachas} />
                                <h1 className="text-[18px] font-medium">Agile Process</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='competitivepricing' src={competitivepricing} />
                                <h1 className="text-[18px] font-medium">Competitive Pricing</h1>
                            </div>
                        </div>


                        <div className="w-[325px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='highcustomersatisfactiondfg' src={highcustomersatisfactiondfg} />
                                <h1 className="text-[18px] font-medium">Dedicated Teams</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='integritytransparency' src={integritytransparency} />
                                <h1 className="text-[18px] font-medium">Integrity & Transparency</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='flexibleengagement' src={flexibleengagement} />
                                <h1 className="text-[18px] font-medium">Flexible Engagement Models</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" mt-[40px] m-auto p-[40px] w-[1320px] h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[20px] font-semibold text-[#fff]">Want to expand your business with our custom Social Media App Development Services?</h1>
            </div>


            <h1 className="text-[40px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="emergeSite.png" src="/images2/emergeSite.png"></img>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SocialNetworking
