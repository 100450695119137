import React, { useState } from 'react';
import Header from '../components/layout/Header'
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import 'animate.css';
import Customized from '../assets/images/Customized.png';
import Savetime from '../assets/images/Savetime.png';
import ReduceCosts from '../assets/images/ReduceCosts.png';
import Lowrisk from '../assets/images/Lowrisk.png';
import Customization from '../assets/images/Customization.png';
import Costeffective from '../assets/images/Costeffective.png';
import EnhancedEfficiency from '../assets/images/EnhancedEfficiency.png';
import NoRisks from '../assets/images/NoRisks.png';
import Adaptability from '../assets/images/Adaptability.png';
import FastImplementation from '../assets/images/FastImplementation.png';
import Maintenance from '../assets/images/Maintenance.png';
import EHREMRSoftwareDevelopment from '../assets/images/EHREMRSoftwareDevelopment.png';
import telemedicineappdevelopment from '../assets/images/telemedicineappdevelopment.png';
import Sportsfitness from '../assets/images/Sportsfitness.png';
import jobportalwebsite from '../assets/images/jobportalwebsite.png';
import TaxiBooking from '../assets/images/TaxiBooking.png';
import portfoliobnr from '../assets/images/portfoliobnr.png';
import ChatAppFeatures from '../assets/images/ChatAppFeatures.png';
import Eweb from '../assets/images/Eweb.png';
import LMSIntegrations from '../assets/images/LMSIntegrations.png';
import panel from '../assets/images/panel.png';
import MobileSolutions from '../assets/images/MobileSolutions.png';
import ProtoverifyApp from '../assets/images/ProtoverifyApp.png';
const CustomizableApp = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What is a customised app?",
            answer:
                "Custom app development is designing software applications for users in an organization to fulfill specific business needs. Designing and developing your custom app focuses on specific needs instead of using more conventional and standard software.",
            open: false
        },
        {
            question: "Can I create my app?",
            answer: "If you're a programmer or you can code, you can create mobile applications independently, using online resources to upskill yourself. If you can't code, you can use a no-code app maker or an app development company like BitFront.",
            open: false
        },
        {
            question:
                "What are the benefits of using customizable app solutions?",
            answer: "Increased efficiency and productivity, improved user experience, cost savings, better adaptability to changing needs, and competitive advantage.",
            open: false
        },
        {
            question:
                "What are some examples of customizable app solutions?",
            answer: "Learning Management Systems (LMS), Job Portals/Hiring Applications, EHR/EMR Software, Practice Management Software, Fitness Apps, Telemedicine Apps, Payroll Management Software, and many more.",
            open: false
        },
        {
            question:
                "What level of customization can I expect?",
            answer: "It's different for each solution provider and plan. Some offer basic adjustments like branding and workflows, while others allow extensive modifications to features and functionality.",
            open: false
        },
        {
            question:
                "How easy is it to customize the app?",
            answer: "The ease of customization generally depends on the solution. Some offer user-friendly interfaces and drag-and-drop features, while others require technical expertise.",
            open: false
        },
        {
            question:
                "What should I consider when choosing a level of customization?",
            answer: "Your technical skills, budget, specific needs, and future growth potential.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div>
            <Header />

            <div className="max-w-7xl h-auto bg-white rounded-2xl m-auto mt-[40px] ">
                <div className="grid grid-cols-2 items-center gap-[5rem] justify-center p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">Customized App
                            <span className="text-[#0652dd]"> Solutions for Businesses</span></h1>
                        <p className="text-[18px]">
                            Custom business software applications are a way to take your idea and bring it to life, working with our
                            team of expert developers to design a solution that fits your needs without compromising on features or
                            functionality.
                        </p>
                        <li className="text-[18px] pt-[10px]">Project planning</li>
                        <li className="text-[18px] pt-[10px]">Choosing tech stack</li>
                        <li className="text-[18px] pt-[10px]">UI/UX</li>
                        <li className="text-[18px] pt-[10px]">Programming</li>
                        <div className=" mt-[30px] flex items-center gap-[2rem]">
                            <div>
                                <img alt='Customized' className=" w-[40px] h-[40px] bg-[#ccfde4] rounded-full" src={Customized} />
                                <h1>100% Customized</h1>
                            </div>
                            <div>
                                <img alt='Savetime' className=" w-[40px] h-[40px] bg-[#ccfde4] rounded-full" src={Savetime} />
                                <h1>Save time up to 70%</h1>
                            </div>
                            <div>
                                <img alt='ReduceCosts' className=" w-[40px] h-[40px] bg-[#ccfde4] rounded-full" src={ReduceCosts} />
                                <h1>Reduce Costs by 50%</h1>
                            </div>
                            <div>
                                <img alt='Lowrisk' className=" w-[40px] h-[40px] bg-[#ccfde4] rounded-full" src={Lowrisk} />
                                <h1>Low-risk factor</h1>
                            </div>
                        </div>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[120px] mt-10 h-[42px]">LETS TALK</button>
                    </div>
                    <img alt='buildfire' className="w-[603px] h-[450px]" src="https://buildfire.com/wp-content/themes/buildfire/assets/images/custom-app-hero@3x.png" />
                </div>
            </div>
            <div className="max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <h1 className="text-[32px] font-semibold ">Why choose our Customizable <span className="text-[#0652DD]">Software/App Solutions?</span></h1>
                        <img alt='zorbis' className="w-[636px] h-[655px]" src="https://www.zorbis.com/images/app-development.jpg" />
                    </div>
                    <div>
                        <div className=" mb-[16px] p-[10px] w-[636px] h-[88px] border-[1px] border-[#0652dd] rounded-[10px]">
                            <div className="flex items-center">
                                <img alt='Customization' className="w-[50px] h-[50px]" src={Customization} />
                                <h1 className=" ml-[10px] text-[20px] font-semibold">100% Customization</h1>
                            </div>
                        </div>
                        <div className=" mb-[16px] p-[10px] w-[636px] h-[88px] border-[1px] border-[#0652dd] rounded-[10px]">
                            <div className="flex items-center">
                                <img alt='Costeffective' className="w-[50px] h-[50px]" src={Costeffective} />
                                <h1 className=" ml-[10px] text-[20px] font-semibold">Cost-effective</h1>
                            </div>
                        </div>
                        <div className=" mb-[16px] p-[10px] w-[636px] h-[88px] border-[1px] border-[#0652dd] rounded-[10px]">
                            <div className="flex items-center">
                                <img alt='EnhancedEfficiency' className="w-[50px] h-[50px]" src={EnhancedEfficiency} />
                                <h1 className=" ml-[10px] text-[20px] font-semibold">Enhanced Efficiency</h1>
                            </div>
                        </div>
                        <div className=" mb-[16px] p-[10px] w-[636px] h-[88px] border-[1px] border-[#0652dd] rounded-[10px]">
                            <div className="flex items-center">
                                <img alt='NoRisks' className="w-[50px] h-[50px]" src={NoRisks} />
                                <h1 className=" ml-[10px] text-[20px] font-semibold">No Risks</h1>
                            </div>
                        </div>
                        <div className=" mb-[16px] p-[10px] w-[636px] h-[88px] border-[1px] border-[#0652dd] rounded-[10px]">
                            <div className="flex items-center">
                                <img alt='Adaptability' className="w-[50px] h-[50px]" src={Adaptability} />
                                <h1 className=" ml-[10px] text-[20px] font-semibold">Adaptability</h1>
                            </div>
                        </div>
                        <div className=" mb-[16px] p-[10px] w-[636px] h-[88px] border-[1px] border-[#0652dd] rounded-[10px]">
                            <div className="flex items-center">
                                <img alt='FastImplementation' className="w-[50px] h-[50px]" src={FastImplementation} />
                                <h1 className=" ml-[10px] text-[20px] font-semibold">Fast Implementation</h1>
                            </div>
                        </div>
                        <div className=" mb-[16px] p-[10px] w-[636px] h-[88px] border-[1px] border-[#0652dd] rounded-[10px]">
                            <div className="flex items-center">
                                <img alt='Maintenance' className="w-[50px] h-[50px]" src={Maintenance} />
                                <h1 className=" ml-[10px] text-[20px] font-semibold">Maintenance</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[95px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">EHR/EMR</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable EHR/EMR Software</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            We design custom EHR/EMR Software solutions with features for scheduling appointments,
                            managing patient-doctor communication, and more. Our portals also allow healthcare providers
                            to classify diseases accurately in various health records using the latest and most detailed information.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Personalized care</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Reduced medication errors</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Streamlined clinical decision-making</p>
                        </div>
                        <button className=" bg-[#16ba7f] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='EHREMRSoftwareDevelopment' className="w-[498px] h-[411px] " src={EHREMRSoftwareDevelopment} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[130px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">Telemedicine</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable Telemedicine App</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            If you want to build a telemedicine app for your business with live streaming and an
                            online payment gateway. We have a readymade Telemedicine App designed and developed for
                            customers to book doctors and take consultations online.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">100% Customization</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Boost Efficiency by 60%</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Reduce Costs by 70%</p>
                        </div>
                        <button className=" bg-[#6191fe] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='telemedicineappdevelopment' className="w-[498px] h-[404px] " src={telemedicineappdevelopment} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[120px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">Fitness App</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable Fitness App/Fitness Tracking App</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            BitFront offers customized fitness app development solutions, building cutting-edge fitness apps that
                            offer seamless experiences and assurance, ensuring users stay engaged and motivated for sustained participation.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Seamless Delivery</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Customized Fitness Plans</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Cost-efficient</p>
                        </div>
                        <button className=" bg-[#16ba7f] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='Sportsfitness' className="w-[480px] h-[370px] " src={Sportsfitness} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[105px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">Job Portal</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable Job Portal/ Hiring Application</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            We simplify recruitment by providing a high-end & customized job portal solution integrating
                            various recruitment processes and automation functionalities. The platform is built to be
                            customizable for any workflow that the hiring team wants to implement.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Better Hiring Process</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Enhanced employer branding</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Better Data-Driven Decisions</p>
                        </div>
                        <button className=" bg-[#6191fe] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='jobportal' className="w-[603px] h-[444px] " src={jobportalwebsite} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[127px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">Taxi Booking</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable Bike & Taxi Booking App</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            Take a look at this ride-booking app, similar to Uber, Rapido & Ola, where users can easily book or
                            schedule rides. It also has options for delivery requests and tracking. You can change it to fit your
                            needs and it comes with lots of nice-looking design elements.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">100% Customization</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Boost Efficiency by 60%</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Reduce Costs by 70%</p>
                        </div>
                        <button className=" bg-[#16ba7f] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='taxibooking' className="w-[503px] h-[349px] " src={TaxiBooking} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[135px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">Food Delivery</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable Food and Grocery Delivery Application</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            A great starter kit for delivery apps, easy to use, grow, and keep up. It uses Flutter for the front
                            end of Mobile and Node.js, Express.js, TypeScript, GraphQL, and PostgreSQL for the back end. You can
                            change it to fit any delivery or service app you want.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Grocery Purchase Flow for Users</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Order Checking for Vendors</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Product Search and Vendor Selection</p>
                        </div>
                        <button className=" bg-[#6191fe] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='portfoliobnr' className=" ml-[40px] w-[507px] h-[383px] " src={portfoliobnr} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[150px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">Messaging App</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable Chat and Messaging App</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            A chat application for web and mobile that allows users to communicate with each other in real-time
                            through text, voice, or video. It can be customized to accommodate all your modern chat app requirements.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">100% Customized</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Multi-chat Support</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Mobile App Portability</p>
                        </div>
                        <button className=" bg-[#16ba7f] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='chatapp' className=" ml-[20px] w-[484px] h-[423px] " src={ChatAppFeatures} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[130px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">E-commerce</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable E-commerce Portal & E-commerce Mobile App</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            If you’re looking to build an e-commerce application designed to perform all the essential functions,
                            then don’t build it from scratch. BitFront has all the necessary features to save you hundreds of
                            development hours.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">100% Customization</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Save time up to 50%</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Save money up to 60%</p>
                        </div>
                        <button className=" bg-[#6191fe] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='Eweb' className="w-[410px] h-[402px] " src={Eweb} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[113px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">E-Learning</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable Learning Management System (LMS)</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            Our customizable Learning Management System provides organizations with flexibility, scalability,
                            enhanced engagement, advanced analytics, integration capabilities, compliance, cost-effectiveness,
                            rapid deployment, and competitive advantage, making it a valuable tool for employee development and
                            organizational growth.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Interactive Learning Tools</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Personalized Learning Paths</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Branded Interface</p>
                        </div>
                        <button className=" bg-[#16ba7f] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='LMSintegrations' className=" ml-[20px] w-[497px] h-[485px] " src={LMSIntegrations} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[60px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">HRM</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable HR Management System</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            Our All-in-One HR system helps businesses manage their employees better. It makes handling HR tasks easier,
                            like hiring, managing, training, and keeping staff happy. Get our affordable HR system to make managing your
                            workforce easy.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">100% Customized Solutions</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Scalability</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Increased Efficiency</p>
                        </div>
                        <button className=" bg-[#6191fe] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='panel' className=" ml-[20px] w-[386px] h-[381px] " src={panel} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[193px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">Payroll Management</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable Payroll Management Software</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            We make customized, full-service payroll software that handles paying employees, calculating taxes,
                            managing staff, entering pay data, and other tasks. Our software helps with calculations, payments,
                            and filings, making everything faster and simpler for you.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Faster processing</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Increased Accuracy and Efficiency</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Scalability</p>
                        </div>
                        <button className=" bg-[#16ba7f] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='mobile solutions' className=" ml-[20px] w-[324px] h-[559px] " src={MobileSolutions} />
                </div>
            </div>
            <div className=" mb-[40px] border-[1px] rounded-[15px] max-w-7xl h-auto m-auto">
                <div className="grid grid-cols-2 gap-[5rem] justify-center p-[30px]">
                    <div>
                        <div className=" p-[8px] rounded-[10px] w-[225px] h-[41px] bg-[#d1f3e7]">
                            <h1 className="text-[18px] font-medium">Background Verification</h1>
                        </div>
                        <h1 className="text-[32px] font-semibold">Customizable Employee Background Verification App</h1>
                        <p className=" mb-[10px]  text-[18px] font-medium text-[#272727]">
                            Our ready-made Employee Background Verification Application provides companies with pre-employment screening
                            solutions to verify the information provided by job applicants. It makes it easier for organizations to screen
                            new hires and volunteers. The app collects and organizes data from checks on employment, education, credit history,
                            and criminal background.
                        </p>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Help Protect Workplace Safety</p>
                        </div>
                        <div className=" mb-[10px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Reduce Your Liability</p>
                        </div>
                        <div className=" mb-[15px] flex items-center">
                            <img alt='img' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" />
                            <p className="text-[18px] font-medium ml-[10px]">Hiring Top Candidates</p>
                        </div>
                        <button className=" bg-[#6191fe] text-[14px] font-semibold rounded-full text-[#fff] w-[150px] h-[40px]">LET'S DISCUSS</button>
                    </div>
                    <img alt='protoverify' className=" ml-[20px] w-[384px] h-[403px] " src={ProtoverifyApp} />
                </div>
            </div>
            <h1 className="text-[32px] text-center font-semibold mb-[30px] ">How do we <span className="text-[#0652dd]">apply a Customized App Solutions</span></h1>
            <div className="flex justify-center gap-[5rem]">
                <div>
                    <div className=" mb-[20px] flex items-center">
                        <div className=" text-center border-[1px] border-[#000] p-[8px] rounded-full hover:border-[#0652dd] hover:bg-[#0652dd] hover:text-[#fff] w-[40px] h-[40px]">
                            <h1 className="font-semibold">01</h1>
                        </div>
                        <div className="ml-[20px]">
                            <h1 className="text-[18px] font-semibold">Consulting</h1>
                            <p className="text-[17px]">Choosing the BEST solution for YOUR business</p>
                        </div>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <div className=" text-center border-[1px] border-[#000] p-[8px] rounded-full hover:border-[#0652dd] hover:bg-[#0652dd] hover:text-[#fff] w-[40px] h-[40px]">
                            <h1 className="font-semibold">02</h1>
                        </div>
                        <div className="ml-[20px]">
                            <h1 className="text-[18px] font-semibold">Estimate</h1>
                            <p className="text-[17px]">Counting cost and time efforts</p>
                        </div>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <div className=" text-center border-[1px] border-[#000] p-[8px] rounded-full hover:border-[#0652dd] hover:bg-[#0652dd] hover:text-[#fff] w-[40px] h-[40px]">
                            <h1 className="font-semibold">03</h1>
                        </div>
                        <div className="ml-[20px]">
                            <h1 className="text-[18px] font-semibold">Development</h1>
                            <p className="text-[17px]">Project development based on modules</p>
                        </div>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <div className=" text-center border-[1px] border-[#000] p-[8px] rounded-full hover:border-[#0652dd] hover:bg-[#0652dd] hover:text-[#fff] w-[40px] h-[40px]">
                            <h1 className="font-semibold">04</h1>
                        </div>
                        <div className="ml-[20px]">
                            <h1 className="text-[18px] font-semibold">Equipment</h1>
                            <p className="text-[17px]">Assistance with choosing and integrating equipment</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className=" mb-[20px] flex items-center">
                        <div className=" text-center border-[1px] border-[#000] p-[8px] rounded-full hover:border-[#0652dd] hover:bg-[#0652dd] hover:text-[#fff] w-[40px] h-[40px]">
                            <h1 className="font-semibold">05</h1>
                        </div>
                        <div className="ml-[20px]">
                            <h1 className="text-[18px] font-semibold">Support</h1>
                            <p className="text-[17px]">Further technical and business support</p>
                        </div>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <div className=" text-center border-[1px] border-[#000] p-[8px] rounded-full hover:border-[#0652dd] hover:bg-[#0652dd] hover:text-[#fff] w-[40px] h-[40px]">
                            <h1 className="font-semibold">06</h1>
                        </div>
                        <div className="ml-[20px]">
                            <h1 className="text-[18px] font-semibold">Launch</h1>
                            <p className="text-[17px]">Product launch</p>
                        </div>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <div className=" text-center border-[1px] border-[#000] p-[8px] rounded-full hover:border-[#0652dd] hover:bg-[#0652dd] hover:text-[#fff] w-[40px] h-[40px]">
                            <h1 className="font-semibold">07</h1>
                        </div>
                        <div className="ml-[20px]">
                            <h1 className="text-[18px] font-semibold">Education</h1>
                            <p className="text-[17px]">Staff training on how to use the system</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="App">
            <h1 className="text-[40px] w-[700px] m-auto font-bold">FAQs</h1>
                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CustomizableApp
