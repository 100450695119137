import React from 'react'

const SAM = () => {
    return (
        <div>
            <div className="p-1">
                <h1 className="xl:text-[30px] lg:text-[30px] md:text-[20px] text-[30px] font-semibold text-[#192734] leading-10 mb-5">Many times, one focuses only on the development, but it is the maintenance that keeps it going!</h1>
                <p className="xl:text-[17px] lg:text-[17px] md:text-[16px] text-[16px] text-[#4c5a67] font-normal mb-[30px]">We aspire to keep your software/app running with 100% efficiency. And for that, post-launch service is as crucial as quality development. We understand your system,
                    source codes so identifying glitches becomes faster and scaling up becomes easy and cost-efficient.</p>
                <div className="grid lg:grid-cols-2 gird sm:grid-cols-1">
                    <div className='space-y-5'>
                        <div className="flex items-center">
                            <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
                            <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Modifications & Technical Improvements</h1>
                        </div>
                        <div className="flex items-center">
                            <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
                            <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Performance Improvements</h1>
                        </div>
                    </div>
                    <div className='space-y-5 ld:mt-0 md:mt-4 mt-4'>
                        <div className="flex items-center">
                            <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
                            <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Bug & Glitch Removal</h1>
                        </div>
                    </div>
                </div>
                <button className=" font-medium w-[200px] h-[50px] border-[1px] border-[#0652dd] bg-[#0652dd] text-[#fff] rounded-[5px] mt-11">Get More Info</button>
            </div>
        </div>
    )
}

export default SAM;
