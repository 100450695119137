import React, { useState } from 'react';
import Header from "../components/layout/Header";
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import ReactNativeApp from '../assets/images/ReactNativeApp.png';
import TopReactNativeApp from '../assets/images/TopReactNativeApp.png';
import ReactNativemobileappdevelopmentconsultation from '../assets/images/ReactNativemobileappdevelopmentconsultation.png';
import ReactNativeappUIUXdesign from '../assets/images/ReactNativeappUIUXdesign.png';
import ReactNativeapplicationdevelopment from '../assets/images/ReactNativeapplicationdevelopment.png';
import ReactNativeappmigration from '../assets/images/ReactNativeappmigration.png';
import hhelth from '../assets/images/hhelth.png';
import EcommerceSolutions from '../assets/images/EcommerceSolutions.png';
import networking from '../assets/images/networking.png';
import banking from '../assets/images/banking.png';
import Travel from '../assets/images/Travel.png';
import Media from '../assets/images/Media.png';
import EntertainmentWebSolutions from '../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../assets/images/EdutechSolutions.png';
const ReactDevelopment = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/Contact-us');
  };
  const [faqs, setFaqs] = useState([
    {
      question: "Custom-Based Solutions",
      answer:
        "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
      open: false
    },
    {
      question: "Industrial Experts",
      answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
      open: false
    },
    {
      question: "Seamless Communication",
      answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
      open: false
    },
    {
      question: "Latest Technology",
      answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
      open: false
    },

  ]);
  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const categories = [
    { alt: "Healthcare", src: hhelth, title: "Healthcare" },
    { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
    { alt: "Social networking", src: networking, title: "Social networking" },
    { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
    { alt: "Travel", src: Travel, title: "Travel" },
    { alt: "Media", src: Media, title: "Media" },
    { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
    { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
  ];
  const reviews = [
    {
      rating: 5.0,
      reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
      author: "Founder & CEO, Larrea Wealth Management",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
      author: "Co-Founder & CEO, FLOHOM",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
      author: "Founder & CEO, FilmUp",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
      author: "Founder, Level7 Legal",
      verified: "Verified Review"
    }
  ];
  return (
    <div>
      <Header />
      <div className="max-w-7xl h-[580px]	 bg-white rounded-2xl m-auto mt-[80px] ">
        <div className="flex justify-center gap-[10rem] p-[20px]">
          <div>
            <h1 className="text-[40px] font-bold">
              <span className="text-[#0652dd]">React Native App</span><br /> Development</h1>
            <p className="text-[18px]">
              Make your business grow faster and get noticed with our good<br />
              React Native app services. Our team of React Native developers<br />
              creates custom apps that work on both iPhone and Android,<br />
              designed specifically to meet your business needs.
            </p>
            <li className="text-[18px] pt-[10px]">React Native Consulting</li>
            <li>React Native Development</li>
            <li>React Native Migration</li>
            <li>React Native Team Augmentation</li>
            <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd]	border-2 w-[200px] mt-10 h-[40px]">TALK TO OUR EXPERT</button>
          </div>
          <img alt='React Native App' className="w-[455px] h-[457px]" src={ReactNativeApp} />
        </div>
      </div>
      <div className="h-[148.8px] ml-[300px] mt-[80px] overflow-hidden w-[1300px]">
        <div className="flex items-center gap-2">
          <div className="w-24 h-0.5 mb-px bg-black"></div>
          <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
        </div>
        <div className="cont-no">
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
          <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
          <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
        </div>
      </div>
      <div className="w-[1900px] h-[622px] bg-white m-auto mt-[30px]">
        <div className="flex justify-center items-center gap-[10rem] pt-[35px]">
          <div>
            <h1 className="text-[30px] font-bold">
              Top <span className="text-[#0652dd]">React Native App<br />
                Development</span> Company in India
            </h1>
            <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
              At BitFront, our dedicated team of seasoned <b>React Native developers</b> is <br />
              committed to crafting robust and reliable <b>React Native applications</b><br />
              tailored to your needs. As the premier <b>React native</b> <span className="text-[#0652dd] font-bold">mobile app<br />development company in India
              </span>, we pride ourselves on our ability to handle<br />
              projects of any scale, transforming conceptual app ideas into fully functional<br />
              and efficient React Native applications. By leveraging the versatility of React<br />
              Native, we deliver top-quality, cross-platform applications that not only<br />
              streamline your operations but also elevate your user engagement to new heights.
            </p>
            <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
              With <b>BitFront as your trusted partner</b>, you can expect meticulous attention<br />
              to detail, a collaborative approach to development, and a relentless pursuit<br />
              of excellence. Let us empower your business with innovative <b>
                React Native<br />solutions
              </b> that drive growth and set you apart in today's competitive landscape.
            </p>
            <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
              Choose a <b>trusted React Native development company</b> for your next<br />
              project. We deliver quality and efficiency. <b>Let's build together</b>.
            </p>
          </div>
          <img alt='Top React Native App' className="w-[450px] h-[350px]" src={TopReactNativeApp} />
        </div>
      </div>
      <div className="flex justify-center gap-10 mt-[29px]">
        <div>
          <h1 className="text-5xl font-bold">Our <span className="text-[#0652dd]">React Native App</span><br />Development Services</h1>
          <p className="text-[18px] leading-[29px] py-[10px]">
            With our experience in React Native app development, our agile developers<br />
            can create strong and scalable apps with great user experiences for all<br />
            digital platforms. Our React Native developers are recognized as top-notch<br />
            mobile app experts, ensuring exceptional quality and performance in every<br /> project.
          </p>
          <p className="text-[18px] leading-[29px] py-[10px]">
            Being a reliable React Native app development company, we excel in<br />
            creating React Native apps from the ground up or seamlessly integrating<br />
            the framework into your existing applications. Our focus is on speeding up<br />
            your app delivery process without expanding costs. We offer personalized<br />
            and competitive React Native application development services tailored to<br />
            meet your specific business requirements.
          </p>
          <button className="w-[250px] h-[40px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
            <b>TALK TO OUR DEVELOPERS</b></button>
        </div>
        <div>
          <div className=" mb-[20px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
            <div className="flex items-center gap-1">
              <img
                alt='React Native mobile app development consultation'
                className="w-6 h-9"
                src={ReactNativemobileappdevelopmentconsultation} />
              <h4 className=" text-[20px] font-bold">React Native mobile app development<br />
                consultation</h4>
            </div>
            <p className="text-[17px] pl-2">
              Businesses of all sizes and sectors are opting for React Native in their digitalization
              endeavors. Our team of consultants works closely with you to grasp your business
              goals and integrate React Native capabilities accordingly. Throughout our
              consultancy process, we ensure your app is primed for market success right
              from the start.
            </p>
          </div>

          <div className="mb-[20px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
            <div className="flex items-center gap-1">
              <img alt='React Native app UI-UX design' className="w-9 h-9" src={ReactNativeappUIUXdesign} />
              <h4 className=" text-[20px] font-bold">React Native app UI/UX design</h4>
            </div>
            <p className="text-[17px] pl-2">
              Making sure an app works smoothly on various platforms with just one codebase requires
              a skilled team proficient in designing user experiences. At BitFront, we use the
              latest design tools and methods to create compelling experiences that turn browsers
              into buyers quickly.
            </p>
          </div>

          <div className="mb-[20px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
            <div className="flex items-center gap-1">
              <img alt='React Native application development' className="w-9 h-9" src={ReactNativeapplicationdevelopment} />
              <h4 className=" text-[20px] font-bold">React Native application development</h4>
            </div>
            <p className="text-[17px] pl-2">
              We're a globally recognized React Native app development company, renowned for crafting
              secure, scalable multi-platform applications. Our team possesses deep expertise in the
              framework, enabling us to create solutions that run seamlessly across various platforms,
              including smartphones, desktops, wearables, and smart home devices.
            </p>
          </div>

          <div className="w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
            <div className="flex items-center gap-1">
              <img alt='React Native app migration' className="w-9 h-9" src={ReactNativeappmigration} />
              <h4 className=" text-[20px] font-bold">React Native app migration</h4>
            </div>
            <p className="text-[17px] pl-2">
              At BitFront, we understand the challenge of outdated software. Our team of React
              Native mobile app developers specializes in migrating existing apps to the React
              Native framework, ensuring seamless performance across platforms. Our migration
              experts ensure a smooth transition with no runtime issues
            </p>
          </div>
        </div>
      </div>
      <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
        <div className="w-full h-auto bg-[#fafafa] p-5">
          <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
            BitFront Reviews 5.0
            <span className="text-[#e62415] flex items-center">
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
            </span>
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-7">
            {reviews.map((review, index) => (
              <div key={index} className="w-full md:w-72 h-auto shadow-md bg-white rounded-lg p-6">
                <p className="text-lg flex gap-2 items-center">
                  {review.rating}
                  <span className="text-[#e62415] flex items-center">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </span>
                </p>
                <p className="text-base pt-4">
                  "{review.reviewText}"
                  <br /><br />
                  {review.author}
                  <br /><br />
                  {review.verified}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
        <span className="text-[#0652dd]">React Native App Development</span>
        Process</h1>
      <p className="text-[18px] text-center mt-4">
        We're the go-to React Native app development company. Our thorough development process ensures market-ready success from the start. With up-to-date<br />
        features and room for growth, we guarantee future-proof software.
      </p>
      <div className="flex gap-1 items-center justify-center mt-8">
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
      </div>
      <h1 className="text-5xl text-center mt-15 font-bold mt-9">Our <span className="text-[#0652dd]"> Multi-industry</span> Experience</h1>
      <br />
      <p className="text-lg text-center">
        Our React Native experts build apps that feel just like native ones. Businesses trust us to bring their ideas to life across multiple platforms.
      </p>
      <div className="flex flex-wrap justify-center items-center gap-7">
        {categories.map((category, index) => (
          <div
            key={index}
            className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
          >
            <div className="py-10 flex justify-center">
              <img alt={category.alt} src={category.src} width="60" height="60" />
            </div>
            <h4 className="text-center">{category.title}</h4>
          </div>
        ))}
      </div>
      <h1 className="text-center text-4xl mt-12 font-bold">
        <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
      <div className="flex justify-center gap-9 mt-11 ">
        <div>
          <h1 className="text-3xl font-bold">
            <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
            <span className="text-[#0652dd]">Digital Success Works</span>
          </h1>
          <p className="text-lg">
            With a proven track record in React Native app development,<br />
            we're the perfect fit for your project. Here's why:
          </p>
          <br />
          <h5 class="text-lg flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Global quality standards.</h5>
          <br />
          <h5 class="text-lg flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Monthly/yearly hiring.</h5>
          <br />
          <h5 class="text-lg flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Flexible office hours depend on the timezone.</h5>
          <br />
          <h5 class="text-lg flex items-center gap-2  ">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Daily/weekly/monthly reporting via email.</h5>
        </div>
        <div>

          <div className="App">

            <div className="faqs">
              {faqs.map((faq, index) => (
                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div class="bg-blue-700 w-3/4 h-48 mx-auto text-white text-center pt-5 rounded-lg mt-10">
        <h3 class="text-center text-3xl font-bold">How much does it cost to make a Flutter App?</h3>
        <p class="text-lg">
          On average, the React Native app development cost ranges from $10,000 to $300,000, depending
          on app complexity, advanced features, and third-<br />party integrations.</p>
        <div>
          <button onClick={handleClick} class="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[10px]"><b>Contact Us</b></button>
        </div>
      </div>

      <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
        <img className="h-auto w-full rounded-[40px]" alt="emergeSite.png" src="/images2/emergeSite.png"></img>
        <div className="w-[344px] absolute top-[83px] right-[134px]">
          <p className="text-lg">
            Re-building Emerge - A finance app making it easy for financial experts to make money.
          </p>
          <div className="inline-row">
            <div>
              <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">4.4<p className="text-[#DE5B48] text-[10px] flex gap-1"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p></span>
              <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
            </div>
            <div>
              <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
              <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
            </div>
          </div>
          <h1 className="my-3">1 Million+ downloads</h1>
          <div className="flex gap-4">
            <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
              <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" /><p><b>View Case Study</b></p>
            </button>
            <img className="w-[124px] h-[36px] cursor-pointer" alt="41p.jpg" src="/images2/41p.jpg" />
          </div>
        </div>
        <div>
          <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
          <p className="my-2">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
            in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
            scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
        </div>
        <div className="flex items-center gap-4">
          <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
          <div>
            <p>Corey Smith</p>
            <p>CEO, Emerge</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ReactDevelopment;
