import React, { useState } from 'react';
import "react-phone-input-2/lib/bootstrap.css";
import Header from "../components/layout/Header";
import { IoCallSharp } from "react-icons/io5";
import { IoMailSharp } from "react-icons/io5";
import { FaSkype } from "react-icons/fa";
// import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contactus = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if any input field is empty
    if (!firstName || !lastName || !email || !phone || !description) {
      toast.error("Please fill in all fields.", {
        position: "top-right",
      });
      return;
    }

    // Handle form submission logic here
    toast.success("Message sent successfully!", {
      position: "top-right",
    });

    // Optionally, reset the form after a successful submission
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setDescription('');
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <h1 className="mt-5 xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px]  text-center font-bold">Get in Touch With Us</h1>
      <div className="xl:w-[1176px] h-auto bg-[#F9FAFA] shadow-custom rounded-[20px] p-2.5 py-2 xl:m-auto lg:m-5 mt-[40px]">
        {/* <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-5"> */}
        <div className='grid lg:grid-cols-2 gap-10 md:grid-cols-1'>
          <div className="lg:m-1 md:m-auto h-auto rounded-[20px] bg-custom-gradienwt">
            <div className="p-[50px]">
              <h1 className="text-[24px] ">Contact Information</h1>
              <h1 className="text-[#4C5A67] font-normal text-base leading-6">Fill up the form and our Team will get back to you within 24 hours.</h1>
            </div>
            <h1 className="transition-all duration-200 flex items-center gap-[15px] text-[20px] text-[#0087F5] cursor-pointer hover:text-[#498dc4] pl-[50px]">
              <IoCallSharp />+91 88662 22169
            </h1>
            <h1 className="transition-all duration-200 flex items-center gap-[15px] text-[20px] text-[#0087F5] cursor-pointer hover:text-[#498dc4] pt-7 pl-[50px]">
              <IoMailSharp />info@bitfrontinfotech.com
            </h1>
            <h1 className="transition-all duration-200 flex items-center gap-[15px] text-[20px] text-[#0087F5] cursor-pointer hover:text-[#498dc4] pt-7 pl-[50px]">
              <FaSkype />BitFront Infotech
            </h1>
            <div className="lg:w-[363px] md:w-[670px] h-[1px] bg-[#959595] m-auto mt-16"></div>
            <p class="flex gap-4 p-[40px]">
              <svg width="82" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="11" r="11" fill="#0087F5"></circle>
                <path d="M6.41797 11L9.3644 14.2083L15.1263 8.25" stroke="white" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <span className="text-[18px]">
                We guarantee 100% security of your information. We will not share the details you provide above with anyone. Your email won’t be used for spamming.
              </span>
            </p>
          </div>
          <div className="lg:ml-0 md:ml-[100px] lg:mt-0 md:mt-[20px] h-auto">
            <h1 className="text-[19px] font-bold">How do we help ?</h1>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                className="hover:shadow-custom transition-all duration-200 h-[60px] p-4 rounded-[2px]"
              />
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                className="hover:shadow-custom transition-all duration-200 h-[60px] p-4 rounded-[2px]"
              />
            </div>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail"
                className="hover:shadow-custom transition-all duration-200 h-[60px] p-4 rounded-[2px]"
              />
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone"
                className="hover:shadow-custom transition-all duration-200 h-[60px] p-4 rounded-[2px]"
              />
            </div>
            <input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Project Description"
              className="hover:shadow-custom transition-all duration-200 mt-8 h-[70px] p-4 w-full rounded-[2px]"
            />
            <div className="hover:shadow-custom transition-all duration-200 bg-white p-[30px] mt-8 h-[90px]">
              <div className="flex gap-5">
                <h1 className="text-[#929191]">Drag & Drop Files Here or</h1>
                <input type="file" className='flex w-full'/>
              </div>
            </div>
            <button
              onClick={handleSubmit}
              className="transition-all duration-200 mt-8 bg-[#0652dd] w-[200px] border-[1px] border-[#0087F5] hover:bg-white hover:text-[#0087F5] h-[60px] text-[#fff] font-bold"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className=" w-screen h-[2px] m-auto mt-10 bg-[#bfbebe] mb-[20px]"></div>
    </div>
  )
}

export default Contactus
