import React, { useState } from 'react';
import Header from '../components/layout/Header'
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import 'animate.css';
import FinancialSoftwareDevelopment from '../assets/images/FinancialSoftwareDevelopment.png';
import DigitalBanking from '../assets/images/DigitalBanking.png';
import WealthManagement from '../assets/images/WealthManagement.png';
import Insurtech from '../assets/images/Insurtech.png';
import OnlinePayments from '../assets/images/OnlinePayments.png';
import CustomFinTechSoftwareDevelopment from '../assets/images/CustomFinTechSoftwareDevelopment.png';
import FinancialAccountingReporting from '../assets/images/FinancialAccountingReporting.png';
import ComplianceManagementSolutions from '../assets/images/ComplianceManagementSolutions.png';
import FinancialPlanning from '../assets/images/FinancialPlanning.png';
import TreasuryManagement from '../assets/images/TreasuryManagement.png';
import BillingandInvoicing from '../assets/images/BillingandInvoicing.png';
import PricingManagement from '../assets/images/PricingManagement.png';
import CentralizedBillingSystems from '../assets/images/CentralizedBillingSystems.png';
import eWallets from '../assets/images/eWallets.png';
import BanklevelEncryptions from '../assets/images/BanklevelEncryptions.png';
import Microinvestments from '../assets/images/Microinvestments.png';
import MobilePayments from '../assets/images/MobilePayments.png';
import SoftwareDevelopmentFeatures from '../assets/images/SoftwareDevelopmentFeatures.png';
import TwofactorAuthentication from '../assets/images/TwofactorAuthentication.png';
import RealtimeTransactions from '../assets/images/RealtimeTransactions.png';
import DigitalLending from '../assets/images/DigitalLending.png';
import FullscaleTesting from '../assets/images/FullscaleTesting.png';
import DeliverTime from '../assets/images/DeliverTime.png';
import MultipleUseCases from '../assets/images/MultipleUseCases.png';
import CompleteComplianceCheck from '../assets/images/CompleteComplianceCheck.png';
import NextgenTechInclusion from '../assets/images/NextgenTechInclusion.png';
const Fintech = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "Why choose BitFront for mobile app development services?",
            answer:
                "Several reasons make us the chosen partner of startups and established firms. Some of the top ones are --Team of subject matter experts-Multi-industry expertise-Specialization in emerging tech-92% client retention rate-93% on-time delivery",
            open: false
        },
        {
            question: "What is the cost of mobile app development?",
            answer: "The cost of mobile app development services is dependent on a series of factors - number and complexity of features, technology incorporation, number of platforms, and location of the development agency. All these factors together decide the cost of application development.",
            open: false
        },
        {
            question:
                "How to choose a mobile app development platform?",
            answer: "Choosing a mobile app development platform is a decision that varies from ease of adoption, integration support, community, scope of features that it can support, etc.",
            open: false
        },
        {
            question:
                "How much time does custom mobile app development take?",
            answer: "The exact time to create a mobile application depends on its features, the screen sizes, and the frameworks used. Generally, it takes 2 months to around 4–5 months depending on developers, functionalities of the app, platform, and much more.",
            open: false
        },
        {
            question:
                "What solutions are provided by your mobile app development services?",
            answer: "We offer end-to-end development solutions ranging from app consultancy, design, development, QA and testing, app deployment, and maintenance.",
            open: false
        },
        {
            question:
                "What is the difference between cross-platform app development and native mobile app development?",
            answer: "The difference between native and cross-platform application development is the way it is built. While native application development revolves around creating apps for a specific platform, cross-platform development allows developers to use one codebase and deploy the app on multiple platforms.",
            open: false
        },
        {
            question:
                "Will I get post-mobile app development support?",
            answer: "Yes. For the maintenance of the mobile app, you are not required to hire a full-time programmer as we have a flexible spot, which allows you to hire our mobile app developers to provide dedicated app support and maintenance service when required.",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div>
            <Header />
            <div className="max-w-7xl h-[580px]	 bg-white rounded-2xl m-auto mt-[40px] ">
                <div className="grid grid-cols-2 items-center gap-[5rem] justify-center p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]">Financial Software</span> Development Company</h1>
                        <p className="text-[18px]">
                            We help build financial products that transform the way your users handle payments,
                            investments, and financial management. Our financial software development services
                            help you give them a strong, super safe, and adaptable solution.
                        </p>
                        <li className="text-[18px] pt-[10px]">Data Analytics</li>
                        <li className="text-[18px] pt-[10px]">Product design</li>
                        <li className="text-[18px] pt-[10px]">Data Science & AI</li>
                        <li className="text-[18px] pt-[10px]">Cloud and SaaS solutions</li>
                        <li className="text-[18px] pt-[10px]">Open API</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[250px] mt-10 h-[42px]">TALK TO OUR DEVELOPERS</button>
                    </div>
                    <img alt='FinancialSoftwareDevelopment' className="w-[413px] h-[414px]" src={FinancialSoftwareDevelopment} />
                </div>
            </div>
            <div className="h-[148.8px] ml-[300px] mt-[50px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>
            <div className="flex justify-center gap-[5rem]">
                <div className="mb-[20px] mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#43b37b] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='DigitalBanking' className="w-10 h-10" src={DigitalBanking} />
                        <h4 className=" text-[20px] font-bold">Digital Banking</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        Our fintech development services specialize in creating digital banking solutions. We make it easy
                        for banks to better their customer experience and give them accessible, affordable services. Our
                        team provides banking software development services that are 100% secure, and compliance-ready,
                        and offer omnichannel banking experience.
                    </p>
                </div>

                <div className=" mb-[20px] mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#b39d43] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='WealthManagement' className="w-10 h-10" src={WealthManagement} />
                        <h4 className=" text-[20px] font-bold">Wealth Management</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We are the FinTech app development company that specializes in creating investment-based products.
                        We create data-powered stock market and portfolio analytics, advisory systems, and automated trading
                        digital platforms. The result? We help investment firms reach untapped markets at low operational cost
                    </p>
                </div>
            </div>
            <div className="flex justify-center gap-[5rem]">
                <div className=" mb-[20px] mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#43a2b3] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='Insurtech' className="w-10 h-10" src={Insurtech} />
                        <h4 className=" text-[20px] font-bold">Insurtech</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We are the financial app development company that has helped a number of insurance firms automate
                        their business operations. We build software that brings down the cost, better the claim management
                        process, and increase the market reach through AI and ML technologies.
                    </p>
                </div>

                <div className=" mb-[20px] mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#4354b3] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='OnlinePayments' className="w-10 h-10" src={OnlinePayments} />
                        <h4 className=" text-[20px] font-bold">Online Payments</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        Our team of specialized fintech software developers deliver innovative payment solutions ranging
                        from wallets, mobile payments, POS system architecture, and B2B payment software. We carry an
                        extensive skill set in creating frictionless transaction modules, which are backed by robust
                        encryption and seamlessness.
                    </p>
                </div>
            </div>
            <h1 className="text-[40px] text-center font-bold mt-[40px]">Custom <span className="text-[#0652dd]">FinTech Software</span> Development Solutions</h1>
            <p className="text-[18px] text-center w-[1500px] m-auto">Our custom software development solutions range to banks, FinTech startups, financial institutions, insurance agencies, and stockbrokers. Our financial software
                design and development fill the gap between financial providers and digital technology.</p>
            <div className="flex justify-center items-center gap-7">
                <img alt='CustomFinTechSoftwareDevelopment' className="w-[561px] h-[472px]" src={CustomFinTechSoftwareDevelopment} />
                <div>
                    <div className=" mt-[40px] w-[624px] h-[65px] border-[#d7e5a6] border-[2px] rounded-[10px] ">
                        <div className="flex p-[10px] gap-[2rem] items-center">
                            <img alt='FinancialAccountingReporting' className="w-[40px] h-[40px]" src={FinancialAccountingReporting} />
                            <h1 className="text-[25px] font-bold">Financial Accounting and Reporting</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[624px] h-[65px] border-[#afe5a6] border-[2px] rounded-[10px] ">
                        <div className="flex p-[10px] gap-[2rem] items-center">
                            <img alt='ComplianceManagementSolutions' className="w-10 h-10" src={ComplianceManagementSolutions} />
                            <h1 className="text-[25px] font-bold">Compliance Management S. Solutions</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[624px] h-[65px] border-[#a6e5e3] border-[2px] rounded-[10px] ">
                        <div className="flex p-[10px] gap-[2rem] items-center">
                            <img alt='FinancialPlanning' className="w-10 h-10" src={FinancialPlanning} />
                            <h1 className="text-[25px] font-bold">Financial Planning and Analysis</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[624px] h-[65px] border-[#a6b7e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[10px] gap-[2rem] items-center">
                            <img alt='TreasuryManagement' className="w-10 h-10" src={TreasuryManagement} />
                            <h1 className="text-[25px] font-bold">Treasury Management</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[624px] h-[65px] border-[#c0a6e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[10px] gap-[2rem] items-center">
                            <img alt='BillingandInvoicing' className="w-10 h-10" src={BillingandInvoicing} />
                            <h1 className="text-[25px] font-bold">Billing and Invoicing</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[624px] h-[65px] border-[#e5a6aa] border-[2px] rounded-[10px] ">
                        <div className="flex p-[10px] gap-[2rem] items-center">
                            <img alt='PricingManagement' className="w-10 h-10" src={PricingManagement} />
                            <h1 className="text-[25px] font-bold">Pricing Management</h1>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className=" mt-8 text-[38px] font-semibold text-center">Top <span className="text-[#0652dd]">Financial Software</span> Development Features</h1>
            <p className="text-[18px] font-medium text-center m-auto w-[800px]">We are known as the financial software development company in India that integrates different features in apps, effortlessly.</p>
            <div className="flex justify-center mt-[60px]">
                <div className="mr-[50px]">
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='CentralizedBillingSystems' className="w-10 h-10" src={CentralizedBillingSystems} />
                        <h1 className="text-[18px] font-medium">Centralized Billing Systems</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='eWallets' className="w-10 h-10" src={eWallets} />
                        <h1 className="text-[18px] font-medium">e-Wallets</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='BanklevelEncryptions' className="w-10 h-10" src={BanklevelEncryptions} />
                        <h1 className="text-[18px] font-medium">Bank-level Encryptions</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='Microinvestments' className="w-10 h-10" src={Microinvestments} />
                        <h1 className="text-[18px] font-medium">Micro-investments</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='MobilePayments' className="w-10 h-10" src={MobilePayments} />
                        <h1 className="text-[18px] font-medium">Mobile Payments</h1>
                    </div>
                </div>
                <img alt='SoftwareDevelopmentFeatures' className="w-[506px] h-[408px]" src={SoftwareDevelopmentFeatures} />

                <div className="ml-[50px]">
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='TwofactorAuthentication' className="w-10 h-10" src={TwofactorAuthentication} />
                        <h1 className="text-[18px] font-medium">Two-factor Authentication (2FA)</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='RealtimeTransactions' className="w-10 h-10" src={RealtimeTransactions} />
                        <h1 className="text-[18px] font-medium">Real-time Transactions</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='DigitalLending' className="w-10 h-10" src={DigitalLending} />
                        <h1 className="text-[18px] font-medium">Digital Lending</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='FullscaleTesting' className="w-10 h-10" src={FullscaleTesting} />
                        <h1 className="text-[18px] font-medium">Full-scale Testing</h1>
                    </div>
                </div>
            </div>

            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Fintech Software</span> Development Process</h1>
            <p className="text-[18px] text-center mt-4">
                We add innovation and security in every development stage of your fintech product.
            </p>
            <div className="flex gap-1 items-center justify-center mt-8">
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
            </div>
            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex justify-center items-center gap-[5rem] mt-[30px]">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                </div>
                <div>
                    <div className="flex gap-[5rem]">
                        <div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='DeliverTime' className="w-10 h-10" src={DeliverTime} />
                                <h1 className="text-[18px] font-medium">On-time Delivery</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='MultipleUseCases' className="w-10 h-10" src={MultipleUseCases} />
                                <h1 className="text-[18px] font-medium">Multiple Use Cases</h1>
                            </div>
                        </div>
                        <div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='CompleteComplianceCheck' className="w-10 h-10" src={CompleteComplianceCheck} />
                                <h1 className="text-[18px] font-medium">Complete Compliance Check</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='NextgenTechInclusion' className="w-10 h-10" src={NextgenTechInclusion} />
                                <h1 className="text-[18px] font-medium">Next-gen Tech Inclusion</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" mt-[40px] m-auto p-[40px] w-[1320px] h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[25px] font-semibold text-[#fff]">NEED SOFTWARE DEVELOPMENT FOR FINANCIAL SERVICES?</h1>
                <p className="text-center text-[19px] font-medium text-[#fff]">BitFront will help in creating a secure and efficient solution, optimizing business logic for new revenue streams.</p>
            </div>

            <h1 className="text-[40px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Fintech
