import React from 'react'

const AIS = () => {
    return (
        <div>
           <div className="p-1">
                <h1 className="text-[30px] font-semibold text-[#ffffff] leading-10 mb-5">OCR Services</h1>
                <p className="text-base leading-[30px] text-[#ffffff] font-normal mb-[20px]">At BitFront, we offer OCR services that help
                you to recognize text and characters from scanned documents, photographs as well as digital camera-captured images.</p>
                <button className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                    <div class=" bg-gradient-to-r from-[#26123C] to-[#040206] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">Read More </div>
                </button>
            </div>
        </div>
    )
}

export default AIS;
