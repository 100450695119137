import React from 'react';
function WAD() {
  return (
    <div>
      <div className="p-1">
        <h1 className="xl:text-[30px] lg:text-[30px] md:text-[20px] text-[30px] font-semibold text-[#192734] leading-10 mb-5">Build your Web application with cutting-edge technologies and battle-tested tools.</h1>
        <p className="xl:text-[17px] lg:text-[17px] md:text-[16px] text-[16px] text-[#4c5a67] font-normal mb-[30px]">We leverage long-standing experience in building and offering
          custom web application helping you implement the right technologies
          in areas that are best suited to bring your business value.</p>
        <div className="grid lg:grid-cols-2 gird sm:grid-cols-1">
          <div className='space-y-5'>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Website Development</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Software Development</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Web Application Support & Maintenance</h1>
            </div>
          </div>
          <div className='space-y-5 ld:mt-0 md:mt-4 mt-4'>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">E-Commerce Web App Development</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Web Application Testing</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Progressive Web Apps</h1>
            </div>
          </div>
        </div>
        <button className=" font-medium w-[200px] h-[50px] border-[1px] border-[#0652dd] bg-[#0652dd] text-[#fff] rounded-[5px] mt-11">Get More Info</button>
      </div>
    </div>

  );
}

export default WAD;
