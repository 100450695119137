import React, { useState } from 'react';
import Header from '../components/layout/Header'
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import 'animate.css';
import LogisticsSoftwareDevelopment from '../assets/images/LogisticsSoftwareDevelopment.png';
import LogisticsManagementApps from '../assets/images/LogisticsManagementApps.png';
import RealtimeTrackingApps from '../assets/images/RealtimeTrackingApps.png';
import OrderManagementSolutions from '../assets/images/OrderManagementSolutions.png';
import LogisticsDevelopment from '../assets/images/LogisticsDevelopment.png';
import CloudandDevOps from '../assets/images/CloudandDevOps.png';
import AnalyticsandReporting from '../assets/images/AnalyticsandReporting.png';
import IOT from '../assets/images/IOT.png';
import GPStechnology from '../assets/images/GPStechnology.png';
import Blockchain from '../assets/images/Blockchain.png';
import AIbasedlogisticsmanagement from '../assets/images/AIbasedlogisticsmanagement.png';
import Automationlogistics from '../assets/images/Automationlogistics.png';
import Pushnotifications from '../assets/images/Pushnotifications.png';
import LogisticsSoftwarewithInnovativeFeatures from '../assets/images/LogisticsSoftwarewithInnovativeFeatures.png';
import Realtimestatustracking from '../assets/images/Realtimestatustracking.png';
import MultimediaEditing from '../assets/images/MultimediaEditing.png';
import Integrationplatforms from '../assets/images/Integrationplatforms.png';
import Bestsoftwaredevelopmentpractices from '../assets/images/Bestsoftwaredevelopmentpractices.png';
import Clearanddetailedtime from '../assets/images/Clearanddetailedtime.png';
import Customreports from '../assets/images/Customreports.png';
import Agile from '../assets/images/Agile.png';
import transparency from '../assets/images/transparency.png';
import Regularcommunications from '../assets/images/Regularcommunications.png';
const Logistic = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What is logistics software development?",
            answer:
                "Logistics software development is the solution to automate shipping and freight forwarding activities and allow transport companies to simplify freight operations, save time, reduce costs and improve customer services. A widely used solution for this is a transportation management system (TMS).",
            open: false
        },
        {
            question: "What are the 4 major types of logistics?",
            answer: "The four types of logistics are inbound logistics, outbound logistics, reverse logistics, and third-party logistics (3PL) or fourth-party logistics (4PL).",
            open: false
        },
        {
            question:
                "How logistics software development can benefit any logistics organization?",
            answer: "It provides more transparency to the supply chain:1. Offering a unified platform for fleet management",
            open: false
        },
        {
            question:
                "Is it possible to upgrade our existing logistics software?",
            answer: "Yes, we do provide assistance to upgrade your existing system with our latest solutions.",
            open: false
        },
        {
            question:
                "Do you guarantee the security and confidentiality of our data?",
            answer: "Yes we kept it confidential and provide assurance for the same.",
            open: false
        },
        {
            question:
                "How much does it cost to develop custom logistics software?",
            answer: "The final cost of developing a logistics software depends on various factors. However, usually, the average cost of logistics software and mobile app development ranges between $20,000 to $500,000.",
            open: false
        },
        {
            question:
                "How much time does it take to develop a logistics software for transport company?",
            answer: "The time required to develop a logistics software system varies based on factors like technology stack, front and back end development processes, application type, platform, team size, and more",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div>
            <Header />
            <div className="max-w-7xl h-[580px]	 bg-white rounded-2xl m-auto mt-[40px] ">
                <div className="grid grid-cols-2 items-center gap-[5rem] justify-center p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]">Logistics Software</span> Development Company</h1>
                        <p className="text-[18px]">
                            At BitFront, we build solutions that power real-time tracking of goods, efficient
                            warehouse management, and make the industry automated.
                        </p>
                        <li className="text-[18px] pt-[10px]">Inventory Management</li>
                        <li className="text-[18px] pt-[10px]">Risk Management</li>
                        <li className="text-[18px] pt-[10px]">Streamlined Operations</li>
                        <li className="text-[18px] pt-[10px]">Real-time Tracking</li>
                        <li className="text-[18px] pt-[10px]">Real-time Information</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[250px] mt-10 h-[42px]">TALK TO OUR DEVELOPERS</button>
                    </div>
                    <img alt='LogisticsSoftwareDevelopment' className="w-[506px] h-[420px]" src={LogisticsSoftwareDevelopment} />
                </div>
            </div>

            <div className="h-[148.8px] ml-[300px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>
            <div className="w-[1100px] m-auto">
                <div className="grid grid-cols-2 justify-center items-center gap-[5rem]">
                    <div>
                        <h1 className="text-[40px] font-semibold"><span className="text-[#0652dd]">Logistics Software</span> Development Services</h1>
                        <p className="space-y-5 mb-[20px] text-[18px] text-[#192734]">
                            At BitFront, we specialize in creating digital solutions for order tracking and optimizing transportation
                            and distribution processes for manufacturing units. Our commitment to digitizing the logistics sector has
                            established us as a trusted agency for transportation and logistics software development.
                        </p>
                        <p className="space-y-5 text-[18px] text-[#192734]">
                            If your business moves quickly and needs help organizing everything, our logistics software can automate
                            many tasks for you. We create software for all your logistics needs, both inside and outside your company,
                            to improve how you deliver goods.
                        </p>
                    </div>
                    <div>
                        <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#533398] border-[2px]">
                            <div className="flex items-center gap-1">
                                <img alt='LogisticsManagementApps' className="w-9 h-9" src={LogisticsManagementApps} />
                                <h4 className=" text-[20px] font-bold">Logistics Management Apps</h4>
                            </div>
                            <p className="text-[17px] pl-2">
                                We offer customized logistics software development solutions that run on both mobile and web. The software we
                                make is engineered to offer a detailed view of the logistic movement leading to increased user engagement
                                and real-time data interaction.
                            </p>
                        </div>
                        <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#983382] border-[2px]">
                            <div className="flex items-center gap-1">
                                <img alt='LogisticsManagementApps' className="w-9 h-9" src={LogisticsManagementApps} />
                                <h4 className=" text-[20px] font-bold">Supply Chain Management Solutions</h4>
                            </div>
                            <p className="text-[17px] pl-2">
                                At BitFront we build supply chain management software that makes it easy for businesses to track the product’s
                                journey from the manufacturing station to the end users’ doorsteps. The products we build are known to
                                introduce transparency in the industry.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center ">
                <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#99c2a4] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='RealtimeTrackingApps' className="w-9 h-9" src={RealtimeTrackingApps} />
                        <h4 className=" text-[20px] font-bold">Real-time Tracking Apps</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We provide logistics businesses and their customers an IT architecture to track the shipment status in real-time.
                        Our team specializes in creating an API of the tracking mechanism, which can be used commercially as a third-party
                        integration for new eCommerce applications.
                    </p>
                </div>
                <div className=" ml-[40px] mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#de916d] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='OrderManagementSolutions' className="w-9 h-9" src={OrderManagementSolutions} />
                        <h4 className=" text-[20px] font-bold">Order Management Solutions</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        Our logistics & transportation mobile app development process comprises creating a solution around order
                        fulfillment and management. We make it easy for businesses to keep track of the number of orders placed along
                        with shipment details, their delivery status, and managing reverse orders.
                    </p>
                </div>
            </div>

            <h1 className="text-[35px] font-semibold text-center mt-10"><span className="text-[#0652dd]">Logistics Software</span> Development Solutions</h1>
            <p className="text-[18px] w-[1340px] m-auto font-medium text-center">Our goal is to enhance the overall customer experience
                and facilitate sustainable business expansion through offerings like logistics management systems, transport management
                software, and advanced data analytics solutions. Our team of specialists implements these solutions using cutting-edge
                technologies, including:</p>

            <div className="flex justify-center items-center gap-[5rem] mt-[40px]">
                <img alt='LogisticsDevelopment' className="w-[561px] h-[557px]" src={LogisticsDevelopment} />
                <div>

                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#afe5a6] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='CloudandDevOps' className="w-[38px] h-[38px]" src={CloudandDevOps} />
                            <h1 className="text-[20px] font-medium">Cloud and DevOps</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#a6e5e3] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='AnalyticsandReporting' className="w-[38px] h-[38px]" src={AnalyticsandReporting} />
                            <h1 className="text-[20px] font-medium">Big Data Science and Analytics</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#a6b7e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='IOT' className="w-[38px] h-[38px]" src={IOT} />
                            <h1 className="text-[20px] font-medium">IoT</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#c0a6e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='GPStechnology' className="w-[38px] h-[38px]" src={GPStechnology} />
                            <h1 className="text-[20px] font-medium">GPS technology</h1>
                        </div>
                    </div>

                    <div className=" mt-[40px] w-[550px] h-[65px] border-[#e5a6aa] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='Blockchain' className="w-[38px] h-[38px]" src={Blockchain} />
                            <h1 className="text-[20px] font-medium">Blockchain</h1>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className=" mt-8 text-[38px] font-semibold text-center">Powering Your<span className="text-[#0652dd]"> Logistics Software </span>
                with Innovative Features</h1>
            <p className="text-[18px] font-medium text-center m-auto w-[940px]">Incorporating smart, innovative features sets us apart in the logistics and shipment industry.
                Our team specializes in implementing features that position your brand as leaders in the logistics sector.</p>
            <div className="flex justify-center items-center gap-[5rem] mt-[60px]">
                <div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='AIbasedlogisticsmanagement' className="w-9 h-9" src={AIbasedlogisticsmanagement} />
                        <h1 className="text-[18px] font-medium">AI-based logistics management</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='Automationlogistics' className="w-9 h-9" src={Automationlogistics} />
                        <h1 className="text-[18px] font-medium">Automation of logistics</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='img' className="w-9 h-9" src={Pushnotifications} />
                        <h1 className="text-[18px] font-medium">Push notifications</h1>
                    </div>
                </div>
                <img alt='LogisticsSoftwarewithInnovativeFeatures' className="w-[556px] h-[379px]" src={LogisticsSoftwarewithInnovativeFeatures} />

                <div className="w-[400px]">
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='Realtimestatustracking' className="w-9 h-9" src={Realtimestatustracking} />
                        <h1 className="text-[18px] font-medium">Real-time status tracking</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='MultimediaEditing' className="w-9 h-9" src={MultimediaEditing} />
                        <h1 className="text-[18px] font-medium">Multi-payment support</h1>
                    </div>
                    <div className=" mt-6 gap-[2rem] flex items-center">
                        <img alt='Integrationplatforms' className="w-9 h-9" src={Integrationplatforms} />
                        <h1 className="text-[18px] font-medium">Integration with CRM, ERP, TMS, and WMS platforms</h1>
                    </div>
                </div>
            </div>


            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Logistics Software</span> Development Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 w-[1100px]">
                Every single element of your logistics app is built with a focus on your customers and employees.
                We aim to make the end-to-end order fulfillment process streamlined and efficient.
            </p>
            <div className="flex gap-1 items-center justify-center mt-8">
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
            </div>

            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className=" flex justify-center items-center gap-[5rem] mt-[30px]">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                </div>
                <div>

                    <div className="flex gap-[5rem]">
                        <div className="w-[300px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Bestsoftwaredevelopmentpractices' className="w-9 h-9" src={Bestsoftwaredevelopmentpractices} />
                                <h1 className="text-[18px] font-medium">Best software development practices</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Clearanddetailedtime' className="w-9 h-9" src={Clearanddetailedtime} />
                                <h1 className="text-[18px] font-medium">Clear and detailed time</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Customreports' className="w-9 h-9" src={Customreports} />
                                <h1 className="text-[18px] font-medium">Custom reports</h1>
                            </div>
                        </div>


                        <div className="w-[325px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Agile' className="w-10 h-10" src={Agile} />
                                <h1 className="text-[18px] font-medium">Agile (Scrum, Kanban methodology)</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='transparency' className="w-9 h-9" src={transparency} />
                                <h1 className="text-[18px] font-medium">100% transparency</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Regularcommunications' className="w-9 h-9" src={Regularcommunications} />
                                <h1 className="text-[18px] font-medium">Regular communications</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" mt-[40px] m-auto p-[40px] w-[1320px] h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[20px] font-semibold text-[#fff]">PGet your Business Goals Optimize Efficiency and Reduce Costs with Our Logistics Software Solutions</h1>
            </div>

            <h1 className="text-[40px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="emergeSite.png" src="/images2/emergeSite.png"></img>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Logistic
