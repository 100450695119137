import React, { useState } from 'react';
import Header from '../components/layout/Header'
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import 'animate.css';
import RealEstateAppDevelopment from '../assets/images/RealEstateAppDevelopment.png';
import RealEstateMobileAppDevelopment from '../assets/images/RealEstateMobileAppDevelopment.png';
import RealEstateWebsiteDevelopment from '../assets/images/RealEstateWebsiteDevelopment.png';
import AIRealEstateMobileAppDevelopment from '../assets/images/AIRealEstateMobileAppDevelopment.png';
import EnterpriseRealEstateAppDevelopment from '../assets/images/EnterpriseRealEstateAppDevelopment.png';
import THzyjcgw from '../assets/images/THzyjcgw.png';
import MLSSoftwareDevelopment from '../assets/images/MLSSoftwareDevelopment.png';
import RealEstateMarketplace from '../assets/images/RealEstateMarketplace.png';
import PropertiesRealEstateBuyers from '../assets/images/PropertiesRealEstateBuyers.png';
import RealEstateCompaniesSellers from '../assets/images/RealEstateCompaniesSellers.svg';
import RealEstateAggregators from '../assets/images/RealEstateAggregators.png';
import LoginSignup from '../assets/images/LoginSignup.png';
import UserProfileManagement from '../assets/images/UserProfileManagement.png';
import AIChatbotAssistance from '../assets/images/AIChatbotAssistance.png';
import searchbarwithfilter from '../assets/images/searchbarwithfilter.png';
import pushnotificationsss from '../assets/images/pushnotificationsss.png';
import mapintegration from '../assets/images/mapintegration.png';
import appointmentreminder from '../assets/images/appointmentreminder.png';
import propertylisting from '../assets/images/propertylisting.png';
import manageappointments from '../assets/images/manageappointments.png';
import calendarreminders from '../assets/images/calendarreminders.png';
import multiplepaymentgateways from '../assets/images/multiplepaymentgateways.png';
import manageleads from '../assets/images/manageleads.png';
import monitorprojects from '../assets/images/monitorprojects.png';
import recentactivity from '../assets/images/recentactivity.png';
import comprehensivedashboard from '../assets/images/comprehensivedashboard.png';
import managelisting from '../assets/images/managelisting.png';
import managebookings from '../assets/images/managebookings.png';
import analyticsreportsdd from '../assets/images/analyticsreportsdd.png';
import transactionmanagement from '../assets/images/transactionmanagement.png';
import advancechatoption from '../assets/images/advancechatoption.png';
import dedicateddevelopers from '../assets/images/dedicateddevelopers.png';
import customersupport from '../assets/images/customersupport.png';
import customizedsolution from '../assets/images/customizedsolution.png';
import agiledevelopmentmethod from '../assets/images/agiledevelopmentmethod.png';
import affordable from '../assets/images/affordable.png';
import satisfactionclient from '../assets/images/satisfactionclient.png';
const RealEstate = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What is Real estate App Development?",
            answer:
                "Real estate app development involves creating mobile applications that can serve various purposes like property search, listing, virtual tours, transaction management, and communication between buyers, sellers, and agents.",
            open: false
        },
        {
            question: "What are the benefits of Real Estate App for businesses?",
            answer: "Well-developed and feature-rich real estate apps can provide several benefits for businesses, including: 1. Enhanced communication",
            open: false
        },
        {
            question:
                "What is the cost of Real Estate Mobile App Development?",
            answer: "The cost to develop a real estate app is based on various factors like the complexity of the app, UI/UX design, tech stack used, and the location of the development team. However, a basic app might range from $10,000 to $50,000 and an advanced app can go up to $80,000 or above.",
            open: false
        },
        {
            question:
                "What are the top features of a real estate app?",
            answer: "Here are some advanced features that we can deploy in your app- 1. Virtual property tour 2. Chatbot integration",
            open: false
        },
        {
            question:
                "Is there any future for mobile apps in the real estate industry?",
            answer: "Real estate mobile apps are leading the industry and helping to change the overall process. Sellers prefer to upload all their property details over these large social platforms to gain more customers and even buyers visit these apps first to buy a property or rent it over.",
            open: false
        },
        {
            question:
                "How long does it take to develop a Real Estate App?",
            answer: "Various factors influence the timeline for real estate app development like app complexity, size and location of the development team, feature list, and more.",
            open: false
        },
        {
            question:
                "How do I create a real estate app from scratch?",
            answer: "Creating a real estate app from scratch requires planning first. Focus on essential features like property listings with filters and map search.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div>
            <Header />
            <div className="max-w-7xl h-auto bg-white rounded-2xl m-auto mt-[40px] ">
                <div className="grid grid-cols-2 items-center gap-[5rem] justify-center p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]">Real Estate</span> App Development Company</h1>
                        <p className="text-[18px]">
                            BitFront has a team of expert app developers who can help you with custom real estate app development,
                            revolutionizing the way of managing real estate business processes and the way people buy, sell or
                            rent properties while ensuring compliance with industry standards.
                        </p>
                        <li className="text-[18px] pt-[10px]">Property Management</li>
                        <li className="text-[18px] pt-[10px]">Revenue Management</li>
                        <li className="text-[18px] pt-[10px]">AI-Chatbot Assistance</li>
                        <li className="text-[18px] pt-[10px]">Monitor Projects</li>
                        <li className="text-[18px] pt-[10px]">Automatic Speech Recognition</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[250px] mt-10 h-[42px]">TALK TO OUR DEVELOPERS</button>
                    </div>
                    <img alt='Real Estate App Development' className="w-[616px] h-[450px]" src={RealEstateAppDevelopment} />
                </div>
            </div>

            <div className="h-[148.8px] ml-[300px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>
            <div className="flex justify-center items-center gap-[5rem]">
                <div className=" hover:shadow-custom mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-tr-[50px] rounded-bl-[30px] p-[20px] border-[#efa54f] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img
                            alt='Real Estate Mobile App Development'
                            className="w-9 h-9"
                            src={RealEstateMobileAppDevelopment} />
                        <h4 className=" text-[20px] font-bold">Real Estate Mobile App Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        Our team of real estate app developers can use the latest tech to create a successful Real Estate mobile
                        app that is feature-loaded. We can add cool features like seeing properties with AR/VR, an interest rate
                        calculator, a 360° property tour, scheduling meetings, and more to make your app exciting.
                    </p>
                </div>
                <div className=" hover:shadow-custom mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[50px] rounded-tr-[30px] rounded-br-[30px] p-[20px] border-[#333a98] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='Real Estate Website Development' className="w-9 h-9" src={RealEstateWebsiteDevelopment} />
                        <h4 className=" text-[20px] font-bold">Real Estate Website Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        Develop a strong digital presence and take your real estate to the heights. Get real estate website development
                        services from BitFront and create a website that will leave your competitors behind. Hire real estate app developers,
                        and they will deliver you the best real estate web application development solutions.
                    </p>
                </div>
            </div>
            <div className="flex justify-center items-center gap-[5rem]">
                <div className=" hover:shadow-custom mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-bl-[30px] rounded-br-[50px] p-[20px] border-[#339836] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='AI Real Estate Mobile App Development' className="w-9 h-9" src={AIRealEstateMobileAppDevelopment} />
                        <h4 className=" text-[20px] font-bold">AI Real Estate Mobile App Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We can deploy AI features in your real estate app that help you create a personalized experience for your
                        customers. With the help of AI, your customers would get personalized suggestions of properties that would
                        assist you in performing upselling in your store.
                    </p>
                </div>
                <div className=" hover:shadow-custom mt-[40px] w-[556px] h-[250px] bg-white rounded-tr-[30px] rounded-bl-[50px] rounded-br-[30px] p-[20px] border-[#885d24] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='EnterpriseRealEstateAppDevelopment' className="w-9 h-9" src={EnterpriseRealEstateAppDevelopment} />
                        <h4 className=" text-[20px] font-bold">Enterprise Real Estate App Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We customize our property financing apps to suit the requirements of large real estate businesses.
                        Our services include robust software development that helps to achieve strategic business goals.
                    </p>
                </div>
            </div>

            <h1 className=" text-[35px] font-semibold text-center mt-10">Our <span className="text-[#0652dd]">Real Estate</span> Solutions</h1>
            <p className="text-[18px] w-[1340px] m-auto font-medium text-center">BitFront as a leading real estate software development
                company believes in delivering complete customer satisfaction by offering an extensive set of software and mobile app
                development for the real estate industry, which includes:</p>

            <div className="flex justify-center items-center gap-[5rem] mt-[40px]">
                <img alt='THzyjcgw' className="w-[499px] h-[486px]" src={THzyjcgw} />
                <div>
                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#afe5a6] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='MLS Software Development' className="w-9 h-9" src={MLSSoftwareDevelopment} />
                            <h1 className="text-[20px] font-medium">MLS Software Development</h1>
                        </div>
                    </div>

                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#a6e5e3] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='Real Estate Market place' className="w-9 h-9" src={RealEstateMarketplace} />
                            <h1 className="text-[20px] font-medium">Real Estate Marketplace</h1>
                        </div>
                    </div>

                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#a6b7e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='Properties Real Estate Buyers' className="w-9 h-9" src={PropertiesRealEstateBuyers} />
                            <h1 className="text-[20px] font-medium">Properties/Real Estate Buyers</h1>
                        </div>
                    </div>

                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#c0a6e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='Real Estate Companies Sellers' className="w-9 h-9" src={RealEstateCompaniesSellers} />
                            <h1 className="text-[20px] font-medium">Real Estate Companies or Sellers</h1>
                        </div>
                    </div>

                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#e5a6aa] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='Real Estate Aggregators' className="w-9 h-9" src={RealEstateAggregators} />
                            <h1 className="text-[20px] font-medium">Real Estate Aggregators</h1>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className=" font-semibold mt-[40px] text-[40px] text-center">Top <span className="text-[#0652dd]">Real Estate</span> App Features</h1>
            <p className="text-[18px] text-center mb-[20px]">Our team of real estate app developers helps you build a feature-loaded real estate app.</p>
            <div className="flex justify-center items-center gap-[7rem]">
                <div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='Login Signup' className="w-9 h-9" src={LoginSignup} />
                        <p className="text-[19px] font-medium ml-[35px]">Login/Signup</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='User Profile Management' className="w-9 h-9" src={UserProfileManagement} />
                        <p className="text-[19px] font-medium ml-[35px]">User Profile Management</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='AI Chatbot Assistance' className="w-9 h-9" src={AIChatbotAssistance} />
                        <p className="text-[19px] font-medium ml-[35px]">AI-Chatbot Assistance</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='search bar with filter' src={searchbarwithfilter} />
                        <p className="text-[19px] font-medium ml-[35px]">Search Bar With Filter</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='push notificationsss' src={pushnotificationsss} />
                        <p className="text-[19px] font-medium ml-[35px]">Push Notifications</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='map integration' src={mapintegration} />
                        <p className="text-[19px] font-medium ml-[35px]">Map integration</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='appointmentreminder' src={appointmentreminder} />
                        <p className="text-[19px] font-medium ml-[35px]">Appointment Reminder</p>
                    </div>
                </div>
                <div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='property listing' src={propertylisting} />
                        <p className="text-[19px] font-medium ml-[35px]">Property Listing</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='manage appointments' src={manageappointments} />
                        <p className="text-[19px] font-medium ml-[35px]">Manage Appointments</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='calendar reminders' src={calendarreminders} />
                        <p className="text-[19px] font-medium ml-[35px]">Calendar Reminders</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='multiple payment gateways' src={multiplepaymentgateways} />
                        <p className="text-[19px] font-medium ml-[35px]">Multiple Payment Gateways</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='manage leads' src={manageleads} />
                        <p className="text-[19px] font-medium ml-[35px]">Manage Leads</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='monitor projects' src={monitorprojects} />
                        <p className="text-[19px] font-medium ml-[35px]">Monitor Projects</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='recent activity' src={recentactivity} />
                        <p className="text-[19px] font-medium ml-[35px]">Recent Activity</p>
                    </div>
                </div>
                <div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='comprehensive dashboard' src={comprehensivedashboard} />
                        <p className="text-[19px] font-medium ml-[35px]">Comprehensive Dashboard</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='manage listing' src={managelisting} />
                        <p className="text-[19px] font-medium ml-[35px]">Manage Listings</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='manage bookings' src={managebookings} />
                        <p className="text-[19px] font-medium ml-[35px]">Manage Bookings</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='analytics reportsdd' src={analyticsreportsdd} />
                        <p className="text-[19px] font-medium ml-[35px]">Analytics & Reports</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='transaction management' src={transactionmanagement} />
                        <p className="text-[19px] font-medium ml-[35px]">Transaction Management</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='transaction management' src={transactionmanagement} />
                        <p className="text-[19px] font-medium ml-[35px]">Offers & Discounts</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='advance chat option' src={advancechatoption} />
                        <p className="text-[19px] font-medium ml-[35px]">Advance Chat Option</p>
                    </div>
                </div>
            </div>


            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Real Estate</span> Software Development Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 w-[600px]">
                To create a scalable real estate app platform we follow a unified process of software development-
            </p>
            <div className="flex gap-1 items-center justify-center mt-8">
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
            </div>

            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className=" flex justify-center items-center gap-[5rem] mt-[30px]">
                <div className="w-[600px]">
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        We deliver quality-assured solutions making us the best real estate app development
                        company and a clear choice for your success in this competitive industry.
                    </p>
                </div>
                <div>
                    <div className="flex gap-[5rem]">
                        <div className="w-[300px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='dedicated developers' src={dedicateddevelopers} />
                                <h1 className="text-[18px] font-medium">80+ Dedicated Developers</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='customer support' src={customersupport} />
                                <h1 className="text-[18px] font-medium">24*7 Customer Support</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='customized solution' src={customizedsolution} />
                                <h1 className="text-[18px] font-medium">Customized Solutions</h1>
                            </div>
                        </div>


                        <div className="w-[325px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='agile development method' src={agiledevelopmentmethod} />
                                <h1 className="text-[18px] font-medium">Agile Development Method</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='affordable' src={affordable} />
                                <h1 className="text-[18px] font-medium">100% Affordable</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='satisfaction client' src={satisfactionclient} />
                                <h1 className="text-[18px] font-medium">Client Satisfaction</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" mt-[40px] m-auto p-[40px] w-[1320px] h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[20px] font-semibold text-[#fff]">Have a unique Real Estate Application Development Idea? Trust BitFront to turn it into reality.</h1>
            </div>
            <h1 className="text-[40px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="emergeSite.png" src="/images2/emergeSite.png"></img>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default RealEstate
