import React from 'react'

const ADI = () => {
    return (
        <div>
            <div className="p-1">
                <h1 className="text-[30px] font-semibold text-[#ffffff] leading-10 mb-5">Chatbot Development</h1>
                <p className="text-base leading-[30px] text-[#ffffff] font-normal mb-[20px]">Being a top AI development firm, we have an
                    expert AI team who can develop chatbots that offer highly personalized interaction to improve loyalty as well as customer
                    retention.</p>
                <button className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                    <div class=" bg-gradient-to-r from-[#26123C] to-[#040206] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">Read More </div>
                </button>
            </div>
        </div>
    )
}

export default ADI;
