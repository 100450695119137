import Header from "../components/layout/Header"
import bitlogo from '../assets/images/bitlogo.png';
import { useState } from "react";
import AI from "../components/AI";
import { FaAngleRight } from "react-icons/fa";
import ML from "../components/ML";
import CV from "../components/CV";
import NLPD from "../components/NLPD";
import LLMD from "../components/LLMD";
import ADI from "../components/ADi";
import OC from "../components/OC";
import GAI from "../components/GAI";
import AIS from "../components/AIS";
import DL from "../components/DL";
import healths from '../assets/images/healths.svg';
import fintechs from '../assets/images/fintechs.svg';
import autotechs from '../assets/images/autotechs.svg';
import edtechs from '../assets/images/edtechs.svg';
import traveltechs from '../assets/images/traveltechs.svg';
import esporticon from '../assets/images/esporticon.svg';
import foodicon from '../assets/images/foodicon.svg';
import logisticicon from '../assets/images/logisticicon.svg';
import entertainmentsicon from '../assets/images/entertainmentsicon.svg';
import realestatesicon from '../assets/images/realestatesicon.svg';
import artificialprocessimage from '../assets/images/artificialprocessimage.png';
import { FaArrowRightLong } from "react-icons/fa6";
import { GoCheck } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Footerq from "../components/layout/Footerq";
import FAQsw from '../components/layout/FAQsw';
import './BitfrontAi.css';
import koovsicon from '../assets/images/koovsicon.svg';
import koovsweb from '../assets/images/koovsweb.png';
import revazaweb from '../assets/images/revazaweb.png';
import revezaicon from '../assets/images/revezaicon.svg';
import easecareicon from '../assets/images/easecareicon.svg';
import easecareweb from '../assets/images/easecareweb.png';
import rightangleweb from '../assets/images/rightangleweb.png';
import rightangleicon from '../assets/images/rightangleicon.svg';
import docaicon from '../assets/images/docaicon.svg';
import docaiweb from '../assets/images/docaiweb.png';
import artificialtechnologynewimg from '../assets/images/artificialtechnologynewimg.png';
import artificialpartnermin from '../assets/images/artificialpartnermin.png';
const BitfrontAI = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { label: 'Artificial Intelligence ', },
    { label: ' Machine Learning ', },
    { label: 'Computer Vision ', },
    { label: 'NLP Development ', },
    { label: 'LLM Development ', },
    { label: 'Adaptive AI ', },
    { label: 'Openai-ChatGPT ', },
    { label: 'Generative AI ', },
    { label: 'Deep Learning', },
    { label: 'AI as a Service', },

  ];
  const [isOpen, setIsOpen] = useState(true);
  const [isOpens, setIsOpens] = useState(false);
  const [isOpent, setIsOpent] = useState(false);
  const [isOpenf, setIsOpensf] = useState(false);
  const [isOpenfi, setIsOpensfi] = useState(false);

  const toggleCard = () => {
    setIsOpen(!isOpen);
    setIsOpens(false);
    setIsOpensfi(false);
    setIsOpent(false);
    setIsOpensf(false);
  };

  const toggleCardopen = () => {
    setIsOpens(!isOpens);
    setIsOpen(false);
    setIsOpent(false);
    setIsOpensf(false);
    setIsOpensfi(false);
  };

  const toggleCardopent = () => {
    setIsOpent(!isOpent);
    setIsOpen(false);
    setIsOpens(false);
    setIsOpensf(false);
    setIsOpensfi(false);
  };

  const toggleCardopenf = () => {
    setIsOpensf(!isOpenf);
    setIsOpen(false);
    setIsOpens(false);
    setIsOpent(false);
    setIsOpensfi(false);
  };

  const toggleCardopenfi = () => {
    setIsOpensfi(!isOpenfi);
    setIsOpen(false);
    setIsOpens(false);
    setIsOpent(false);
    setIsOpensf(false);
  };
  const handleTabChange = (index) => {
    console.log(index, "indexindexindex");
    setActiveTab(index);
  };

  const [faqs, setFaqs] = useState([
    {
      question: "What is Artificial Intelligence?",
      answer: "Artificial Intelligence (AI) is a part of computer science focused on making systems that can think and solve problems like humans. They learn from lots of information, get better over time, and use what they've learned to do things more efficiently in the future.",
      open: false
    },
    {
      question: "How long does it take to build an AI-based product?",
      answer: "The timeframe for developing an AI-based product varies based on its complexity. A highly complex solution with extensive features may take 10 to 18 months, while a simpler solution with fewer features could be completed in 3 to 6 months, depending on custom requirements.",
      open: false
    },
    {
      question: "What programming languages are commonly used in AI software development?",
      answer: "Python is the most popular and widely used programming language for AI software. Its extensive libraries like TensorFlow, PyTorch, and sci-kit-learn make it convenient for tasks such as data manipulation, machine learning, and deep learning. Other languages like Java, C++, and R are also used, depending on the specific requirements of the project.",
      open: false
    },
    {
      question: "How can you track the progress of your AI development project while collaborating with Bit Front?",
      answer: "We are a dedicated AI application development service company that follows agile project management practices. We ensure regular updates on AI software development progress for all stakeholders. For AI-based mobile or web apps, we offer login access to track real-time progress.",
      open: false
    },
    {
      question: "Can artificial intelligence be used to replace human workers?",
      answer: "AI can be used to solve tasks that humans currently do. However, it is not yet possible to develop AI systems that can replicate all the capabilities of human beings. As such, AI will likely supplement human workers instead of replacing them.",
      open: false
    },
    {
      question: "What does an AI engineer do?",
      answer: "AI engineers design, build and implement AI software solutions. Their job is to research new technologies, integrate them into existing systems, and test the results. They also monitor the performance of AI systems in production environments.",
      open: false
    },
    {
      question: "How do Data Science and AI differ?",
      answer: "Data science is the field of gaining insights and knowledge from data. AI is the application of computer systems that perform tasks commonly associated with intelligent behavior. A data scientist will use AI techniques as part of their analysis.",
      open: false
    },
    {
      question: "Do you sign NDA for my AI application development?",
      answer: "Yes, as a reputed AI application development company at Bit Front we sign an NDA to protect the AI project data of our client.",
      open: false
    },

  ]);
  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faqq, i) => {
        if (i === index) {
          return {
            ...faqq,
            open: !faqq.open,
          };
        } else {
          return {
            ...faqq,
            open: false,
          };
        }
      })
    );
  };
  return (
    <div>
      <Header />
      <div className="max-w-7xl h-[580px] rounded-2xl m-auto mt-[40px]">
        <div className="grid grid-cols-2 items-center gap-[5rem] justify-center p-[30px]">
          <div>
            <h1 className="text-[40px] font-semibold">It’s time for change, Solving What Matters with AI</h1>
            <p className="text-[18px] mb-[25px]">
              BitFront AI development services help businesses improve their decision-making and automate business operations and workflows.
            </p>
            <button className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
              <div class=" bg-[#000] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">Talk to Experts</div>
            </button>
          </div>
          <img alt="bitlogo" src={bitlogo} />
        </div>
      </div>
      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-[1903px] mx-auto p-[20px] border-b-[0.1px] border-t-[0.1px] h-[400px] border-[#a3d2f3] ">
        <div className=" m-auto container pt-5 pb-5">
          <h1 className="text-[22px] w-[143px] mb-[5px] leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
            Our Services</h1>
          <h1 className="text-[32px] mb-[15px] font-medium ">Artificial Intelligence Development Services We Offer</h1>
          <p className="text-[18px] mb-[25px]">
            Upgrade your business with BitFront's AI Development services. We provide customized solutions to improve customer experience, streamline
            supply chain operations, and manage inventory effectively. Our strategic use of advanced technologies such as facial recognition, fraud
            detection, and biometric authentication is aimed at boosting growth and achieving outstanding outcomes.
          </p>
          <p className="text-[18px]">
            Our AI solutions use big data to achieve real business results. Our experts turn raw data into useful insights, making operations more
            efficient and decisions smarter. Check out our AI services to make your ideas come to life.
          </p>
        </div>
      </div>
      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] p-[40px] w-[1903px]">
        <div className=" mb-[10px] mt-[0px] container mx-auto h-[594] xl:px-0 px-5">
          <h1 className="text-[22px] w-[143px] mb-[5px] leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
            Our Services</h1>
          <h1 className="text-[32px] mb-[25px] font-medium">Seamless solutions for every tech brand</h1>
          <div className="flex gap-[75px] px-3">
            <div className="w-1/3 flex-[0_0_33.33%]">
              {tabs.map((tab, index) => (
                <div onClick={() => handleTabChange(index)} key={index} className={` text-[#fff] hover:font-semibold hover:bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text hover:text-transparent w-[400px] py-[11px] px-[25px] cursor-pointer ${activeTab === index ? 'bg-[#2490ef0d]  bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent font-semibold' : 'border-l-8 border-transparent font-normal'} flex items-center justify-between`}>
                  <div className="leading-[34px]">
                    <h1 className=" text-[17px]">{tab.label}</h1>
                  </div>
                  <FaAngleRight className="text-[20px]" />
                </div>
              ))}
            </div>
            <div>
              {activeTab === 0 && (
                <AI />
              )}
              {activeTab === 1 && (
                <ML />
              )}
              {activeTab === 2 && (
                <CV />
              )}
              {activeTab === 3 && (
                <NLPD />
              )}
              {activeTab === 4 && (
                <LLMD />
              )}
              {activeTab === 5 && (
                <ADI />
              )}
              {activeTab === 6 && (
                <OC />
              )}
              {activeTab === 7 && (
                <GAI />
              )}
              {activeTab === 8 && (
                <DL />
              )}
              {activeTab === 9 && (
                <AIS />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] h-auto w-[1903px] mx-auto p-[20px] border-b-[0.1px]  border-[#a3d2f3]">
        <div className=" m-auto container pt-5 pb-5">
          <div className=" mb-[20px] items-center grid grid-cols-2">
            <div>
              <h1 className="text-[22px] w-[143px] mb-[5px] leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
                Industries</h1>
              <h1 className="text-[32px] mb-[15px] font-medium ">Industries That Our AI Development Services Excel In</h1>
              <p className="text-[18px] mb-[25px]">
                Our AI developers excel in seamlessly integrating top-tier artificial intelligence services into your business
                offerings, processes, and growth strategies, irrespective of your industry.
              </p>
            </div>
            <button className=" ml-[200px] w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
              <div class=" bg-[#000] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">Talk to Experts</div>
            </button>
          </div>
          <div className=" mb-[30px] flex items-center justify-center">
            <div className=" p-[25px] bg-[#1F0A32] rounded-[6px] w-[440px] h-[315px]">
              <div className="mb-[15px] bg-[#536AFF] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="healths" src={healths} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">Healthcare </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Use AI-powered analytics for precise diagnoses, reliable predictions, and personalized treatment.
              </p>
            </div>
            <div className=" ml-[30px] p-[25px] bg-[#1F0A32] rounded-[6px] w-[440px] h-[315px]">
              <div className="mb-[15px] bg-[#7D11D3] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="fintechs" src={fintechs} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">FinTech </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Tackle important finance tasks like predicting loan risks and automating financial analysis.
              </p>
            </div>
            <div className=" ml-[30px] p-[25px] bg-[#1F0A32] rounded-[6px] w-[440px] h-[315px]">
              <div className="mb-[15px] bg-[#536AFF] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="fintechs" src={fintechs} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">ECommerce </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Transform your retail business with digital solutions for a seamless, personalized shopping experience.
              </p>
            </div>
          </div>

          <div className=" mb-[30px] flex items-center justify-center">
            <div className=" p-[25px] bg-[#1F0A32] rounded-[6px] w-[440px] h-[315px]">
              <div className="mb-[15px] bg-[#7D11D3] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="autotechs" src={autotechs} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">AutoTech-EV </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Locate nearby EV charging stations, initiate charging sessions and seamlessly make payments using AI solutions.
              </p>
            </div>
            <div className=" ml-[30px] p-[25px] bg-[#1F0A32] rounded-[6px] w-[440px] h-[315px]">
              <div className="mb-[15px] bg-[#536AFF] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="edtechs" src={edtechs} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">EdTech-ELearning </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Introduce a new platform and approach to learning with personalized experiences using AI solutions.
              </p>
            </div>
            <div className=" ml-[30px] p-[25px] bg-[#1F0A32] rounded-[6px] w-[440px] h-[315px]">
              <div className="mb-[15px] bg-[#7D11D3] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="traveltechs" src={traveltechs} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">TravelTech </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Ensure a hassle-free travel experience for your customers using AI solutions.
              </p>
            </div>
          </div>

          <div className=" mb-[30px] flex items-center justify-center">
            <div className=" p-[25px] bg-[#1F0A32] rounded-[6px] w-[440px] h-[315px]">
              <div className="mb-[15px] bg-[#536AFF] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="esporticon" src={esporticon} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">Esport-Gaming </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Enhance gaming and sports experiences with interactive, immersive, and personalized digital solutions.
              </p>
            </div>
            <div className=" ml-[30px] p-[25px] bg-[#1F0A32] rounded-[6px] w-[440px] h-[315px]">
              <div className="mb-[15px] bg-[#7D11D3] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="foodicon" src={foodicon} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">Food & Restaurant </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Optimize your restaurant business with digital solutions for seamless ordering, payment, and delivery.
              </p>
            </div>
            <div className=" ml-[30px] p-[25px] bg-[#1F0A32] rounded-[6px] w-[440px] h-[315px]">
              <div className="mb-[15px] bg-[#536AFF] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="logisticicon" src={logisticicon} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">Logistics </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Gain real-time insights and data, track precise locations, and operate efficiently while monitoring.
              </p>
            </div>
          </div>

          <div className="flex items-center ">
            <div className=" p-[25px] bg-[#1F0A32] rounded-[6px] w-[380px] h-[315px]">
              <div className="mb-[15px] bg-[#7D11D3] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="entertainmentsicon" src={entertainmentsicon} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">Entertainment </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Innovative solutions to improve operations and engagement in the entertainment industry.
              </p>
            </div>
            <div className=" ml-[30px] p-[25px] bg-[#1F0A32] rounded-[6px] w-[380px] h-[315px]">
              <div className="mb-[15px] bg-[#536AFF] w-[81px] rounded-[12px] p-[10px] h-[78px]">
                <img alt="realestatesicon" src={realestatesicon} />
              </div>
              <div className=" mb-[8px] flex items-center">
                <h1 className=" text-[25px] font-normal">Real Estate </h1>
                <FaArrowRightLong className=" ml-[10px] text-[25px]" />
              </div>
              <p className="text-[18px]">
                Simplified AI solutions for managing real estate and property transactions.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] h-auto w-[1903px] mx-auto p-[20px] border-b-[0.1px]  border-[#a3d2f3]">
        <div className=" m-auto container pt-5 pb-5">
          <h1 className="text-[22px] w-[143px] mb-[5px] leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
            Portfolio</h1>
          <p className="text-[32px] font-medium mb-[25px]">
            Our AI Development Services Success Stories
          </p>
          <div className="w-[1200px] rounde-[12px] h-auto">
            <div className="sticky top-[10rem] mb-[35px] flex justify-center">
              <div className="w-[660px] h-[549px] rounded-tl-[12px] rounded-bl-[12px] bg-gradient-to-r from-[#1f0a32] to-[#56099c] p-[30px]">
                <img alt="img" className="w-[120px] h-[54px]" src={koovsicon} />
                <h1 className="text-[28px] text-[#9b65c3] font-medium mb-[8px]">Our Feature Work</h1>
                <p className="text-[25px] mb-[25px] font-medium leading-[30px] max-w-[530px]">
                  Retail Inventory Management Solution Featuring Virtual Assistant Driven by Chatbot Integration
                </p>
                <p className="text-[18px] leading-[23px] font-normal max-w-[630px] text-[#dadada] mb-[25px]">
                  Incorporating chatbots and virtual assistants involved developing AI models capable of understanding
                  and responding to a variety of inventory-related queries..
                </p>
                <div className="flex mb-[25px] items-center">
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[110px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Android</h1>
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[74px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">IOS</h1>
                  <h1 className="text-[18px] leading-[23px] w-[82px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Web</h1>
                </div>
                <button className="w-[190px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                  <div class=" bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-[300px] text-[18px] p-[10px] w-[184px] h-[50px] m-auto text-[#fff]">Visit Portfolio</div>
                </button>
              </div>
              <div className="w-[660px] h-[549px] rounded-tr-[12px] rounded-br-[12px] bg-[#000] p-[30px]">
                <img alt="img" className="w-[408px] h-[415px] m-auto" src={koovsweb} />
              </div>
            </div>
            <div className="sticky top-[10rem] mb-[35px] flex justify-center">
              <div className="w-[660px] h-[549px] rounded-tl-[12px] rounded-bl-[12px] bg-[#000] p-[30px]">
                <img alt="img" className="w-[408px] h-[415px] m-auto" src={revazaweb} />
              </div>
              <div className="w-[660px] h-[549px] rounded-tr-[12px] rounded-br-[12px] bg-gradient-to-r from-[#1f0a32] to-[#56099c] p-[30px]">
                <img alt="img" className="w-[120px] h-[54px]" src={revezaicon} />
                <h1 className="text-[28px] text-[#9b65c3] font-medium mb-[8px]">Our Feature Work</h1>
                <p className="text-[25px] mb-[25px] font-medium leading-[30px] max-w-[530px]">
                  Reveza - AI Transforming the Customer Journey
                </p>
                <p className="text-[18px] leading-[23px] font-normal max-w-[630px] text-[#dadada] mb-[25px]">
                  Reveza empowers brand's journey with cutting edge platform, seamlessly integrating AI to capture customer sentiment from pre
                  to post purchase by unleashing the power of reviews, Realtime responsive engagement, and also instant gratification reward model
                  to transform brand's customer experience fostering unwavering loyalty.
                </p>
                <div className="flex mb-[25px] items-center">
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[110px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Android</h1>
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[74px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">IOS</h1>
                  <h1 className="text-[18px] leading-[23px] w-[82px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Web</h1>
                </div>
                <button className="w-[190px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                  <div class=" bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-[300px] text-[18px] p-[10px] w-[184px] h-[50px] m-auto text-[#fff]">Visit Portfolio</div>
                </button>
              </div>
            </div>
            <div className="sticky top-[10rem] mb-[35px] flex justify-center">
              <div className="w-[660px] h-[549px] rounded-tl-[12px] rounded-bl-[12px] bg-gradient-to-r from-[#1f0a32] to-[#56099c] p-[30px]">
                <img alt="esimg" className="w-[120px] h-[54px]" src={easecareicon} />
                <h1 className="text-[28px] text-[#9b65c3] font-medium mb-[8px]">Our Feature Work</h1>
                <p className="text-[25px] mb-[25px] font-medium leading-[30px] max-w-[530px]">
                  Personalized Health Predictions Driven by Predictive Analytics, NLP, and ML
                </p>
                <p className="text-[18px] leading-[23px] font-normal max-w-[630px] text-[#dadada] mb-[25px]">
                  We developed a Predictive Analytics system using Natural Language Processing and Machine Learning to forecast health trends
                  and risks. Our system analyzes longitudinal health data, learning and adapting to provide personalized health predictions.
                </p>
                <div className="flex mb-[25px] items-center">
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[110px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Android</h1>
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[74px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">IOS</h1>
                  <h1 className="text-[18px] leading-[23px] w-[82px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Web</h1>
                </div>
                <button className="w-[190px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                  <div class=" bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-[300px] text-[18px] p-[10px] w-[184px] h-[50px] m-auto text-[#fff]">Visit Portfolio</div>
                </button>
              </div>
              <div className="w-[660px] h-[549px] rounded-tr-[12px] rounded-br-[12px] bg-[#000] p-[30px]">
                <img alt="easecareweb" className="w-[408px] h-[415px] m-auto" src={easecareweb} />
              </div>
            </div>
            <div className="sticky top-[10rem] mb-[35px] flex justify-center">
              <div className="w-[660px] h-[549px] rounded-tl-[12px] rounded-bl-[12px] bg-[#000] p-[30px]">
                <img alt="rightangleweb" className="w-[408px] h-[415px] m-auto" src={rightangleweb} />
              </div>
              <div className="w-[660px] h-[549px] rounded-tr-[12px] rounded-br-[12px] bg-gradient-to-r from-[#1f0a32] to-[#56099c] p-[30px]">
                <img alt="rightangleicon" className="w-[120px] h-[54px]" src={rightangleicon} />
                <h1 className="text-[28px] text-[#9b65c3] font-medium mb-[8px]">Our Feature Work</h1>
                <p className="text-[25px] mb-[25px] font-medium leading-[30px] max-w-[530px]">
                  AI Tutor For Personalized Learning
                </p>
                <p className="text-[18px] leading-[23px] font-normal max-w-[630px] text-[#dadada] mb-[25px]">
                  Assisting Students in Reading Comprehension & Writing Tutorial Activities with a Deep Learning -Based Intelligent Tutor.
                  Our solution included using a tool to assist the Subject matter Experts (SMEs) in tagging the content of the course and creating
                  a knowledge Graph to empower the dialogs, questions and the chat flow of the Ai Tutor
                </p>
                <div className="flex mb-[25px] items-center">
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[110px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Android</h1>
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[74px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">IOS</h1>
                  <h1 className="text-[18px] leading-[23px] w-[82px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Web</h1>
                </div>
                <button className="w-[190px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                  <div class=" bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-[300px] text-[18px] p-[10px] w-[184px] h-[50px] m-auto text-[#fff]">Visit Portfolio</div>
                </button>
              </div>
            </div>
            <div className="sticky top-[10rem] mb-[35px] flex justify-center">
              <div className="w-[660px] h-[549px] rounded-tl-[12px] rounded-bl-[12px] bg-gradient-to-r from-[#1f0a32] to-[#56099c] p-[30px]">
                <img alt="docaicon" className="w-[120px] h-[54px]" src={docaicon} />
                <h1 className="text-[28px] text-[#9b65c3] font-medium mb-[8px]">Our Feature Work</h1>
                <p className="text-[25px] mb-[25px] font-medium leading-[30px] max-w-[530px]">
                  Document Process Using Gen AI
                </p>
                <p className="text-[18px] leading-[23px] font-normal max-w-[630px] text-[#dadada] mb-[25px]">
                  The customer is a top US health-tech company who wanted to automate the underwriting process for its client’s New Business
                  Applications, Childcare, and Worker Compensation Applications.
                  We have has deployed a sandbox that ingests the emails automatically and processes the attachments as required by the business.
                </p>
                <div className="flex mb-[25px] items-center">
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[110px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Android</h1>
                  <h1 className="text-[18px] mr-[15px] leading-[23px] w-[74px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">IOS</h1>
                  <h1 className="text-[18px] leading-[23px] w-[82px] h-[33px] p-[5px] text-center rounded-[30px] bg-[#470E7E] font-normal">Web</h1>
                </div>
                <button className="w-[190px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                  <div class=" bg-gradient-to-r from-[#1f0a32] to-[#56099c] rounded-[300px] text-[18px] p-[10px] w-[184px] h-[50px] m-auto text-[#fff]">Visit Portfolio</div>
                </button>
              </div>
              <div className="w-[660px] h-[549px] rounded-tr-[12px] rounded-br-[12px] bg-[#000] p-[30px]">
                <img alt="docaiweb" className="w-[408px] h-[415px] m-auto" src={docaiweb} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-[1903px] mx-auto p-[20px] border-b-[0.1px]  h-auto border-[#a3d2f3] ">
        <div className=" m-auto container pt-5 pb-5">
          <div className="grid grid-cols-2 items-center">
            <div>
              <h1 className="text-[22px] w-[143px] mb-[5px] leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
                Process</h1>
              <h1 className="text-[32px] mb-[15px] font-medium ">AI-Driven Software Development Process</h1>
              <p className="text-[18px] text-[#DADADA] mb-[25px]">
                Check out the steps it takes us to make your project a success
              </p>
              <h1 className="text-[18px] flex items-center mb-[15px]">
                <span className=" mr-[15px] w-[30px] h-[1px] bg-[#bf63c6]"></span>Discovery</h1>
              <h1 className="text-[18px] flex items-center mb-[15px]">
                <span className=" mr-[15px] w-[30px] h-[1px] bg-[#bf63c6]"></span>Project Setup</h1>
              <h1 className="text-[18px] flex items-center mb-[15px]">
                <span className=" mr-[15px] w-[30px] h-[1px] bg-[#bf63c6]"></span>Development</h1>
              <h1 className="text-[18px] flex items-center mb-[15px]">
                <span className=" mr-[15px] w-[30px] h-[1px] bg-[#bf63c6]"></span>Testing</h1>
              <h1 className="text-[18px] flex items-center mb-[15px]">
                <span className=" mr-[15px] w-[30px] h-[1px] bg-[#bf63c6]"></span>Live Release</h1>
              <h1 className="text-[18px] flex items-center mb-[15px]">
                <span className=" mr-[15px] w-[30px] h-[1px] bg-[#bf63c6]"></span>Support</h1>
              <h1 className="text-[18px] flex items-center mb-[25px]">
                <span className=" mr-[15px] w-[30px] h-[1px] bg-[#bf63c6]"></span>Maintenance</h1>
              <button className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                <div class=" bg-gradient-to-r from-[#26123C] to-[#040206] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">Talk to Experts</div>
              </button>
            </div>
            <img alt="img" src={artificialprocessimage} />
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-[1903px] mx-auto p-[20px] border-b-[0.1px]  h-auto border-[#a3d2f3] ">
        <div className=" m-auto container pt-5 pb-5">
          <div className="grid grid-cols-2 justify-center">
            <div>
              <h1 className="text-[22px] w-[143px] mb-[5px] leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
                Technology</h1>
              <p className="text-[32px] font-medium mb-[25px]">
                Alliances and Partnerships
              </p>
              <p className="text-[18px] mb-[25px]">
                We work closely with various partners and platforms to achieve flexible and efficient results while ensuring high
                quality and excellence.
              </p>
              <p className="text-[18px] mb-[25px]">
                We continually exceed expectations by delivering exceptional work that not only meets but exceeds custom requirements.
                As a leading provider of AI development services, we collaborate with clients worldwide to develop and expand their digital
                products using high-quality technologies such as artificial intelligence, cloud computing, data science, analytics, AR/VR,
                IoT, blockchain, and the metaverse.
              </p>
              <div className="flex items-center ">
                <div className="w-[211px] h-[200px] p-[10px] border-r-[2px] border-r-[#127E9B] ml-[10px] ">
                  <h1 className="text-[85px] h-auto w-[143px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
                    13+
                  </h1>
                  <span className="text-[18px] mr-[15px]">Ai Solutions</span>
                </div>
                <div className="w-[211px] h-[200px] p-[10px] border-r-[2px] border-r-[#127E9B] ml-[10px] ">
                  <h1 className="text-[85px] h-auto w-[143px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
                    10+
                  </h1>
                  <span className="text-[18px] mr-[15px]">Ai Engineers</span>
                </div>
                <div className="w-[211px] h-[200px] p-[10px]">
                  <h1 className="text-[85px] h-auto w-[143px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
                    6+
                  </h1>
                  <span className="text-[18px] mr-[15px]">Work Experience</span>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-[25px] flex items-center ">
                <div className="w-[196px] h-[170px] bg-[#310A64] p-[25px] rounded-[20px]">
                  <img alt="img" className=" mt-[40px]" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                </div>
                <div className="w-[196px] h-[170px] ml-[25px] bg-[#310A64] p-[25px] rounded-[20px]">
                  <img alt="img" className="w-[180px] h-[50px] m-auto mt-[30px]" src="https://bit-front-eight.vercel.app/images2/14p.jpg" />
                </div>
                <div className="w-[196px] h-[170px] ml-[25px] bg-[#310A64] p-[25px] rounded-[20px]">
                  <img alt="img" className="w-[180px] h-[80px] m-auto" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                </div>
              </div>
              <div className="mb-[25px] flex items-center ">
                <div className="w-[196px] h-[170px] bg-[#310A64] p-[25px] rounded-[20px]">
                  <img alt="img" className="w-[100px] h-[100px] m-auto" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                </div>
                <div className="w-[196px] h-[170px] ml-[25px] bg-[#310A64] p-[25px] rounded-[20px]">
                  <img alt="img" className="w-[80px] h-[80px] m-auto" src="https://30mins.com/assets/logo.svg" />
                </div>
                <div className="w-[196px] h-[170px] ml-[25px] bg-[#310A64] p-[25px] rounded-[20px]">
                  <img alt="img" className="" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                </div>
              </div>
              <div className="mb-[25px] flex items-center ">
                <div className="w-[196px] h-[170px] bg-[#310A64] p-[25px] rounded-[20px]">
                  <img alt="img" className=" w-[133px] h-[25px] m-auto mt-[40px]" src="https://bit-front-eight.vercel.app/images2/9p.jpg" />
                </div>
                <div className="w-[196px] h-[170px] ml-[25px] bg-[#310A64] p-[25px] rounded-[20px]">
                  <img alt="img" className="w-[150px] h-[60px] m-auto mt-[30px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                </div>
                <div className="w-[196px] h-[170px] ml-[25px] bg-[#310A64] p-[25px] rounded-[20px]">
                  <img alt="img" className="w-[120px] h-[120px] m-auto" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-[1903px] mx-auto p-[20px] border-b-[0.1px]  h-auto border-[#a3d2f3] ">
        <div className=" m-auto container pt-5 pb-5">
          <div className="grid grid-cols-2">
            <div>
              <h1 className="text-[22px] w-[143px] mb-[5px] leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
                Technology</h1>
              <h1 className="text-[32px] mb-[15px] font-medium ">Our AI Development Technology Stack</h1>
              <button className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                <div class=" bg-gradient-to-r from-[#26123C] to-[#040206] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">Talk to Experts</div>
              </button>
            </div>
            <img alt="img" className="w-[776px] h-[557px] ml-[20px]" src={artificialtechnologynewimg} />
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-screen mx-auto p-[20px] border-b-[0.1px]  h-auto border-[#a3d2f3] ">
        <div className=" m-auto container pt-5 pb-5">
          <div className="grid grid-cols-2">
            <div>
              <h1 className="text-[22px] w-[143px] mb-[5px] leading-[25px] font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-text text-transparent ">
                Partners</h1>
              <h1 className="text-[32px] mb-[15px] font-medium ">AI Models We Have Expertise In</h1>
              <p className="text-[18px]">
                For over 6+ years, our AI services have enhanced business processes, helping organizations achieve rapid
                growth with the latest AI tools and technologies. Watch out these amazing latest AI Models -
              </p>
            </div>
            <img alt="img" className="w-[746px] h-[471px] ml-[20px]" src={artificialpartnermin} />
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-[1903px] mx-auto p-[20px] border-b-[0.1px]  h-auto border-[#a3d2f3] ">
        <div className=" mt-[40px] flex justify-center">
          <div className="w-[550px] h-auto">
            <h1 className="text-[32px] font-medium leading-[32px] mb-[25px]">Why choose us?</h1>
            <p className="text-[18px] leading-[33px] font-normal">
              Being one of the dedicated AI services companies, we use our deep domain expertise to push the boundaries
              of what's achievable for your business. Our AI app development services are designed to deliver tangible results
              and unlock the full potential of artificial intelligence.
            </p>
          </div>
          <div className="ml-[40px]">
            <div
              className={` mb-[30px] p-4 rounded-lg border-[2px] ${isOpen
                ? 'bg-black border-gradient-to-r from-[#08dff8] to-[#b811e5]'
                : 'bg-black border-gradient-to-r from-transparent to-transparent'
                }  w-[780px] h-auto`}
              onClick={toggleCard}
            >
              <div className="flex items-center">
                <div className="text-[#fff] text-[20px] w-[32px] h-[32px] rounded-full p-[6px] bg-[#4ab145] mr-2"><GoCheck /></div>
                <h2 className="text-white font-bold">Data-Centric Approach</h2>
              </div>
              {isOpen && (
                <p className="text-white mt-2">
                  We use proven and advanced big data technologies to convert the raw data into actionable insights.
                </p>
              )}
            </div>
            <div
              className={` mb-[30px] p-4 rounded-lg border-[2px] ${isOpen
                ? 'bg-black border-gradient-to-r from-[#08dff8] to-[#b811e5]'
                : 'bg-black border-gradient-to-r from-transparent to-transparent'
                }  w-[780px] h-auto`}
              onClick={toggleCardopen}
            >
              <div className="flex items-center">
                <div className="text-[#fff] text-[20px] w-[32px] h-[32px] rounded-full p-[6px] bg-[#4ab145] mr-2"><GoCheck /></div>
                <h2 className="text-white font-bold">Outcome-Driven</h2>
              </div>
              {isOpens && (
                <div className=" w-[740px] h-auto">
                  <p className="text-white mt-2">
                    Our experienced team of Data Scientists and Customer Success Managers drive ROI by a customer-centric
                    approach and help you achieve your business goals.
                  </p>
                </div>
              )}
            </div>
            <div
              className={` mb-[30px] p-4 rounded-lg border-[2px] ${isOpen
                ? 'bg-black border-gradient-to-r from-[#08dff8] to-[#b811e5]'
                : 'bg-black border-gradient-to-r from-transparent to-transparent'
                }  w-[780px] h-auto`}
              onClick={toggleCardopent}
            >
              <div className="flex items-center">
                <div className="text-[#fff] text-[20px] w-[32px] h-[32px] rounded-full p-[6px] bg-[#4ab145] mr-2"><GoCheck /></div>
                <h2 className="text-white font-bold">Transparent & Secure</h2>
              </div>
              {isOpent && (
                <div className=" w-[740px] h-auto">
                  <p className="text-white mt-2">
                    We are governed by ISO 27001 to safeguard your data with complete transparency through data security
                    privacy and comply with all data compliance requirements.
                  </p>
                </div>
              )}
            </div>
            <div
              className={` mb-[30px] p-4 rounded-lg border-[2px] ${isOpen
                ? 'bg-black border-gradient-to-r from-[#08dff8] to-[#b811e5]'
                : 'bg-black border-gradient-to-r from-transparent to-transparent'
                }  w-[780px] h-auto`}
              onClick={toggleCardopenf}
            >
              <div className="flex items-center">
                <div className="text-[#fff] text-[20px] w-[32px] h-[32px] rounded-full p-[6px] bg-[#4ab145] mr-2"><GoCheck /></div>
                <h2 className="text-white font-bold">Proven proficiency</h2>
              </div>
              {isOpenf && (
                <div className=" w-[740px] h-auto">
                  <p className="text-white mt-2">
                    Our AI specialists are well-versed in IT trends, artificial intelligence algorithms, and deep learning architectures.
                  </p>
                </div>
              )}
            </div>
            <div
              className={` mb-[30px] p-4 rounded-lg border-[2px] ${isOpen
                ? 'bg-black border-gradient-to-r from-[#08dff8] to-[#b811e5]'
                : 'bg-black border-gradient-to-r from-transparent to-transparent'
                }  w-[780px] h-auto`}
              onClick={toggleCardopenfi}
            >
              <div className="flex items-center">
                <div className="text-[#fff] text-[20px] w-[32px] h-[32px] rounded-full p-[6px] bg-[#4ab145] mr-2"><GoCheck /></div>
                <h2 className="text-white font-bold">Technology Agnostic</h2>
              </div>
              {isOpenfi && (
                <div className=" w-[740px] h-auto">
                  <p className="text-white mt-2">
                    As a technology-agnostic company, we maintain expertise in multiple technologies and help
                    you choose the right technology for your business needs.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-[1903px] mx-auto p-[40px] border-b-[0.1px]  h-auto border-[#a3d2f3] ">
        <div className="w-[1296px] border-transparent p-[3px] rounded-[20px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-border h-[255px] m-auto">
          <div className=" text-center w-[1290px] h-[249px] p-[20px] rounded-[20px] m-auto bg-gradient-to-r from-[#26123C] to-[#040206]">
            <h1 className="text-[32px] text-center font-medium mb-[25px] ">Let’s discuss your AI/ML project and transform your business with our expert AI development services.</h1>
            <button onClick={() => navigate("/contact-us")} className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
              <div class=" bg-[#000] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">Contact Us</div>
            </button>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-r from-[#26123C] to-[#040206] w-[1903px] mx-auto p-[20px] border-b-[0.1px]  h-auto border-[#a3d2f3] ">
     <h1 className="text-[32px] font-medium w-[1200px] m-auto mb-[25px]">FAQs</h1>  
        <div>
          <div className="App text-[#fff]">
            <div className="faqs">
              {faqs.map((faqq, index) => (
                <FAQsw faqq={faqq} index={index} key={index} toggleFAQ={toggleFAQ} />
              ))}
            </div>
          </div>
        </div>

      </div>

      <Footerq />
    </div>
  )
}

export default BitfrontAI
