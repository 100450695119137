import React from 'react';
import Header from '../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "./Footer";
import shopifyvid from '../assets/images/shopifyvid.mp4';
import LeadingShopifyAppDevelopment from '../assets/images/LeadingShopifyAppDevelopment.png';
import CustomShopifyAppDevelopment from '../assets/images/CustomShopifyAppDevelopment.png';
import ShopifyIntegrations from '../assets/images/ShopifyIntegrations.png';
import ShopifyMigration from '../assets/images/ShopifyMigration.png';
import ShopifyThemeDevelopment from '../assets/images/ShopifyThemeDevelopment.png';
import hhelth from '../assets/images/hhelth.png';
import EcommerceSolutions from '../assets/images/EcommerceSolutions.png';
import networking from '../assets/images/networking.png';
import banking from '../assets/images/banking.png';
import Travel from '../assets/images/Travel.png';
import Media from '../assets/images/Media.png';
import EntertainmentWebSolutions from '../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../assets/images/EdutechSolutions.png';
const Shopifyappdevelopment = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Contact-us');
    };
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];
    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <div className="max-w-7xl h-[580px]	 bg-white rounded-2xl m-auto mt-[80px] ">
                <div className="flex justify-center gap-[0rem] p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]"> Shopify Development</span> <br />Company</h1>
                        <p className="text-[18px]">
                            Turning your Shopify Store into a Mobile App to increase profitability for your e-commerce or D2C business with higher conversions, reduced acquisition costs, and increased delivery rates.
                        </p>
                        <li className="text-[18px] pt-[10px]">Shopify Store Development</li>
                        <li className="text-[18px] pt-[10px]">Shopify Theme Development</li>
                        <li className="text-[18px] pt-[10px]">Shopify Store Migration Services</li>
                        <li className="text-[18px] pt-[10px]">Shopify App Integrations</li>
                        <li className="text-[18px] pt-[10px]">Shopify Support & Maintenance</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd]	border-2 w-[200px] mt-10 h-[40px]">TALK TO OUR EXPERT</button>
                    </div>
                    <video className="w-[604px] h-[535px] z-99 object-cover" autoplay="autoplay" loop="auto" muted="no" src={shopifyvid}>
                        <source poster="" type="video/mp4" /> Your browser does not support the video tag.</video>
                </div>
            </div>

            <div className="h-[148.8px] ml-[300px] mt-[80px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>

            <div className="w-[1900px] h-[622px] bg-white m-auto mt-[30px]">
                <div className="flex justify-center items-center pt-[35px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            Leading  <span className="text-[#0652dd]">Shopify App Development</span> <br />
                            Company  in India
                        </h1>
                        <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
                            BitFront stands as an expert <b><u>Shopify app development company</u></b>, proficient<br />
                            in crafting customized solutions for <b>Shopify online store</b> development and<br />
                            maintenance services. With a deep understanding of Shopify's mechanics,<br />
                            we excel in crafting stunning Shopify stores, from theme selection to product<br />
                            integration and <b>configuring payment and shipping</b> methods essential for<br />
                            store launch. Whether designing a new store from scratch or maintaining an<br />
                            already existing store, we can deliver you the most advanced solutions with<br />
                            rich features and seamless functionality, helping you achieve your business<br />
                            goals.
                        </p>
                        <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
                            <b>Our designers are good at making Shopify themes</b> that work well on all<br />
                            devices and browsers. We know every online business is unique, so we<br />
                            create a <b>custom Shopify store just for you</b>. This store will be perfect for<br />
                            your business and will attract the right customers. We're not just good at<br />
                            making websites, we can also build special features and apps for your<br />
                            Shopify store.
                        </p>
                    </div>
                    <img alt='Leading Shopify App Development' className="w-[373px] h-[317px]" src={LeadingShopifyAppDevelopment} />
                </div>
            </div>

            <div className="flex justify-center gap-10 mt-[29px]">
                <div>
                    <h1 className="text-5xl font-bold">Our
                        <span className="text-[#0652dd]"> Shopify App</span>Development<br /> Services</h1>
                    <p className="text-[18px] leading-[29px] py-[10px]">
                        With our expertise in eCommerce and latest trends, we can create apps for<br />
                        various business needs, whether B2B, B2C, C2B, or other customer sectors,<br />
                        at any scale. We use Shopify’s powerful themes and extensive functionality<br />
                        to create a competitive online store for your business.
                    </p>
                    <p className="text-[18px] leading-[29px] py-[10px]">
                        Transforming your online store with Shopify is effortless—from setup to<br />
                        sales. Elevate your store's performance with our tailored Shopify web<br />
                        design services and stand out from the competition. We always involve our<br />
                        partners in eliminating issues and finding the most effective solutions. This<br />
                        is how we ensure the expected results for every project we work on.
                    </p>
                    <button className="w-[170px] h-[40px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
                        <b>CONTACT NOW</b></button>
                </div>
                <div>
                    <div className=" mb-[20px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='Custom Shopify App Development' className="w-9 h-9" src={CustomShopifyAppDevelopment} />
                            <h4 className=" text-[20px] font-bold">Custom Shopify App Development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            Our experts can make a special Shopify app that works smoothly with
                            your online store, making shopping easier for customers. By using
                            Shopify's main features, we create a mobile shopping solution that
                            adds more options to your store and makes shopping more personal.
                        </p>
                    </div>

                    <div className=" mb-[20px] w-[556px] h-[200px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='Shopify Integrations' className="w-9 h-9" src={ShopifyIntegrations} />
                            <h4 className=" text-[20px] font-bold">Shopify Integrations</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            We customize Shopify integrations to improve your eCommerce store's
                            functions like order, vendor, payment, and inventory management,
                            boosting its efficiency.
                        </p>
                    </div>

                    <div className=" mb-[20px] w-[556px] h-[200px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='Shopify Migration' className="w-9 h-9" src={ShopifyMigration} />
                            <h4 className=" text-[20px] font-bold">Shopify Migration</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            Our experienced developers can smoothly move your online store to
                            Shopify, using its powerful features and app store to create a
                            user-friendly interface and dynamic Shopify app.
                        </p>
                    </div>

                    <div className="w-[556px] h-[180px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='Shopify Theme Development' className="w-9 h-9" src={ShopifyThemeDevelopment} />
                            <h4 className=" text-[20px] font-bold">Shopify Theme Development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            Our experts craft custom Shopify themes to fit your brand, creating intuitive,
                            feature-rich, and mobile-friendly designs for your online store.
                        </p>
                    </div>
                </div>
            </div>

            <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
                <div className="w-full h-auto bg-[#fafafa] p-5">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 h-auto shadow-md bg-white rounded-lg p-6">
                                <p className="text-lg flex gap-2 items-center">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-base pt-4">
                                    "{review.reviewText}"
                                    <br /><br />
                                    {review.author}
                                    <br /><br />
                                    {review.verified}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Shopify App</span> Development Process</h1>
            <p className="text-[18px] text-center mt-4">
                Proven development methods focused on creating top-notch Shopify mobile apps with successful outcomes.
            </p>
            <div className="flex gap-1 items-center justify-center mt-8">
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
            </div>


            <h1 className="text-5xl text-center mt-15 font-bold mt-9">Our <span className="text-[#0652dd]"> Multi-industry</span> Experience</h1>
            <br />
            <p className="text-lg text-center">
                At BitFront, we offer Shopify mobile app development services to a wide range of businesses across the globe.
            </p>
            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>

            <div className="w-[1903px] h-[480px] bg-white pt-[40px] pl-[300px] mt-[80px]">
                <h1 className="text-[#0652dd] text-[40px] font-bold">Why Choose Us?</h1>
                <p className="text-[21px] text-[#292929]">Our certified and experienced specialists build revenue-driven and customer-centric e-commerce solutions.</p>
                <li className="text-[18px] pt-[10px]">Affordable Solutions for Improved Budgeting, Risk Control, and Project Planning.</li>
                <li className="text-[18px] pt-[10px]">70+ Experienced e-commerce specialists for Building a Successful Product.</li>
                <li className="text-[18px] pt-[10px]">E-commerce Development Experience That Ensures Quality-centric Deliveries.</li>
                <li className="text-[18px] pt-[10px]">Custom Shopify Development Services to Meet Specific Needs.</li>
                <li className="text-[18px] pt-[10px]">Personalized Shopify Development to Improve Operational Efficiency and Customer Experience.</li>
                <li className="text-[18px] pt-[10px]">Future-proof Technology Utilized for Shopify App Development.</li>
                <li className="text-[18px] pt-[10px]">Agile Development Team to Meet Project Deadlines Faster</li>
                <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd]	border-2 w-[200px] mt-10 h-[40px]">TALK TO OUR EXPERT</button>
            </div>


            <div class="bg-blue-700 w-3/4 h-48 mx-auto text-white text-center pt-5 rounded-lg mt-10">
                <h3 class="text-center text-3xl font-bold">How much does it cost to develop a web application?</h3>
                <p class="text-lg pt-4">
                    The total cost of the Shopify Mobile App Development can be as low as $15,000 or as high as $50,000 depending on the complexity, and features<br />
                    of the store & more.</p>
                <div>
                    <button onClick={handleClick} class="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[20px]"><b>Contact Us</b></button>
                </div>
            </div>

            <h1 className="text-[40px] text-center font-bold mt-[70px]">Looking for the best Mobile App Development Services for your<br />
                business?</h1>
            <p className="text-[18px] text-center">We help top brands deliver quality products. Share your new business needs, and let us make it a success.</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="emergeSite.png" src="/images2/emergeSite.png"></img>
                <div className="w-[344px] absolute top-[83px] right-[134px]">
                    <p className="text-lg">
                        Re-building Emerge - A finance app making it easy for financial experts to make money.
                    </p>
                    <div className="inline-row">
                        <div>
                            <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">4.4<p className="text-[#DE5B48] text-[10px] flex gap-1"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p></span>
                            <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                        </div>
                        <div>
                            <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                            <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                        </div>
                    </div>
                    <h1 className="my-3">1 Million+ downloads</h1>
                    <div className="flex gap-4">
                        <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                            <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" /><p><b>View Case Study</b></p>
                        </button>
                        <img className="w-[124px] h-[36px] cursor-pointer" alt="41p.jpg" src="/images2/41p.jpg" />
                    </div>
                </div>
                <div>
                    <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                    <p className="my-2">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                        in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                        scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                </div>
                <div className="flex items-center gap-4">
                    <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                    <div>
                        <p>Corey Smith</p>
                        <p>CEO, Emerge</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Shopifyappdevelopment
