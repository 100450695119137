import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Career from './pages/Career';
import Blog from './pages/Blog';
import Mobile from './pages/Mobile';
import Contactus from './pages/Contactus';
import AndroidAppDevelopment from './pages/AndroidAppDevelopment';
import Phone from './pages/Phone';
import Flutter from './pages/Flutter';
import Reactnativedevelopment from './pages/Reactnativedevelopment';
import Webappdevelopment from './pages/Webappdevelopment';
import Cloudappdevelopment from './pages/Cloudappdevelopment';
import Webdesigningdevelopment from './pages/Webdesigningdevelopment';
import Shopifyappdevelopment from './pages/Shopifyappdevelopment';
import UIUXdesign from './pages/UIUXdesign';
import Chatgptdevelopment from './pages/Chatgptdevelopment';
import BitfrontAI from './pages/BitfrontAi';
import GenerativeAi from './pages/GenerativeAi';
import DevOpsConsulting from './pages/DevOpsConsulting';
import Machinelearning from './pages/Machinelearning';
import OTTAppDevelopment from './pages/OTTAppDevelopment';
import Blockchaindevelopment from './pages/Blockchaindevelopment';
import ARVRappdevelopment from './pages/ARVRappdevelopment';
import DeepLearningDevelopment from './pages/DeepLearningDevelopment';
import AiDevelopment from './pages/AiDevelopment';
import HybridAppDevelopment from './pages/HybridAppDevelopment';
import NativeAppDevelopment from './pages/NativeAppDevelopment';
import Healthcare from './pages/Healthcare';
import Fintech from './pages/Fintech';
import Ecommerce from './pages/Ecommerce';
import AutotechEv from './pages/AutotechEv';
import EdtechElearning from './pages/EdtechElearning';
import TravelTech from './pages/TravelTech';
import EsportGameApp from './pages/EsportGameApp';
import Logistic from './pages/Logistic';
import SocialNetworking from './pages/SocialNetworking';
import Entertainment from './pages/Entertainment';
import RealEstate from './pages/RealEstate';
import CustomizableApp from './pages/CustomizableApp';
import OnDemandAppDevelopment from './pages/OnDemandAppDevelopment';
import ArchitectureService from './pages/ArchitectureService';
import StartupAppDevelopment from './pages/StartupAppDevelopment';
import EnterpriseSoftwareDevelopment from './pages/EnterpriseSoftwareDevelopment';
import './App.css';
function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Mobile" element={<Mobile />} />
          <Route path="/Contact-us" element={<Contactus />} />
          <Route path="/Android-App-Development" element={<AndroidAppDevelopment />} />
          <Route path="/Phone" element={<Phone />} />
          <Route path="/Flutter" element={<Flutter />} />
          <Route path="/React-native-development" element={<Reactnativedevelopment />} />
          <Route path="/Web-app-development" element={<Webappdevelopment />} />
          <Route path="/Cloud-app-development" element={<Cloudappdevelopment />} />
          <Route path="/Web-designing-development" element={<Webdesigningdevelopment />} />
          <Route path="/Shopify-app-development" element={<Shopifyappdevelopment />} />
          <Route path="/UI-UX-design" element={<UIUXdesign />} />
          <Route path="/Chatgpt-development" element={<Chatgptdevelopment />} />
          <Route path="/Generative-Ai" element={<GenerativeAi />} />
          <Route path="/Dev-Ops-Consulting" element={<DevOpsConsulting />} />
          <Route path="/Machine-learning" element={<Machinelearning />} />
          <Route path="/OTT-App-Development" element={<OTTAppDevelopment />} />
          <Route path="/Block-chain-development" element={<Blockchaindevelopment />} />
          <Route path="/AR-VR-app-development" element={<ARVRappdevelopment />} />
          <Route path="/Deep-Learning-Development" element={<DeepLearningDevelopment />} />
          <Route path="/Ai-Development" element={<AiDevelopment />} />
          <Route path="/Hybrid-App-Development" element={<HybridAppDevelopment />} />
          <Route path="/Native-App-Development" element={<NativeAppDevelopment />} />
          <Route path="/Healthcare" element={<Healthcare />} />
          <Route path="/Bitfront-AI" element={<BitfrontAI />} />
          <Route path="/Fintech" element={<Fintech />} />
          <Route path="/Ecommerce" element={<Ecommerce />} />
          <Route path="/Auto-tech-Ev" element={<AutotechEv />} />
          <Route path="/EdtechElearning" element={<EdtechElearning />} />
          <Route path="/Travel-Tech" element={<TravelTech />} />
          <Route path="/Esport-Game-App" element={<EsportGameApp />} />
          <Route path="/Logistic" element={<Logistic />} />
          <Route path="/Social-Net-working" element={<SocialNetworking />} />
          <Route path="/Entertainment" element={<Entertainment />} />
          <Route path="/Real-E-state" element={<RealEstate />} />
          <Route path="/Customizable-App" element={<CustomizableApp />} />
          <Route path="/On-Demand-App-Development" element={<OnDemandAppDevelopment />} />
          <Route path="/Architecture-Service" element={<ArchitectureService />} />
          <Route path="/Startup-App-Development" element={<StartupAppDevelopment />} />
          <Route path="/Enterprise-Software-Development" element={<EnterpriseSoftwareDevelopment />} />
        </Routes>
         
      </div>
      
    </Router>
  );
}

export default App;
