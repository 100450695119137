import React, { useState } from 'react';
import Header from '../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Footer from "./Footer";
import FAQ from "../components/FAQ/FAQ";
import BlockchainDevelopment from '../assets/images/BlockchainDevelopment.png';
import LeadingBlockchainDevelopment from '../assets/images/LeadingBlockchainDevelopment.png';
import BlockchainTechnologyConsulting from '../assets/images/BlockchainTechnologyConsulting.png';
import dAppsDevelopment from '../assets/images/dAppsDevelopment.png';
import NFTMarketplaceDevelopment from '../assets/images/NFTMarketplaceDevelopment.png';
import CryptoWallet from '../assets/images/CryptoWallet.png';
import hhelth from '../assets/images/hhelth.png';
import EcommerceSolutions from '../assets/images/EcommerceSolutions.png';
import networking from '../assets/images/networking.png';
import banking from '../assets/images/banking.png';
import Travel from '../assets/images/Travel.png';
import Media from '../assets/images/Media.png';
import EntertainmentWebSolutions from '../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../assets/images/EdutechSolutions.png';
const BlockchainDdevelopment = () => {


    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Contact-us');
    };

    const [faqs, setFaqs] = useState([
        {
            question: "Quality Assurance",
            answer:
                "Most prominent app development solutions are used by our team of experts providing quality and effectiveness to our clients.",
            open: false
        },
        {
            question: "Real-Time Support",
            answer: "Being professional, we offer a wide range of support to users in real-time. This can be via phone, e-mail, online chat etc.",
            open: false
        },
        {
            question: "Cost Effectiveness",
            answer: "We provide an affordable and superb quality service that sharply reflects your business and even fits your budget.",
            open: false
        },
        {
            question: "High Level Of Expertise",
            answer: "Our skilful developers create outstanding and truly mesmerizing Blockchain solutions and are responsible for ensuring a high level of Expertise.",
            open: false
        },

    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];

    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <div className="max-w-7xl h-[580px]	 bg-white rounded-2xl m-auto mt-[80px] ">
                <div className="grid grid-cols-2 justify-center items-center gap-[0rem] p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]">Blockchain Development</span><br />Company</h1>
                        <p className="text-[18px]">
                            Elevate your business success with our custom blockchain development
                            services. As a blockchain app development company, we specialize in
                            NFTs and Web 3.0 development.
                        </p>
                        <li className="text-[18px] pt-[10px]">Decentralized Structure</li>
                        <li className="text-[18px] pt-[10px]">Security & Privacy</li>
                        <li className="text-[18px] pt-[10px]">Network Distribution</li>
                        <li className="text-[18px] pt-[10px]">Visibility And Traceability</li>
                        <li className="text-[18px] pt-[10px]">Reduced Costs</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd]	border-2 w-[200px] mt-10 h-[40px]">TALK TO OUR EXPERT</button>
                    </div>
                    <img alt='BlockchainDevelopment' className="w-[479px] h-[470px] ml-[40px]" src={BlockchainDevelopment} />
                </div>
            </div>



            <div className="h-[148.8px] ml-[300px] mt-[80px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>


            <div className="w-[1900px] h-[622px] bg-white  mt-[30px]">
                <div className="grid grid-cols-2 justify-center items-center pl-[250px] pr-[250px] pt-[35px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            Leading  <span className="text-[#0652dd]">Blockchain Development</span> Company in India
                        </h1>
                        <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
                            Have you been running your business for a while but haven't gone online<br />
                            yet? Want to take your business to the next level of success? Need to create<br />
                            a website from scratch or update your current one? No matter what industry<br />
                            you're in, our skilled team at BitFront can build a website that goes beyond<br />
                            your expectations in terms of performance and usability. We offer a single<br />
                            destination for various web development services, delivering fully personalized<br />
                            and adaptable websites - providing world-class solutions for our valued clients.
                        </p>
                        <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
                            We're a top <b><u>web design and development company in India</u></b>. We can help<br />
                            define your brand and increase demand for your products or services by<br />
                            focusing on your customers and using data. We know how important it is to<br />
                            have a great website nowadays, so we include things like <b>SEO, PPC,</b> and<br />
                            <b>content marketing</b> to get outstanding results. As your website design<br />
                            and development company, let us guide you to the right website platform<br />
                            selection.
                        </p>
                    </div>
                    <img alt='leadingblockchain' className="w-[626px] h-[573px]" src={LeadingBlockchainDevelopment} />
                </div>
            </div>



            <div className="flex justify-center gap-10 mt-[29px]">
                <div>
                    <h1 className="text-5xl font-bold">Our
                        <span className="text-[#0652dd]"> Blockchain Development</span><br /> Services</h1>
                    <p className="text-[18px] leading-[29px] py-[10px]">
                        We help startups and enterprises integrate reliable and secure<br />
                        decentralized blockchain solutions for their business automation.<br />
                        Our blockchain solutions are tailored to specific business use cases<br />
                        via feature integration, system modifications, and implementations.
                    </p>
                    <button className="w-[250px] h-[40px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
                        <b>TALK TO OUR DEVELOPERS</b></button>
                </div>
                <div>
                    <div className="w-[556px] h-[180px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1 mb-[15px]">
                            <img alt='Blockchain Technology' className="w-[30px] h-[30px]" src={BlockchainTechnologyConsulting} />
                            <h4 className=" text-[20px] font-bold">Blockchain Technology Consulting</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            Our approach to blockchain consulting begins with what,
                            why, and how blockchain technology can benefit your enterprise
                            solution and bring trust and transparency to the system.
                        </p>
                    </div>
                    <br />
                    <div className="w-[556px] h-[180px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1 mb-[15px]">
                            <img alt='block Apps ' className="w-[30px] h-[30px]" src={dAppsDevelopment} />
                            <h4 className=" text-[20px] font-bold">dApps Development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            From planning to design and development, our blockchain
                            developers build enterprise-grade decentralized applications
                            to help clients accelerate time to market and maximize ROI.
                        </p>
                    </div>
                    <br />
                    <div className="w-[556px] h-[230px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1 mb-[15px]">
                            <img alt='img' className="w-[30px] h-[30px]" src={NFTMarketplaceDevelopment} />
                            <h4 className=" text-[20px] font-bold">NFT Marketplace Development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            We help businesses build their protocol-specific decentralized NFT games
                            development company to organize NFT trading, bidding, and selling digital
                            assets. To solve the interoperability issues, we develop cross-chain marketplaces
                            that facilitate the minting of multi-chain compatible NFTs.
                        </p>
                    </div>
                    <br />
                    <div className="w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1 mb-[15px]">
                            <img alt='CryptoWallet' className="w-[30px] h-[30px]" src={CryptoWallet} />
                            <h4 className=" text-[20px] font-bold">Crypto Wallet Development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            We are a crypto wallet development company that expands your business's
                            DeFi portfolio, ensuring enhanced security and faster transactions for
                            a wider customer base. Our expert blockchain developers craft and launch
                            your crypto wallet with essential features like QR scanning, biometric
                            authentication, and seamless cross-platform functionality.
                        </p>
                    </div>
                </div>
            </div>


            <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
                <div className="w-full h-auto bg-[#fafafa] p-5">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 h-auto shadow-md bg-white rounded-lg p-6">
                                <p className="text-lg flex gap-2 items-center">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-base pt-4">
                                    "{review.reviewText}"
                                    <br /><br />
                                    {review.author}
                                    <br /><br />
                                    {review.verified}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>



            <h1 className="text-5xl text-center mt-15 font-bold mt-9">Transforming Industries with Cutting-Edge<br />
                <span className="text-[#0652dd]">Blockchain Solutions</span></h1>
            <br />
            <p className="text-lg text-center">
                Our cutting-edge blockchain solutions lead innovation, transforming industries for
                greater efficiency and impact.
            </p>
            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>


            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Blockchain Application</span> Development Process</h1>
            <p className="text-[18px] text-center mt-4">
                We turn your ideas into scalable, reliable applications. Our blockchain developers prioritize fast testing for early product maturity.
            </p>
            <div className="flex gap-1 items-center justify-center mt-8">
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
            </div>


            <div class="bg-blue-700 w-3/4 h-48 mx-auto text-white text-center pt-5 rounded-lg mt-10">
                <h3 class="text-center text-3xl font-bold">How much does it cost to develop a web application?</h3>
                <p class="text-lg pt-4">
                    On average, website development costs can vary from $10,000 to $150,000 based on the type of
                    website, design, and web developer costs.</p>
                <div>
                    <button onClick={handleClick} class="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[20px]"><b>Contact Us</b></button>
                </div>
            </div>



            <h1 className="text-center text-4xl mt-12 font-bold">
                <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex justify-center gap-9 mt-11 ">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                    <br />
                    <h5 class="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Global quality standards.</h5>
                    <br />
                    <h5 class="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Monthly/yearly hiring.</h5>
                    <br />
                    <h5 class="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Flexible office hours depend on the timezone.</h5>
                    <br />
                    <h5 class="text-lg flex items-center gap-2  ">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Daily/weekly/monthly reporting via email.</h5>
                </div>
                <div>

                    <div className="App">

                        <div className="faqs">
                            {faqs.map((faq, index) => (
                                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BlockchainDdevelopment
