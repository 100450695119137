import React from 'react';
import Header from '../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "./Footer";
import GenerativeAIDevelopment from '../assets/images/GenerativeAIDevelopment.png';
import landinggenerativeaiimages from '../assets/images/landinggenerativeaiimages.png';
import GenerativeAICustomDevelopment from '../assets/images/GenerativeAICustomDevelopment.png';
import GenerativeAIConsulting from '../assets/images/GenerativeAIConsulting.png';
import AIAuditing from '../assets/images/AIAuditing.png';
import GenerativeAIModelIntegration from '../assets/images/GenerativeAIModelIntegration.png';
import hhelth from '../assets/images/hhelth.png';
import EcommerceSolutions from '../assets/images/EcommerceSolutions.png';
import networking from '../assets/images/networking.png';
import banking from '../assets/images/banking.png';
import Travel from '../assets/images/Travel.png';
import Media from '../assets/images/Media.png';
import EntertainmentWebSolutions from '../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../assets/images/EdutechSolutions.png';
const GenerativeAi = () => {


    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Contact-us');
    };
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];
    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <div className="max-w-7xl h-[580px]	 bg-white rounded-2xl m-auto mt-[80px] ">
                <div className="grid grid-cols-2 justify-center items-center ">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]">Generative AI</span> Development <br />Company</h1>
                        <p className="text-[18px]">
                            Increase day-to-day efficiency in your organization and generate<br />
                            data-driven growth by partnering with our top Generative AI<br />
                            developers.
                        </p>
                        <li className="text-[18px] pt-[10px]">AI/ML consulting</li>
                        <li className="text-[18px] pt-[10px]">AI/ML engineering</li>
                        <li className="text-[18px] pt-[10px]">Generative AI</li>
                        <li className="text-[18px] pt-[10px]">MLOps</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd]	border-2 w-[200px] mt-10 h-[40px]">TALK TO OUR EXPERT</button>
                    </div>
                    <img alt='GenerativeAIDevelopment' className="w-[604px] h-[504px]" src={GenerativeAIDevelopment} />
                </div>
            </div>

            <div className="h-[148.8px] ml-[300px] mt-[80px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>


            <div className="w-[1900px] h-[666px] bg-white m-auto mt-[30px]">
                <div className="flex justify-center items-center pt-[35px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            Experience the Impact of  <span className="text-[#0652dd]">Generative AI</span><br />
                            for Rapid Transformation
                        </h1>
                        <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
                            BitFront is a leading <b>Generative AI Development Company in India</b>,<br />
                            specializing in cutting-edge services designed for a range of innovative<br />
                            applications. We simplify access to this technology, offering tailored<br />
                            solutions aligned with your specific goals. Whether you aim to <b>streamline<br />
                                operations, foster creativity</b>, or <b>enhance customer experiences</b>, our<br />
                            experienced team uses advanced AI algorithms to help achieve your vision<br />
                            for the future. We streamline operations with <b>smart AI solutions</b>, like<br />
                            <b>ChatGPT</b>, Midjourney, DALL-E, and Stable Diffusion. Our experts integrate<br />
                            these models into your business seamlessly. Our software development<br />
                            expertise facilitates efficiency through Code Automation, ensuring thorough<br />
                            bug detection.
                        </p>
                        <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
                            BitFront offers a wide range of generative AI development services from<br />
                            development to post-deployment for various use cases like video, audio,<br />
                            image generation, etc. The OpenAI Developers are dedicated to pushing the<br />
                            boundaries of AI, delivering industry-specific solutions that fully unlock the<br />
                            potential of Generative AI for your projects.
                        </p>
                    </div>
                    <img alt='landinggenerativeaiimages' className="w-[498px] h-[313px]" src={landinggenerativeaiimages} />
                </div>
            </div>


            <div className="flex justify-center gap-10 mt-[29px]">
                <div>
                    <h1 className="text-5xl font-bold">Our
                        <span className="text-[#0652dd]"> Generative AI</span> Development <br /> Services</h1>
                    <p className="text-[18px] leading-[29px] py-[10px]">
                        Our Generative AI development services prioritize user-driven innovation to<br />
                        surpass project expectations and drive growth. We use extensive expertise<br />
                        across various AI technologies such as deep learning, machine learning,<br />
                        computer vision, reinforcement learning, and natural language processing<br />
                        to create tailored generative AI models and solutions for specific domains.
                    </p>
                    <p className="text-[18px] leading-[29px] py-[10px]">
                        Utilizing extensive knowledge across various AI technologies such as deep<br />
                        learning, machine learning, computer vision, reinforcement learning, and<br />
                        natural language processing, we specialize in creating customized<br />
                        generative AI models and solutions specific to your domain.
                    </p>
                    <button className="w-[170px] h-[40px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
                        <b>GET ESTIMATE</b></button>
                </div>
                <div>
                    <div className="w-[556px] h-[180px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='GenerativeAI' className="w-10 h-10" src={GenerativeAICustomDevelopment} />
                            <h4 className=" text-[20px] font-bold">Generative AI Custom Development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            By using models like GPT and DALL.E, our engineers design
                            custom language models and implement ML algorithms to
                            create outcome-centric solutions for your business.
                        </p>
                    </div>
                    <br />
                    <div className="w-[556px] h-[210px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='GenerativeAI' className="w-10 h-10" src={GenerativeAIConsulting} />
                            <h4 className=" text-[20px] font-bold">Generative AI Consulting</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            Our Generative AI Consultants help identify business opportunities,
                            strategize AI integration, custom model development, help set KPIs,
                            calculate ROI, and create a risk-proof development plan for Generative AI Projects.
                        </p>
                    </div>
                    <br />
                    <div className="w-[556px] h-[210px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='AIAuditing' className="w-10 h-10" src={AIAuditing} />
                            <h4 className=" text-[20px] font-bold">AI Auditing</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            We ensure ethical and compliant AI implementation and identify any
                            non-compliant factors, ethical issues, and location-based standards
                            and legal obligations to help your business follow best industry
                            practices and regulations.
                        </p>
                    </div>
                    <br />
                    <div className="w-[556px] h-[160px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='GenerativeAIModel' src={GenerativeAIModelIntegration} />
                            <h4 className=" text-[20px] font-bold">Generative AI Model Integration</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            We can integrate future-proof AI models like ChatGPT-4,
                            DALL.E, and Whisper AI into your digital business.
                        </p>
                    </div>
                </div>
            </div>


            <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
                <div className="w-full h-auto bg-[#fafafa] p-5">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 h-auto shadow-md bg-white rounded-lg p-6">
                                <p className="text-lg flex gap-2 items-center">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-base pt-4">
                                    "{review.reviewText}"
                                    <br /><br />
                                    {review.author}
                                    <br /><br />
                                    {review.verified}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Generative AI</span> Development Process</h1>
            <p className="text-[18px] text-center mt-4">
                Our skilled Generative AI developers work closely with your goals to create a user-friendly solution.
            </p>
            <div className="flex gap-1 items-center justify-center mt-8">
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
            </div>


            <h1 className="text-5xl text-center mt-15 font-bold mt-9">Our <span className="text-[#0652dd]"> Multi-industry</span> Experience</h1>
            <br />
            <p className="text-lg text-center">
                Our GenAI development services include development, customization, and integration solutions for several leading sectors like:
            </p>
            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>

            <div className="w-[1903px] h-[440px] bg-white pt-[40px] pl-[300px] mt-[80px]">
                <h1 className="text-[#0652dd] text-[40px] font-bold">Why Choose Us?</h1>
                <p className="text-[21px] text-[#292929]">With our custom Generative AI services, we help businesses start a journey of change, leading to remarkable growth<br />
                    and innovation.</p>
                <li className="text-[18px] pt-[10px]">We're really good at making top-notch AI solutions that fit exactly what you need.</li>
                <li className="text-[18px] pt-[10px]">Our AI experts team up with you to create AI solutions ready for the future, unlocking all the possibilities of AI.</li>
                <li className="text-[18px] pt-[10px]">We make AI models that fit exactly what you're doing, whether it's for your business or some special project.</li>
                <li className="text-[18px] pt-[10px]">We're flexible in how we work on AI projects to meet your needs.</li>
                <li className="text-[18px] pt-[10px]">Our team has made lots of different AI solutions for both small startups and big companies.</li>
                <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd]	border-2 w-[200px] mt-10 h-[40px]">TALK TO OUR EXPERT</button>
            </div>


            <div class="bg-blue-700 w-3/4 h-48 mx-auto text-white text-center pt-5 rounded-lg mt-10">
                <h3 class="text-center text-3xl font-bold">How much does it cost to develop a web application?</h3>
                <p class="text-lg pt-4">
                    For a simple Generative AI app with minimal features, it could cost between $40,000 to $100,000. But for a more advanced
                    app with lots of features<br /> and high-quality design, it might range from $100,000 to $400,000 or even more.</p>
                <div>
                    <button onClick={handleClick} class="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[20px]"><b>Contact Us</b></button>
                </div>
            </div>


            <h1 className="text-[40px] text-center font-bold mt-[70px]">Looking for the best Mobile App Development Services for your<br />
                business?</h1>
            <p className="text-[18px] text-center">We help top brands deliver quality products. Share your new business needs, and let us make it a success.</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                <div className="w-[344px] absolute top-[83px] right-[134px]">
                    <p className="text-lg">
                        Re-building Emerge - A finance app making it easy for financial experts to make money.
                    </p>
                    <div className="inline-row">
                        <div>
                            <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">4.4<p className="text-[#DE5B48] text-[10px] flex gap-1"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p></span>
                            <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                        </div>
                        <div>
                            <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                            <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                        </div>
                    </div>
                    <h1 className="my-3">1 Million+ downloads</h1>
                    <div className="flex gap-4">
                        <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                            <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" /><p><b>View Case Study</b></p>
                        </button>
                        <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
                    </div>
                </div>
                <div>
                    <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                    <p className="my-2">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                        in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                        scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                </div>
                <div className="flex items-center gap-4">
                    <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                    <div>
                        <p>Corey Smith</p>
                        <p>CEO, Emerge</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default GenerativeAi
