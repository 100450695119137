import React, { useState } from 'react';
import Header from '../components/layout/Header';
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import 'animate.css';
import Retail from '../assets/images/Retail.png';
import BusinessManagement from '../assets/images/BusinessManagement.png';
import CustomeCommerce from '../assets/images/CustomeCommerce.png';
import Marketplace from '../assets/images/Marketplace.png';
import csc from '../assets/images/csc.png';
import multistoreicon from '../assets/images/multistoreicon.png';
import OmnichannelIntegration from '../assets/images/OmnichannelIntegration.png';
import DigitalMarketingTools from '../assets/images/DigitalMarketingTools.png';
import OnlineOrderingDelivering from '../assets/images/OnlineOrderingDelivering.png';
import MobileWalletDevelopment from '../assets/images/MobileWalletDevelopment.png';
import OnlineRetailStore from '../assets/images/OnlineRetailStore.png';
import LoyaltyRewardsApps from '../assets/images/LoyaltyRewardsApps.png';
import AnalyticsandReporting from '../assets/images/AnalyticsandReporting.png';
import ShopifyDevelopment from '../assets/images/ShopifyDevelopment.svg';
import OnlineMarketplaces from '../assets/images/OnlineMarketplaces.png';
import WebPortals from '../assets/images/WebPortals.png';
import ProgressiveWebApps from '../assets/images/ProgressiveWebApps.png';
import PointSaleSystems from '../assets/images/PointSaleSystems.png';
import DedicatedAppDevelopers from '../assets/images/DedicatedAppDevelopers.png'
import FullySecuredSolution from '../assets/images/FullySecuredSolution.png';
import CosteffectiveSolutions from '../assets/images/CosteffectiveSolutions.png';
import FlexibleEngagementModels from '../assets/images/FlexibleEngagementModels.png';
const Ecommerce = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "Why choose BitFront for mobile app development services?",
            answer:
                "Several reasons make us the chosen partner of startups and established firms. Some of the top ones are --Team of subject matter experts-Multi-industry expertise-Specialization in emerging tech-92% client retention rate-93% on-time delivery",
            open: false
        },
        {
            question: "What is the cost of mobile app development?",
            answer: "The cost of mobile app development services is dependent on a series of factors - number and complexity of features, technology incorporation, number of platforms, and location of the development agency. All these factors together decide the cost of application development.",
            open: false
        },
        {
            question:
                "How to choose a mobile app development platform?",
            answer: "Choosing a mobile app development platform is a decision that varies from ease of adoption, integration support, community, scope of features that it can support, etc.",
            open: false
        },
        {
            question:
                "How much time does custom mobile app development take?",
            answer: "The exact time to create a mobile application depends on its features, the screen sizes, and the frameworks used. Generally, it takes 2 months to around 4–5 months depending on developers, functionalities of the app, platform, and much more.",
            open: false
        },
        {
            question:
                "What solutions are provided by your mobile app development services?",
            answer: "We offer end-to-end development solutions ranging from app consultancy, design, development, QA and testing, app deployment, and maintenance.",
            open: false
        },
        {
            question:
                "What is the difference between cross-platform app development and native mobile app development?",
            answer: "The difference between native and cross-platform application development is the way it is built. While native application development revolves around creating apps for a specific platform, cross-platform development allows developers to use one codebase and deploy the app on multiple platforms.",
            open: false
        },
        {
            question:
                "Will I get post-mobile app development support?",
            answer: "Yes. For the maintenance of the mobile app, you are not required to hire a full-time programmer as we have a flexible spot, which allows you to hire our mobile app developers to provide dedicated app support and maintenance service when required.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    return (
        <div>
            <Header />
            <div className="max-w-7xl h-[580px]	 bg-white rounded-2xl m-auto mt-[40px] ">
                <div className="grid grid-cols-2 items-center gap-[5rem] justify-center p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]">Retail & ECommerce</span> App Development Company</h1>
                        <p className="text-[18px]">
                            BitFront's dedicated developers excel in providing retail businesses with the
                            futuristic retail & eCommerce app development services. Our focus is on increasing
                            retail conversions and improving market reaches.
                        </p>
                        <li className="text-[18px] pt-[10px]">Agile Development</li>
                        <li className="text-[18px] pt-[10px]">100% NDA Protected</li>
                        <li className="text-[18px] pt-[10px]">Powerful Integrations</li>
                        <li className="text-[18px] pt-[10px]">100% Scalable solutions</li>
                        <li className="text-[18px] pt-[10px]">User-centric Design</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[250px] mt-10 h-[42px]">TALK TO OUR DEVELOPERS</button>
                    </div>
                    <img alt='Retail' className="w-[526px] h-[486px]" src={Retail} />
                </div>
            </div>
            <div className="h-[148.8px] ml-[300px] mt-[50px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>
            <div className="flex justify-center gap-[5rem]">
                <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#b34743] border-[2px]">
                    <div className="flex items-center gap-1 mb-[15px]">
                        <img alt='BusinessManagement' className="w-[30px] h-[30px]" src={BusinessManagement} />
                        <h4 className=" text-[20px] font-bold ml-[20px]">Retail Business Management Software Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        Develop a comprehensive ERP system for your enterprise-grade retail business to manage daily operations effortlessly. Handle everything
                        from stock management and sales inquiries to payment transactions and customer support.
                    </p>
                </div>
                <br />
                <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#4366b3] border-[2px]">
                    <div className="flex items-center gap-1 mb-[15px]">
                        <img alt='CustomeCommerce' className="w-[30px] h-[30px]" src={CustomeCommerce} />
                        <h4 className=" text-[20px] font-bold ml-[20px]">Custom eCommerce App Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        Elevate your shopping journey with a captivating mobile app. BitFront provides comprehensive eCommerce app development solutions tailored
                        to various stores and platforms. Let's collaborate to craft richly featured mobile apps for your enterprise.
                    </p>
                </div>
            </div>
            <div className="flex justify-center gap-[5rem]">
                <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#b38c43] border-[2px]">
                    <div className="flex items-center gap-1 mb-[15px]">
                        <img alt='Marketplace' className="w-[30px] h-[30px]" src={Marketplace} />
                        <h4 className=" text-[20px] font-bold ml-[20px]">Marketplace & E-Commerce Solutions</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We offer creative marketplace and e-commerce solutions to boost your customer retention. With our mobile and software development services, we help you
                        reach your target market and stay visible. Our platform connects customers, suppliers, and manufacturers.
                    </p>
                </div>
                <br />
                <div className=" mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#ac43b3] border-[2px]">
                    <div className="flex items-center gap-1 mb-[15px]">
                        <img alt='csc' className="w-[30px] h-[30px]" src={csc} />
                        <h4 className=" text-[20px] font-bold ml-[20px]">C2C eCommerce App Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We create an online platform where you can buy and sell items. Our app has features like bargaining, multilingual
                        support, and online payments. It also lets customers sell their own stuff to other customers.
                    </p>
                </div>
            </div>
            <h1 className=" mt-[40px] text-[35px] font-semibold text-center"><span className="text-[#0652dd]">Retail & e-Commerce</span> Solutions</h1>
            <p className="text-[18px] m-auto font-medium text-center w-[1350px]">
                In addition to providing high-quality retail & eCommerce development services, we assist businesses of all sizes in enhancing customer
                satisfaction and making a mark in the competitive market. Explore our retail & eCommerce app development solutions.
            </p>
            <div className=" flex justify-center gap-[1rem]">
                <div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='multistoreicon' className="w-[40px] h-[50px]" src={multistoreicon} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Online Multistore</h1>
                                <p className="text-[18px] font-medium">
                                    Expand your online shop with our multi-store solution. Effortlessly manage multiple languages and product lists.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='OmnichannelIntegration' className="w-[40px] h-[40px]" src={OmnichannelIntegration} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Omni-channel Integration</h1>
                                <p className="text-[18px] font-medium">
                                    Omni-channel integration links online stores, physical retail sites, and mobile apps.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='DigitalMarketingTools' className="w-[40px] h-[40px]" src={DigitalMarketingTools} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Digital Marketing Tools</h1>
                                <p className="text-[18px] font-medium">
                                    We promote products and services online through email marketing, social media marketing, SEO, and PPC advertising.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='Online' className="w-[40px] h-[40px]" src={OnlineOrderingDelivering} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Online Ordering/Delivering</h1>
                                <p className="text-[18px] font-medium">
                                    Manage inventory, shipping, multi-currency payments, and order tracking seamlessly across various delivery channels.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='MobileWalletDevelopment' className="w-[40px] h-[40px]" src={MobileWalletDevelopment} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Mobile Wallet Development</h1>
                                <p className="text-[18px] font-medium">
                                    Our e-commerce app developers integrate secure wallets to track account balances, transactions, expenses, and portfolios.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='OnlineRetailStore' className="w-[40px] h-[40px]" src={OnlineRetailStore} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Online Retail Store</h1>
                                <p className="text-[18px] font-medium">
                                    Reach your target audience with an online retail store & provide a seamless shopping experience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='LoyaltyRewardsApps' className="w-[40px] h-[40px]" src={LoyaltyRewardsApps} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Loyalty & Rewards Apps</h1>
                                <p className="text-[18px] font-medium">
                                    We prioritize client success, crafting custom loyalty & reward apps for top brands.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='AnalyticsandReporting' className="w-[40px] h-[40px]" src={AnalyticsandReporting} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Analytics and Reporting</h1>
                                <p className="text-[18px] font-medium">
                                    Software offering insights into sales, customer behavior, and key metrics, guiding data-driven decisions for retailers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='ShopifyDevelopment' className="w-[40px] h-[40px]" src={ShopifyDevelopment} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Shopify Development</h1>
                                <p className="text-[18px] font-medium">
                                    Utilize Shopify for building digital stores with immersive templates, endless customization, and high performance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='OnlineMarketplaces' className="w-[40px] h-[40px]" src={OnlineMarketplaces} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Online Marketplaces</h1>
                                <p className="text-[18px] font-medium">
                                    We can create online marketplaces for selling products and services, with options for different ways sellers and buyers interact.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='WebPortals' className="w-[40px] h-[40px]" src={WebPortals} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Web Portals</h1>
                                <p className="text-[18px] font-medium">
                                    Our web portals for retail businesses assist in vendor management, customer communication, brand strengthening, and showcasing product lists.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='ProgressiveWebApps' className="w-[40px] h-[40px]" src={ProgressiveWebApps} />
                            <div>
                                <h1 className="text-[20px] font-semibold">PWA (Progressive Web Apps)</h1>
                                <p className="text-[18px] font-medium">
                                    We recommend Progressive Web Apps (PWAs) for a single app serving both web and mobile users, optimized for different screen sizes.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-5 w-[440px] h-[240px] border-[2px] rounded-[10px]">
                        <div className=" p-[30px] flex  gap-[1rem]">
                            <img alt='PointSaleSystems' className="w-[40px] h-[40px]" src={PointSaleSystems} />
                            <div>
                                <h1 className="text-[20px] font-semibold">Point of Sale (POS) Systems</h1>
                                <p className="text-[18px] font-medium">
                                    POS systems handle transactions in physical stores, often with inventory management, CRM, and reporting.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex justify-center items-center gap-[5rem] mt-[30px]">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                </div>
                <div>

                    <div className="flex gap-[5rem]">
                        <div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='DedicatedAppDevelopers' className="w-[30px] h-[30px]" src={DedicatedAppDevelopers} />
                                <h1 className="text-[18px] font-medium">Dedicated App Developers</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='FullySecuredSolution' className="w-[30px] h-[30px]" src={FullySecuredSolution} />
                                <h1 className="text-[18px] font-medium">Fully Secured Solution</h1>
                            </div>
                        </div>


                        <div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='CosteffectiveSolutions' className="w-[30px] h-[30px]" src={CosteffectiveSolutions} />
                                <h1 className="text-[18px] font-medium">Cost-effective Solutions</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='FlexibleEngagementModels' className="w-[30px] h-[30px]" src={FlexibleEngagementModels} />
                                <h1 className="text-[18px] font-medium">Flexible Engagement Models</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" mt-[40px] m-auto p-[40px] w-[1320px] h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[25px] font-semibold text-[#fff]">NEED SOFTWARE DEVELOPMENT FOR FINANCIAL SERVICES?</h1>
                <p className="text-center text-[19px] font-medium text-[#fff]">BitFront will help in creating a secure and efficient solution, optimizing business logic for new revenue streams.</p>
            </div>

            <h1 className="text-[40px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Ecommerce
