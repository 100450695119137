import React from 'react';
import Header from '../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "./Footer";
import uiuxdesign from '../assets/images/uiuxdesign.png';
import MobileAppDesign from '../assets/images/MobileAppDesign.png';
import WebAppDesign from '../assets/images/WebAppDesign.png';
import WebsiteUIUXDesign from '../assets/images/WebsiteUIUXDesign.png';
import Group from '../assets/images/Group.png';
import bluesc from '../assets/images/bluesc.png';
import bluebox from '../assets/images/bluebox.png';
import bluefq from '../assets/images/bluefq.png';
import hpdc from '../assets/images/hpdc.png';
import figmas from '../assets/images/figmas.png';
import photoshop from '../assets/images/photoshop.png';
import mockplus from '../assets/images/mockplus.png';
import sketch from '../assets/images/sketch.png';
import invision from '../assets/images/invision.png';
import affinitydesigner from '../assets/images/affinitydesigner.png';
import marvelapps from '../assets/images/marvelapps.png';
import picmonkeys from '../assets/images/picmonkeys.png';
const UIUXdesign = () => {
    return (
        <div>
            <Header />
            <div className="max-w-[1150px] h-[450px] bg-white rounded-2xl m-auto mt-[80px] ">
                <div className="flex justify-center gap-[4rem] p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]">UI/UX</span> Design Company</h1>
                        <p className="text-[18px]">
                            We specialize in comprehensive UI/UX design services, crafting compelling and immersive user experiences that leave a lasting impact across every interaction with your brand.
                        </p>
                        <li className="text-[18px] pt-[10px]">Technically feasible design</li>
                        <li className="text-[18px] pt-[10px]">Design focused on Android and iOS guidelines</li>
                        <li className="text-[18px] pt-[10px]">Analytics dashboard backend design</li>
                        <li className="text-[18px] pt-[10px]">Clickable prototype with UI kit</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd]	border-2 w-[200px] mt-10 h-[40px]">TALK TO OUR EXPERT</button>
                    </div>
                    <img alt='uiuxdesign' className="w-[506px] h-[363px]" src={uiuxdesign} />
                </div>
            </div>

            <div className="h-[148.8px] ml-[390px] mt-[80px] overflow-hidden w-[1150px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>


            <div className="flex justify-center">
                <div className="w-[575px] h-[212px] bg-white">
                    <h1 className="text-[18px] font-bold p-[50px] border-[1px]">
                        We specialize in comprehensive UI/UX design services, crafting
                        compelling and immersive user experiences that leave a lasting
                        impact across every interaction with your brand.
                    </h1>
                </div>
                <div className="w-[575px] h-[413px] text-white p-[50px] bg-[#2e6de0]">
                    <li className="text-[18px] pt-[10px]">Technically feasible design</li>
                    <li className="text-[18px] pt-[10px]">Design focused on Android and iOS guidelines</li>
                    <li className="text-[18px] pt-[10px]">Analytics dashboard backend design</li>
                    <li className="text-[18px] pt-[10px]">Mind map</li>
                    <li className="text-[18px] pt-[10px]">Wireframes</li>
                    <li className="text-[18px] pt-[10px]">Motion Design</li>
                    <li className="text-[18px] pt-[10px]">Figma design</li>
                    <li className="text-[18px] pt-[10px]">Clickable prototype with UI kit</li>
                </div>
            </div>

            <h1 className="text-[#2E6DE0] font-bold text-[32px] text-center mt-9">UI/UX Design Services<br />
                <span className="text-black">Inspiring User Action</span></h1>
            <div className="flex justify-center mt-12">
                <div className=" border-[1px] p-[50px]  w-[551px] h-[293px] bg-white left_text position: relative;border-[3px]">
                    <h3 className=" text-[25px] font-bold">Mobile App Design</h3>
                    <p className="text-[19px]">At BitFront, we hold expertise in creating user interface
                        designs for mobile apps. No matter what your brand touchpoint
                        is, we ensure that the experience users will get is similar
                        all across.</p><div class="first_img">
                        <div className=" mt-10 flex justify-center gap-10 mr-[-300px]">
                            <img alt='bluesc' className="w-[90px] h-[90px]" src={bluesc} width="18%" />
                            <div class=" p-[40px] w-[340px] h-[266px] bg-[#f7db7c]">
                                <li className="text-[18px] pt-[10px] font-bold">Market Research</li>
                                <li className="text-[18px] pt-[10px] font-bold">Wireframe Creation</li>
                                <li className="text-[18px] pt-[10px] font-bold">Sketching and Wireframing</li>
                                <li className="text-[18px] pt-[10px] font-bold">Quality Assurance (QA)</li>
                                <li className="text-[18px] pt-[10px] font-bold">Prototyping</li></div></div></div>
                </div>
                <img alt='MobileAppDesign' className="w-[551px] h-[584px]" src={MobileAppDesign} />
            </div>

            <div className="flex justify-center mt-12 gap-4">
                <img alt='WebAppDesign' className="w-[539px] h-[366px]" src={WebAppDesign} />
                <div className=" p-[50px]  border-[1px] w-[551px] h-[293px] bg-white left_text position: relative;border-[3px]">
                    <h3 className="  text-[25px] font-bold">Web App Design</h3>
                    <p className="text-[19px]">The team of BitFront excels in crafting user
                        interface designs for diverse platforms, including web applications.
                        Regardless of your brand touchpoint, we guarantee a consistent user
                        experience across all platforms.
                        all across.</p><div class="first_img">
                        <div className=" mt-10 flex justify-center gap-10 mr-[-300px]">
                            <img alt='bluebox' className=" mt-[80px] w-[90px] h-[90px]" src={bluebox} />
                            <div class=" mt-[-50px] p-[40px] w-[340px] h-[226px] bg-[#ef8168]">
                                <li className="text-[18px] pt-[10px] font-bold">Market Research</li>
                                <li className="text-[18px] pt-[10px] font-bold">Wireframe Creation</li>
                                <li className="text-[18px] pt-[10px] font-bold">Information Architecture</li>
                                <li className="text-[18px] pt-[10px] font-bold">Prototype Creation</li></div></div></div>
                </div>
            </div>

            <div className="flex justify-center mt-[200px] gap-9">
                <div className=" p-[50px]  border-[1px] w-[551px] h-[293px] bg-white left_text position: relative;border-[3px]">
                    <h3 className="  text-[25px] font-bold">Website UI/UX Design</h3>
                    <p className="text-[19px]">We carry a skillset in building organic UI/UX
                        that moves users from one screen to another almost intuitively.
                        From smooth transitions to clarity of the next steps, our UX
                        design services make your users feel at home inside your website.</p><div class="first_img">
                        <div className=" mt-10 flex justify-center gap-10 mr-[-300px]">
                            <img alt='bluefq' className=" mt-[136px] w-[90px] h-[90px]" src={bluefq} />
                            <div class=" p-[40px] w-[340px] h-[226px] bg-[#a5cca6]">
                                <li className="text-[18px] pt-[10px] font-bold">Market Research</li>
                                <li className="text-[18px] pt-[10px] font-bold">Interaction Design</li>
                                <li className="text-[18px] pt-[10px] font-bold">Information Architecture</li>
                                <li className="text-[18px] pt-[10px] font-bold">Prototyping</li></div></div></div>
                </div>
                <img alt='WebsiteUIUXDesign' className="w-[539px] h-[366px]" src={WebsiteUIUXDesign} />
            </div>
            <div className="flex justify-center gap-8 mt-[200px]">
                <img alt='Group' className="w-[539px] h-[396px]" src={Group} />
                <div className=" p-[50px]  border-[1px] w-[551px] h-[293px] bg-white left_text position: relative;border-[3px]">
                    <h3 className="  text-[25px] font-bold">Seamless Digital Product Design for Your Brand</h3>
                    <p className="text-[19px]">At BitFront, we excel in crafting user interface
                        designs for diverse platforms, including mobile, web, and wearables.
                        Make the product stand out through custom style, fonts, icons, and
                        animations.</p><div class="first_img">
                        <div className=" mt-[70px] flex justify-center gap-10 ">
                            <div class=" p-[20px] w-[340px] h-[206px] bg-[#c8ac90]">
                                <li className="text-[18px] pt-[10px] font-bold">Frontend Development</li>
                                <li className="text-[18px] pt-[10px] font-bold">Information Architecture</li>
                                <li className="text-[18px] pt-[10px] font-bold">Prototype Creation</li>
                                <li className="text-[18px] pt-[10px] font-bold">Multiple Engagement Models</li>
                            </div>
                            <img alt='hpdc' className=" mt-[1px] w-[90px] h-[90px]" src={hpdc} />
                        </div>
                    </div>
                </div>
            </div>


            <div className="flex items-center justify-center mt-[190px]">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-4xl w-full">
                    <h1 className="text-3xl font-bold text-center mb-4">Effective <span className="text-blue-600">UI/UX Design</span> Methodology</h1>
                    <p className="text-center mb-6">
                        At BitFront, we excel in crafting user interface designs for diverse platforms, including mobile, web, and wearables. Regardless of your brand touchpoint, we guarantee a consistent user experience across all platforms.
                    </p>
                    <div className="relative flex flex-col items-center">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <div className="w-full border-dashed border-t-2 border-gray-300"></div>
                        </div>
                        <div className="flex justify-between w-full mb-4">
                            <div className="flex flex-col items-center">
                                <div className="bg-white border-2 border-gray-300 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold">
                                    01
                                </div>
                                <div className="mt-2 text-center">
                                    <div>Reviewing Design <br />or Defining Scope</div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="bg-white border-2 border-gray-300 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold">
                                    02
                                </div>
                                <div className="mt-2 text-center">
                                    <div>Mind Mapping</div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="bg-white border-2 border-gray-300 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold">
                                    03
                                </div>
                                <div className="mt-2 text-center">
                                    <div>Wireframing</div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="bg-white border-2 border-gray-300 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold">
                                    04
                                </div>
                                <div className="mt-2 text-center">
                                    <div>Prototyping</div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="bg-white border-2 border-gray-300 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold">
                                    05
                                </div>
                                <div className="mt-2 text-center">
                                    <div>UI Design</div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="bg-white border-2 border-gray-300 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold">
                                    06
                                </div>
                                <div className="mt-2 text-center">
                                    <div>UX Design</div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="bg-white border-2 border-gray-300 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold">
                                    07
                                </div>
                                <div className="mt-2 text-center">
                                    <div>Design System</div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 text-center">
                            <h2 className="text-xl font-bold text-blue-600">Reviewing Design or Defining Scope</h2>
                            <p className="mt-2">We examine your existing app design or vision to develop a project scope that outlines the requirements and deliverables.</p>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className="text-[38px] font-bold text-center mt-10">The <span className="text-[#0652dd]">Technological Platforms</span> We<br />
                Depend On</h1>
            <p className="text-center text-[18px]">
                We create your digital products on platforms designed with a focus<br />
                on creativity and intuitiveness in their architecture.
            </p>
            <div className="flex justify-center gap-[40px] mt-10">
                <div className="text-center">
                    <img alt='figmas' src={figmas} />
                    <p>Figma</p>
                </div>
                <div className="text-center">
                    <img alt='photoshop' src={photoshop} />
                    <p>Photoshop</p>
                </div>
                <div className="text-center">
                    <img alt='mockplus' src={mockplus} />
                    <p>Mockplus</p>
                </div>
                <div className="text-center">
                    <img alt='sketch' src={sketch} />
                    <p>Sketch</p>
                </div>
                <div className="text-center">
                    <img alt='invision' src={invision} />
                    <p>Invision</p>
                </div>
                <div className="text-center">
                    <img alt='affinitydesigner' src={affinitydesigner} />
                    <p>Affinity Designer</p>
                </div>
                <div className="text-center">
                    <img alt='marvelapps' src={marvelapps} />
                    <p>Marvel Apps</p>
                </div>
                <div className="text-center">
                    <img alt='picmonkeys' src={picmonkeys} />
                    <p>PicMonkey</p>
                </div>
            </div>


            <h1 className="text-[40px] text-center font-bold mt-[70px]">Looking for the best Mobile App Development Services for your<br />
                business?</h1>
            <p className="text-[18px] text-center">We help top brands deliver quality products. Share your new business needs, and let us make it a success.</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="emergeSite.png" src="/images2/emergeSite.png"></img>
                <div className="w-[344px] absolute top-[83px] right-[134px]">
                    <p className="text-lg">
                        Re-building Emerge - A finance app making it easy for financial experts to make money.
                    </p>
                    <div className="inline-row">
                        <div>
                            <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">4.4<p className="text-[#DE5B48] text-[10px] flex gap-1"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p></span>
                            <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                        </div>
                        <div>
                            <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                            <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                        </div>
                    </div>
                    <h1 className="my-3">1 Million+ downloads</h1>
                    <div className="flex gap-4">
                        <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                            <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" /><p><b>View Case Study</b></p>
                        </button>
                        <img className="w-[124px] h-[36px] cursor-pointer" alt="41p.jpg" src="/images2/41p.jpg" />
                    </div>
                </div>
                <div>
                    <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                    <p className="my-2">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                        in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                        scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                </div>
                <div className="flex items-center gap-4">
                    <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                    <div>
                        <p>Corey Smith</p>
                        <p>CEO, Emerge</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default UIUXdesign
