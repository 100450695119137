import React from 'react'
const Footerq = () => {

  const services = [
    { name: 'Custom Software Development', link: '#' },
    { name: 'Web Application Development', link: '#' },
    { name: 'Mobile Application Development', link: '#' },
    { name: 'QA & Testing', link: '#' },
    { name: 'Support & Maintenance', link: '#' },
    { name: 'Staff Augmentation', link: '#' },
    { name: 'Cloud', link: '#' },
    { name: 'DevOps', link: '#' },
    { name: 'Artificial Intelligence & Machine Learning', link: '#' }
  ];

  const technologies = [
    { name: 'ReactJS', link: '#' },
    { name: 'AngularJS', link: '#' },
    { name: 'NodeJS', link: '#' },
    { name: 'PHP', link: '#' },
    { name: '.NET', link: '#' },
    { name: 'ROR', link: '#' },
    { name: 'JAVA', link: '#' },
    { name: 'WordPress', link: '#' },
    { name: 'CodeIgniter', link: '#' },
    { name: 'Laravel', link: '#' },
    { name: 'Android', link: '#' },
    { name: 'iOS', link: '#' },
    { name: 'React Native', link: '#' },
    { name: 'Flutter', link: '#' }
  ];

  const socialMediaLinks = [
    { href: 'https://www.facebook.com', src: 'https://static.vecteezy.com/system/resources/previews/021/495/985/non_2x/facebook-social-media-logo-icon-free-png.png', alt: 'Facebook' },
    { href: 'https://www.instagram.com', src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png', alt: 'Instagram' },
    { href: 'https://www.twitter.com', src: 'https://cdn-icons-png.flaticon.com/512/124/124021.png', alt: 'Twitter' },
    { href: 'https://www.linkedin.com', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRokEYt0yyh6uNDKL8uksVLlhZ35laKNQgZ9g&s', alt: 'LinkedIn' },
    { href: 'https://www.youtube.com', src: 'https://cdn3.iconfinder.com/data/icons/social-network-30/512/social-06-512.png', alt: 'YouTube' },
    { href: 'https://www.whatsapp.com', src: 'https://cdn.icon-icons.com/icons2/2429/PNG/512/whatsapp_logo_icon_147205.png', alt: 'WhatsApp' },
    { href: 'https://in.pinterest.com', src: 'https://cdn-icons-png.flaticon.com/512/3536/3536559.png', alt: 'Pinterest' },
  ];

  return (
    <div>
      <div className="container mx-auto py-[50px] ">
        <div className='grid grid-cols-4'>
          <div className='px-3'>
            <img alt="img" className="h-[50px] w-[150px]" src="/images2/weblogowhite.svg" />
            <h3 className="font-medium text-lg leading-[30px] tracking-[-0.01em] text-[#ffffff] mt-14 mr-5 mb-5">Want to talk about your project ?</h3>
            <button className="transition-all duration-200 hover:bg-white hover:text-[#0652dd] w-[160px] h-[42px] bg-[#0652dd] border-[1px] border-[#0652dd] text-white rounded-[5px]">Schedule A Call</button>
          </div>
          <div className='px-3'>
            <h3 className="font-medium text-[18px] leading-[22px] tracking-[-.02em] text-[#ffffff] mb-[30px]">Services</h3>
            <div className='grid'>
              {services.map(service => (
                <a
                  key={service.name}
                  href={service.link}
                  className='font-normal text-[13px] leading-[32px] tracking-[-0.01em] text-white cursor-pointer hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out w-max'
                >
                  {service.name}
                </a>
              ))}
            </div>
          </div>
          <div className='px-3'>
            <h3 className="font-medium text-[18px] leading-[22px] tracking-[-.02em] text-[#ffffff] mb-[30px]">Technologies</h3>
            <div className="grid grid-cols-2">
              <div className='grid'>
                {technologies.slice(0, 7).map(tech => (
                  <a
                    key={tech.name}
                    href={tech.link}
                    className='font-normal text-[13px] leading-[32px] tracking-[-0.01em] text-[#ffffffcc] cursor-pointer hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out w-max'
                  >
                    {tech.name}
                  </a>
                ))}
              </div>
              <div className='grid'>
                {technologies.slice(7).map(tech => (
                  <a
                    key={tech.name}
                    href={tech.link}
                    className='font-normal text-[13px] leading-[32px] tracking-[-0.01em] text-[#ffffffcc] cursor-pointer hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out w-max'
                  >
                    {tech.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className='px-3'>
            <h3 className="font-medium text-[18px] leading-[22px] tracking-[-.02em] text-[#ffffff] mb-[30px]">Connect with us</h3>
            <div className="flex gap-[1rem] items-start mb-[30px]">
              <img alt='nao' className="h-auto w-[35px]" src="https://www.narolainfotech.com/wp-content/uploads/2022/09/Flag_of_India.svg" />
              <div>
                <p className="font-normal text-[13px] leading-[23px] tracking-[-0.01em] text-[#ffffff] mb-2.5">301,Pragati IT Park,
                  Mota Varachha,
                  Surat,Gujarat,394101-INDIA</p>
                <h1 className="font-semibold text-[13px] leading-[23px] tracking-[-0.01em] text-[#ffffff] cursor-pointer hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out w-max">info@bitfrontinfotech.com</h1>
              </div>
            </div>
            <div className="flex items-center gap-2.5">
              {socialMediaLinks.map((link, index) => (
                <a key={index} href={link.href}>
                  <img alt={link.alt} className="w-[40px] rounded-[12px] h-[32px]" src={link.src} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-[#E7EAF2]"></div>
      <div className="container mx-auto py-10">
        <div className='flex justify-between items-center'>
          <div>
            <h1 className="text-[24px]  font-bold leading-[29px] tracking-[0.02em] uppercase text-[#ffffff] mb-[5px]">WE’RE HIRING !</h1>
            <h4 className="text-[20px] text-[#ffffff] font-normal leading-[35px]">Learn more about career opportunities with BitFront Infotech</h4>
          </div>
          <h2 className="flex items-center text-[#0084FF] gap-[5px] font-semibold text-xl underline">Explore Careers
            <svg width="55" height="14" viewBox="0 0 55 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 8V6H50.7692L46.5385 2L47.5962 0L55 7L47.5962 14L46.5385 12L50.7692 8H0Z" fill="#0084FF"></path>
            </svg>
          </h2>
        </div>
      </div>
      <div className="border-b border-[#E7EAF2]"></div>
      <div className="container mx-auto py-[30px]">
        <p className='text-center font-normal text-xs leading-[25px] tracking-[-0.01em] text-[#ffffff]'>© 2024 All Rights Reserved - bitfrontinfotech.com</p>
      </div>

    </div>
  )
}

export default Footerq
