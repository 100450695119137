import React, { useState } from 'react';
import Header from '../components/layout/Header';
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import 'animate.css';
import OnDemandDevelopment from '../assets/images/OnDemandDevelopment.png';
import OnDemandTaxiAppDevelopment from '../assets/images/OnDemandTaxiAppDevelopment.png';
import OnDemandHomeServicesAppDevelopment from '../assets/images/OnDemandHomeServicesAppDevelopment.png';
import OnDemandDeliveryAppDevelopment from '../assets/images/OnDemandDeliveryAppDevelopment.png';
import OnDemandLaundryAppDevelopment from '../assets/images/OnDemandLaundryAppDevelopment.png';
import FoodDeliveryApp from '../assets/images/FoodDeliveryApp.png';
import BeautyandWellnessApps from '../assets/images/BeautyandWellnessApps.png';
import PetCareApp from '../assets/images/PetCareApp.png';
import HomeServicesApp from '../assets/images/HomeServicesApp.png';
import GroceryDeliveryApp from '../assets/images/GroceryDeliveryApp.png';
import Onboarding from '../assets/images/Onboarding.png';
import BookingMatching from '../assets/images/BookingMatching.png';
import Pushnotifications from '../assets/images/Pushnotifications.png';
import RealtimeTrackingApps from '../assets/images/RealtimeTrackingApps.png';
import ServiceSelection from '../assets/images/ServiceSelection.png';
import MultiplePaymentOptions from '../assets/images/MultiplePaymentOptions.png';
import OrderRequestsandDetails from '../assets/images/OrderRequestsandDetails.png';
import AdvancedRouteOptimizations from '../assets/images/AdvancedRouteOptimizations.png';
import ProofofDelivery from '../assets/images/ProofofDelivery.png';
import PaymentReports from '../assets/images/PaymentReports.png';
import ServiceAvailability from '../assets/images/ServiceAvailability.png';
import OrdersBookingManagement from '../assets/images/OrdersBookingManagement.png';
import ServiceTracking from '../assets/images/ServiceTracking.png';
import OverseeReviewsandFeedback from '../assets/images/OverseeReviewsandFeedback.png';
import ServiceProvidersManagement from '../assets/images/ServiceProvidersManagement.png';
import AnalyticsReports from '../assets/images/AnalyticsReports.png';
import PricingandCommissionManagement from '../assets/images/PricingandCommissionManagement.png';
import AgileDevelopmentMethodology from '../assets/images/AgileDevelopmentMethodology.png';
import EndtoendSupport from '../assets/images/EndtoendSupport.png';
import Usercentricsolutionss from '../assets/images/Usercentricsolutionss.png';
import AccesstoToptierTalent from '../assets/images/AccesstoToptierTalent.png';
import ScalabilityAssurance from '../assets/images/ScalabilityAssurance.png';
import DesignedArchitecture from '../assets/images/DesignedArchitecture.png';
const OnDemandAppDevelopment = () => {
    const [faqs, setFaqs] = useState([
        {
            question: "What is on-demand app development?",
            answer:
                "An on-demand app is a web or mobile solution that allows users to immediately receive or schedule services, or instantly buy products from a specific company. It may also offer comparisons among various on-demand providers, aiding users in selecting the most suitable option.",
            open: false
        },
        {
            question: "How much does on-demand app development cost?",
            answer: "The cost of creating an on-demand app can vary from $40,000 to $200,000, or more. Numerous factors influence the total cost, including the industry, complexity, project timeline, location of the development company, UI/UX design, and ongoing maintenance. Reach out to our on-demand developers for precise cost estimates as per your specific business needs.",
            open: false
        },
        {
            question:
                "How much time does it take to build an on-demand app?",
            answer: "The time frame to build an on-demand app varies as per the overall complexity of the app. For instance, developing a highly complex app with an extensive feature list will take around 12 to 14 months. On the other hand, a simple app with minimal features will take around 5 to 6 months.",
            open: false
        },
        {
            question:
                "Why should a business have an on-demand service app?",
            answer: "There are numerous benefits for businesses to have an on-demand services app, such as endless business opportunities, gaining a competitive advantage, suitability for all types of businesses, delivering value to consumers, and attracting investors.",
            open: false
        },
        {
            question:
                "Do you create MVP for on-demand apps?",
            answer: "Yes, of course. We create MVP for on-demand app projects as per client requirements.",
            open: false
        },
        {
            question:
                "What industries can benefit from on demand app development?",
            answer: "Almost any industry that links consumers with services or products can benefit from on-demand apps. This includes transportation, food and grocery delivery, healthcare, home services, logistics, beauty, and wellness.",
            open: false
        },
        {
            question:
                "Will I get post-delivery support?",
            answer: "Yes. You don't need to hire a full-time engineer for maintaining your on-demand app solution. We offer a flexible bucket model where you can hire our mobile app developers for dedicated support and maintenance services whenever you need them.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    return (
        <div>
            <Header />
            <div className="max-w-7xl h-auto bg-white rounded-2xl m-auto mt-[40px] ">
                <div className="grid grid-cols-2 items-center gap-[5rem] justify-center p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            <span className="text-[#0652dd]">On-Demand</span> App Development Company</h1>
                        <p className="text-[18px]">
                            We create strong on-demand mobility solutions that make your service super fast. Whether it's transportation,
                            healthcare, or retail, we bring your business right to customers' doors, easily.
                        </p>
                        <li className="text-[18px] pt-[10px]">Social Media Integration</li>
                        <li className="text-[18px] pt-[10px]">In-app payments</li>
                        <li className="text-[18px] pt-[10px]">Instant alerts</li>
                        <li className="text-[18px] pt-[10px]">Geo IP location</li>
                        <li className="text-[18px] pt-[10px]">Analytics</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[250px] mt-10 h-[42px]">TALK TO OUR DEVELOPERS</button>
                    </div>
                    <img alt='OnDemandDevelopment' className="w-[483px] h-[380px]" src={OnDemandDevelopment} />
                </div>
            </div>
            <div className="h-[148.8px] ml-[300px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>

            <div className="flex justify-center items-center gap-[5rem]">
                <div className=" hover:shadow-custom mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-tr-[50px] rounded-bl-[30px] p-[20px] border-[#efa54f] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='On Demand taxi' className="w-9 h-9" src={OnDemandTaxiAppDevelopment} />
                        <h4 className=" text-[20px] font-bold">On-Demand Taxi App Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        Our team can create a taxi booking app for you that's easy to use and interactive. It will have features
                        like Ola and Uber, such as automatic booking, tracking your ride in real-time, reliable customer support,
                        and the ability to cancel rides smoothly.
                    </p>
                </div>
                <div className=" hover:shadow-custom mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[50px] rounded-tr-[30px] rounded-br-[30px] p-[20px] border-[#333a98] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='OnDemand Home Services' className="w-9 h-9" src={OnDemandHomeServicesAppDevelopment} />
                        <h4 className=" text-[20px] font-bold">On-Demand Home Services App Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We're experts in making on-demand home services apps that you can use whenever you need help with things like plumbing
                        or cleaning. Our focus is on making it easy for you to book services quickly, get help when you need it, and have a
                        smooth experience using the app.
                    </p>
                </div>
            </div>
            <div className="flex justify-center items-center gap-[5rem]">
                <div className=" hover:shadow-custom mt-[40px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-bl-[30px] rounded-br-[50px] p-[20px] border-[#339836] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='OnDemand Delivery ' className="w-9 h-9" src={OnDemandDeliveryAppDevelopment} />
                        <h4 className=" text-[20px] font-bold">On-Demand Delivery App Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We're a company that specializes in creating apps for on-demand delivery. Our apps can handle different types
                        of deliveries like medicine, flowers, fuel, food, and groceries. With our help, your grocery deliveries can
                        be quick and smooth. We make ordering easy, keep track of deliveries in real time, and ensure reliable service.
                    </p>
                </div>
                <div className=" hover:shadow-custom mt-[40px] w-[556px] h-[250px] bg-white rounded-tr-[30px] rounded-bl-[50px] rounded-br-[30px] p-[20px] border-[#885d24] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='OnDemand Laundry' className="w-9 h-9" src={OnDemandLaundryAppDevelopment} />
                        <h4 className=" text-[20px] font-bold">On-Demand Laundry App Development</h4>
                    </div>
                    <p className="text-[17px] pl-2">
                        We're experts at creating an easy laundry experience for your customers with our on-demand laundry app development
                        services. Our custom apps are designed to save time and make scheduling pick-ups and deliveries simple with a
                        user-friendly interface.
                    </p>
                </div>
            </div>


            <h1 className=" text-[35px] font-semibold text-center mt-10">Innovative <span className="text-[#0652dd]">On-Demand</span> App Solutions</h1>
            <p className="text-[18px] w-[940px] m-auto font-medium text-center">At BitFront, we hold the market understanding and technical expertise to convert every service-based
                business into an on-demand business model.</p>

            <div className="flex justify-center items-center gap-[5rem] mt-[40px]">
                <img alt='sapphiresolutions' className="w-[499px] h-[486px]" src="https://www.sapphiresolutions.net/images/on_demand_app/banner.svg" />
                <div>

                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#afe5a6] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='FoodDeliveryApp' className="w-9 h-9" src={FoodDeliveryApp} />
                            <h1 className="text-[20px] font-medium">Food Delivery App</h1>
                        </div>
                    </div>

                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#a6e5e3] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='BeautyandWellnessApps' className="w-9 h-9" src={BeautyandWellnessApps} />
                            <h1 className="text-[20px] font-medium">Beauty and Wellness Apps</h1>
                        </div>
                    </div>

                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#a6b7e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='PetCareApp' className="w-9 h-9" src={PetCareApp} />
                            <h1 className="text-[20px] font-medium">Pet Care App</h1>
                        </div>
                    </div>

                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#c0a6e5] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='HomeServicesApp' className="w-9 h-9" src={HomeServicesApp} />
                            <h1 className="text-[20px] font-medium">Home Services App</h1>
                        </div>
                    </div>

                    <div className=" hover:shadow-custom mt-[40px] w-[550px] h-[65px] border-[#e5a6aa] border-[2px] rounded-[10px] ">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt='GroceryDeliveryApp' className="w-9 h-9" src={GroceryDeliveryApp} />
                            <h1 className="text-[20px] font-medium">Grocery Delivery App</h1>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className=" font-semibold mt-[40px] text-[40px] text-center">App Features for <span className="text-[#0652dd]">Service Providers, Customers
            </span> and<span className="text-[#0652dd]"> Admins</span></h1>
            <p className="text-[18px] w-[910px] m-auto text-center mb-[20px]">Developing an on-demand application is equal to building three applications - Customer, Service Provider,
                and Admin. At BitFront, we specialize in creating an efficient experience for all the stakeholders.</p>
            <div className="flex justify-center items-center gap-[7rem]">
                <div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='Onboarding' className="w-9 h-9" src={Onboarding} />
                        <p className="text-[19px] font-medium ml-[35px]">Onboarding</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='BookingMatching' className="w-9 h-9" src={BookingMatching} />
                        <p className="text-[19px] font-medium ml-[35px]">Booking/Matching</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='Pushnotifications' className="w-9 h-9" src={Pushnotifications} />
                        <p className="text-[19px] font-medium ml-[35px]">Push Notifications</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='Real time Tracking' className="w-9 h-9" src={RealtimeTrackingApps} />
                        <p className="text-[19px] font-medium ml-[35px]">Real-time Tracking</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='ServiceSelection' className="w-9 h-9" src={ServiceSelection} />
                        <p className="text-[19px] font-medium ml-[35px]">Service Selection</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='MultiplePaymentOptions' className="w-9 h-9" src={MultiplePaymentOptions} />
                        <p className="text-[19px] font-medium ml-[35px]">Multiple Payment Options</p>
                    </div>
                </div>

                <div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='OrderRequestsandDetails' className="w-9 h-9" src={OrderRequestsandDetails} />
                        <p className="text-[19px] font-medium ml-[35px]">Order Requests and Details</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='AdvancedRouteOptimizations' className="w-9 h-9" src={AdvancedRouteOptimizations} />
                        <p className="text-[19px] font-medium ml-[35px]">Advanced Route Optimizations</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='ProofofDelivery' className="w-9 h-9" src={ProofofDelivery} />
                        <p className="text-[19px] font-medium ml-[35px]">Proof of Delivery</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='PaymentReports' className="w-9 h-9" src={PaymentReports} />
                        <p className="text-[19px] font-medium ml-[35px]">Payment Reports</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='Pushnotifications' className="w-9 h-9" src={Pushnotifications} />
                        <p className="text-[19px] font-medium ml-[35px]">Push Notifications</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='ServiceAvailability' className="w-9 h-9" src={ServiceAvailability} />
                        <p className="text-[19px] font-medium ml-[35px]">Service Availability</p>
                    </div>
                </div>

                <div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='OrdersBookingManagement' className="w-9 h-9" src={OrdersBookingManagement} />
                        <p className="text-[19px] font-medium ml-[35px]">Orders & Booking Management</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='ServiceTracking' className="w-9 h-9" src={ServiceTracking} />
                        <p className="text-[19px] font-medium ml-[35px]">Service Tracking</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='OverseeReviewsandFeedback' className="w-9 h-9" src={OverseeReviewsandFeedback} />
                        <p className="text-[19px] font-medium ml-[35px]">Oversee Reviews and Feedback</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='ServiceProvidersManagement' className="w-9 h-9" src={ServiceProvidersManagement} />
                        <p className="text-[19px] font-medium ml-[35px]">Service Providers Management</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='AnalyticsReports' className="w-9 h-9" src={AnalyticsReports} />
                        <p className="text-[19px] font-medium ml-[35px]">Analytics & Reports</p>
                    </div>
                    <div className=" mb-[20px] flex items-center">
                        <img alt='PricingandCommissionManagement' className="w-9 h-9" src={PricingandCommissionManagement} />
                        <p className="text-[19px] font-medium ml-[35px]">Pricing and Commission Management</p>
                    </div>
                </div>
            </div>

            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> On-demand</span> App Development Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 w-[600px]">
                We add creativity and excellence in every stage of the on-demand app development process.
            </p>
            <div className="flex gap-1 items-center justify-center mt-8">
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
            </div>

            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className=" flex justify-center items-center gap-[5rem] mt-[30px]">
                <div className="w-[600px]">
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        At BitFront, we excel in blending market expertise with cutting-edge technology and features. This
                        specialization establishes us as the trusted on-demand app development company in India.
                    </p>
                </div>
                <div>
                    <div className="flex gap-[5rem]">
                        <div className="w-[300px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='AgileDevelopmentMethodology' className="w-9 h-9" src={AgileDevelopmentMethodology} />
                                <h1 className="text-[18px] font-medium">Agile Development Methodology</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='EndtoendSupport' className="w-9 h-9" src={EndtoendSupport} />
                                <h1 className="text-[18px] font-medium">End-to-end Support</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Usercentricsolutionss' className="w-9 h-9" src={Usercentricsolutionss} />
                                <h1 className="text-[18px] font-medium">User-centric solutionss</h1>
                            </div>
                        </div>


                        <div className="w-[325px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='AccesstoToptierTalent' className="w-9 h-9" src={AccesstoToptierTalent} />
                                <h1 className="text-[18px] font-medium">Access to Top-tier Talent</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='ScalabilityAssurance' className="w-9 h-9" src={ScalabilityAssurance} />
                                <h1 className="text-[18px] font-medium">Scalability Assurance</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='DesignedArchitecture' className="w-9 h-9" src={DesignedArchitecture} />
                                <h1 className="text-[18px] font-medium">Designed Architecture</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" mt-[40px] m-auto p-[40px] w-[1320px] h-[97px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[20px] font-semibold text-[#fff]">Collaborate for a custom on-demand app and add value to customer experience.</h1>
            </div>
            <h1 className="text-[40px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="emergeSite.png" src="/images2/emergeSite.png"></img>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OnDemandAppDevelopment
