import React, { useState } from 'react';
import Header from '../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "./Footer";
import FAQ from '../components/FAQ/FAQ';
import WebsiteDesign from '../assets/images/WebsiteDesign.png';
import landingwebsiteimage from '../assets/images/landingwebsiteimage.png';
import Framewad from '../assets/images/Framewad.png';
import customwebsitedevelopment from '../assets/images/customwebsitedevelopment.png';
import progressiveweb from '../assets/images/progressiveweb.png';
import CMSDEV from '../assets/images/CMSDEV.png';
import hhelth from '../assets/images/hhelth.png';
import EcommerceSolutions from '../assets/images/EcommerceSolutions.png';
import networking from '../assets/images/networking.png';
import banking from '../assets/images/banking.png';
import Travel from '../assets/images/Travel.png';
import Media from '../assets/images/Media.png';
import EntertainmentWebSolutions from '../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../assets/images/EdutechSolutions.png';
import html from '../assets/images/html.png';
import css from '../assets/images/css.png';
import javascript from '../assets/images/javascript.png';
import react from '../assets/images/react.png';
const Webdesigningdevelopment = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/Contact-us');
    };
    const [faqs, setFaqs] = useState([
        {
            question: "Custom-Based Solutions",
            answer:
                "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
            open: false
        },
        {
            question: "Industrial Experts",
            answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
            open: false
        },
        {
            question: "Seamless Communication",
            answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
            open: false
        },
        {
            question: "Latest Technology",
            answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
            open: false
        },

    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];
    const reviews = [
        {
            rating: 5.0,
            reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
            author: "Founder & CEO, Larrea Wealth Management",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
            author: "Co-Founder & CEO, FLOHOM",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
            author: "Founder & CEO, FilmUp",
            verified: "Verified Review"
        },
        {
            rating: 5.0,
            reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
            author: "Founder, Level7 Legal",
            verified: "Verified Review"
        }
    ];
    return (
        <div>
            <Header />
            <div className="max-w-7xl h-[580px]	 bg-white rounded-2xl m-auto mt-[80px] ">
                <div className="flex justify-center  p-[30px]">
                    <div>
                        <h1 className="text-[40px] font-bold">Website
                            <span className="text-[#0652dd]"> Design</span> and<br /><span className="text-[#0652dd]">Development</span></h1>
                        <p className="text-[18px]">
                            We design and build industry-leading web-based products that<br />
                            bring value to your customers, delivered with compelling UX.<br />
                            Establish a strong digital presence for your business with modern<br />
                            web development.
                        </p>
                        <li className="text-[18px] pt-[10px]">Web Application Development</li>
                        <li className="text-[18px] pt-[10px]">E-commerce Web Development</li>
                        <li className="text-[18px] pt-[10px]">Website Design and Redesign</li>
                        <li className="text-[18px] pt-[10px]">Web Portal Development</li>
                        <li className="text-[18px] pt-[10px]">Web Support and Maintenance</li>
                        <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd]	border-2 w-[200px] mt-10 h-[40px]">TALK TO OUR EXPERT</button>
                    </div>
                    <img alt='WebsiteDesign' className="w-[656px] h-[503px]" src={WebsiteDesign} />
                </div>
            </div>

            <div className="h-[148.8px] ml-[300px] mt-[80px] overflow-hidden w-[1300px]">
                <div className="flex items-center gap-2">
                    <div className="w-24 h-0.5 mb-px bg-black"></div>
                    <h1 className="text-2xl pb-px font-bold"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
                    <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                </div>
            </div>

            <div className="w-[1900px] h-[622px] bg-white m-auto mt-[30px]">
                <div className="flex justify-center items-center pt-[35px]">
                    <div>
                        <h1 className="text-[40px] font-bold">
                            Best  <span className="text-[#0652dd]">Web Development Company</span> in India
                        </h1>
                        <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
                            Have you been running your business for a while but haven't gone online<br />
                            yet? Want to take your business to the next level of success? Need to create<br />
                            a website from scratch or update your current one? No matter what industry<br />
                            you're in, our skilled team at BitFront can build a website that goes beyond<br />
                            your expectations in terms of performance and usability. We offer a single<br />
                            destination for various web development services, delivering fully personalized<br />
                            and adaptable websites - providing world-class solutions for our valued clients.
                        </p>
                        <p className="text-[#242424] text-[18px] pt-[15px] leading-[29px]">
                            We're a top <b><u>web design and development company in India</u></b>. We can help<br />
                            define your brand and increase demand for your products or services by<br />
                            focusing on your customers and using data. We know how important it is to<br />
                            have a great website nowadays, so we include things like <b>SEO, PPC,</b> and<br />
                            <b>content marketing</b> to get outstanding results. As your website design<br />
                            and development company, let us guide you to the right website platform<br />
                            selection.
                        </p>
                    </div>
                    <img alt='landingwebsiteimage' className="w-[626px] h-[473px]" src={landingwebsiteimage} />
                </div>
            </div>

            <div className="flex justify-center gap-10 mt-[29px]">
                <div>
                    <h1 className=" leading-[55px] text-5xl font-bold">
                        <span className="text-[#0652dd]">Web Development Services </span>We<br /> Offer</h1>
                    <p className="text-[18px] leading-[29px] py-[10px]">
                        Our team has lots of experience and knows the newest ways to code<br />
                        websites. When we make your app, we'll use the newest ideas in<br />
                        development. We'll make sure the whole process matches exactly what you<br />
                        want and need.
                    </p>
                    <p className="text-[18px] leading-[29px] py-[10px]">
                        BitFront specializes in website design and development services. Our web<br />
                        experiences are high-performing, feature-packed and digitally<br />
                        transformative, designed to be user-friendly, fully functional, very secure<br />
                        and able to scale as your enterprise grows. Analyzing what technology<br />
                        works the best for you and with the most appropriate tools and perfect<br />
                        structure, we will provide you with a unique and intuitive website to attract<br />
                        your business demography.
                    </p>
                    <button className="w-[250px] h-[40px] hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
                        <b>TALK TO OUR DEVELOPERS</b></button>
                </div>
                <div>
                    <div className=" mb-[20px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='Framewad' src={Framewad} />
                            <h4 className=" text-[20px] font-bold">Web Application Development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            We cover all aspects of design, integration, and maintenance. We've
                            perfected our way of making web apps from start to finish over a
                            long time. Your app is guaranteed to be secure, scalable, and work
                            equally well across all devices and platforms.
                        </p>
                    </div>

                    <div className=" mb-[20px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='customwebsitedevelopment' src={customwebsitedevelopment} />
                            <h4 className=" text-[20px] font-bold">E-commerce Web Development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            Create an online store that's easy and enjoyable for customers to use. We make
                            sure it works well with your current technology and any other tools you use.
                            Whether you want a brand-new online shop or just some improvements to what
                            you already have, our team has the best skills to help you.
                        </p>
                    </div>

                    <div className=" mb-[20px] w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='progressiveweb' src={progressiveweb} />
                            <h4 className=" text-[20px] font-bold">Progressive web app development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            We help businesses combine the best of web and mobile technologies by giving
                            them a platform that runs offline and is similar to a mobile application.
                            Our team of PWA developers builds solutions that increase the load time,
                            better the engagement, and lower the app size by a manifold.
                        </p>
                    </div>

                    <div className="w-[556px] h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
                        <div className="flex items-center gap-1">
                            <img alt='CMSDEV' src={CMSDEV} />
                            <h4 className=" text-[20px] font-bold">CMS Development</h4>
                        </div>
                        <p className="text-[17px] pl-2">
                            At BitFront, we specialize in creating content management systems that
                            are scalable, have a high concurrency rate, and make the process of
                            managing content easy, across multiple formats. Our team specializes
                            in creating a multi-platform web architecture that supports websites,
                            portfolios, and blogs.
                        </p>
                    </div>
                </div>
            </div>

            <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
                <div className="w-full h-auto bg-[#fafafa] p-5">
                    <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
                        BitFront Reviews 5.0
                        <span className="text-[#e62415] flex items-center">
                            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                        </span>
                    </p>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-7">
                        {reviews.map((review, index) => (
                            <div key={index} className="w-full md:w-72 h-auto shadow-md bg-white rounded-lg p-6">
                                <p className="text-lg flex gap-2 items-center">
                                    {review.rating}
                                    <span className="text-[#e62415] flex items-center">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </span>
                                </p>
                                <p className="text-base pt-4">
                                    "{review.reviewText}"
                                    <br /><br />
                                    {review.author}
                                    <br /><br />
                                    {review.verified}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <h1 className="font-bold text-[40px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Web Designing Development</span> Process</h1>
            <p className="text-[18px] text-center mt-4">
                Our web developers create websites based on your brand's vision. We mix that with what's popular in the market and use strong tools to make sure your<br />
                website is ready for the future.
            </p>
            <div className="flex gap-1 items-center justify-center mt-8">
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
                <div className="w-[141px] h-[1px] bg-[#000]"></div>
                <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
            </div>

            <h1 className="text-5xl text-center mt-15 font-bold mt-9">Our <span className="text-[#0652dd]"> Multi-industry</span> Experience</h1>
            <br />
            <p className="text-lg text-center">
                Every type of industry, whether old-fashioned or modern, has the chance to become a digital leader with a strong website. At BitFront, we assist<br />
                businesses in reaching their full potential by creating a strong online presence for them.
            </p>
            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>

            <h1 className="text-center text-4xl mt-12 font-bold">
                <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex justify-center gap-9 mt-11 ">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                    <br />
                    <h5 class="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Global quality standards.</h5>
                    <br />
                    <h5 class="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Monthly/yearly hiring.</h5>
                    <br />
                    <h5 class="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Flexible office hours depend on the timezone.</h5>
                    <br />
                    <h5 class="text-lg flex items-center gap-2  ">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Daily/weekly/monthly reporting via email.</h5>
                </div>
                <div>

                    <div className="App">

                        <div className="faqs">
                            {faqs.map((faq, index) => (
                                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-blue-700 w-3/4 h-48 mx-auto text-white text-center pt-5 rounded-lg mt-10">
                <h3 class="text-center text-3xl font-bold">How much does it cost to develop a web application?</h3>
                <p class="text-lg pt-4">
                    On average, website development costs can vary from $10,000 to $150,000 based on the type of
                    website, design, and web developer costs.</p>
                <div>
                    <button onClick={handleClick} class="w-[140px] h-[42px] bg-white text-[#0652dd] rounded-[20px] mt-[20px]"><b>Contact Us</b></button>
                </div>
            </div>

            <h1 className="text-[38px] text-center font-bold mt-[40px]">
                <span className="text-[#0652dd]">Backing your web platform with</span> best-in-class technologies
            </h1>
            <p className="text-center text-[18px]">
                The web development space is ripe for innovation. At BitFront, we use the best frameworks
                and technologies that would make your web platform future-<br />ready, no matter how the market trends move.
            </p>
            <div className="flex items-center justify-center gap-[170px] mt-[40px]">
                <div className="text-center">
                    <img alt='html' className="w-[70px] h-[70px]" src={html} />
                    <p>HTML</p>
                </div>
                <div className="text-center">
                    <img alt='css' className="w-[70px] h-[75px]" src={css} />
                    <p>CSS</p>
                </div>
                <div className="text-center">
                    <img alt='javascript' className="w-[64.84px] h-[70px]" src={javascript} />
                    <p>JavaScript</p>
                </div>
                <div className="text-center">
                    <img alt='react' className="w-[75px] h-[70px]" src={react} />
                    <p>React</p>
                </div>
            </div>

            <h1 className="text-[40px] text-center font-bold mt-[70px]">Looking for the best Mobile App Development Services for your<br />
                business?</h1>
            <p className="text-[18px] text-center">We help top brands deliver quality products. Share your new business needs, and let us make it a success.</p>

            <div className="bg-white rounded-[30px] w-[1242px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                <img className="h-auto w-full rounded-[40px]" alt="emergeSite.png" src="/images2/emergeSite.png"></img>
                <div className="w-[344px] absolute top-[83px] right-[134px]">
                    <p className="text-lg">
                        Re-building Emerge - A finance app making it easy for financial experts to make money.
                    </p>
                    <div className="inline-row">
                        <div>
                            <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">4.4<p className="text-[#DE5B48] text-[10px] flex gap-1"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></p></span>
                            <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                        </div>
                        <div>
                            <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                            <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                        </div>
                    </div>
                    <h1 className="my-3">1 Million+ downloads</h1>
                    <div className="flex gap-4">
                        <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                            <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" /><p><b>View Case Study</b></p>
                        </button>
                        <img className="w-[124px] h-[36px] cursor-pointer" alt="41p.jpg" src="/images2/41p.jpg" />
                    </div>
                </div>
                <div>
                    <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                    <p className="my-2">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                        in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                        scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                </div>
                <div className="flex items-center gap-4">
                    <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                    <div>
                        <p>Corey Smith</p>
                        <p>CEO, Emerge</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Webdesigningdevelopment
