import './Mobile.css';
import { FaStar } from "react-icons/fa6";
import React, { useState } from 'react';
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from 'react-router-dom';
import FAQ from '../components/FAQ/FAQ';
import Footer from "./Footer";
import Header from "../components/layout/Header";
import MobileAppDevelopment from '../assets/images/MobileAppDevelopment.png';
import LeadingMobileApplicationDevelopment from '../assets/images/LeadingMobileApplicationDevelopment.png';
import platformmobileappdevelopmentservices from '../assets/images/platformmobileappdevelopmentservices.png';
import Crossmobileappdevelopmentservices from '../assets/images/Crossmobileappdevelopmentservices.png';
import Swift from '../assets/images/Swift.png';
import kotlin from '../assets/images/kotlin.png';
import AndroidJava from '../assets/images/AndroidJava.png';
import ReactNative from '../assets/images/ReactNative.png';
import Flutter from '../assets/images/Flutter.png';
import DotNetMAUI from '../assets/images/DotNetMAUI.png';
import iOS from '../assets/images/iOS.png';
import Androidmkl from '../assets/images/Androidmkl.png';
const Mobile = () => {

  const handleClickContactus = () => {
    navigate('/Contact-us');
  };

  const navigate = useNavigate();

  const [faqs, setFaqs] = useState([
    {
      question: "Custom-Based Solutions",
      answer:
        "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
      open: false
    },
    {
      question: "Industrial Experts",
      answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
      open: false
    },
    {
      question: "Seamless Communication",
      answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
      open: false
    },
    {
      question: "Latest Technology",
      answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
      open: false
    },

  ]);
  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const images = [
    {
      alt: 'blogimage',
      src: 'https://blogimage.vantagecircle.com/content/images/2021/01/Employee-Management-Meaning-Importance-Tips-Tools---More.png'
    },
    {
      alt: 'invoicera',
      src: 'https://www.invoicera.com/blog/wp-content/uploads/2019/01/online-staff-management-software.png'
    },
    {
      alt: 'blogimage',
      src: 'https://blogimages.softwaresuggest.com/blog/wp-content/uploads/2018/05/12173744/7-Employee-Management-Strategies-to-Keep-Your-Employees-Satisfied-01.png'
    }
  ];
  const reviews = [
    {
      rating: 5.0,
      reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
      author: "Founder & CEO, Larrea Wealth Management",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
      author: "Co-Founder & CEO, FLOHOM",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
      author: "Founder & CEO, FilmUp",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
      author: "Founder, Level7 Legal",
      verified: "Verified Review"
    }
  ];
  return (
    <div>
      <Header />
      <div className="max-w-7xl h-auto bg-white rounded-2xl m-auto mt-[40px]">
        {/* <div className="mob-flex"> */}
        <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
          <div>
            <h1 className="text-[40px]">Mobile <span className="text-[#0652dd]">App Development</span><br /> Services</h1>
            <p className="text-[19px]">
              BitFront is the name behind products that have collected over $15 billion<br />
              in revenue, received funding, and frequently made it to the stores’ <br />
              featured lists. We are the world’s favorite Mobile app development<br />
              service providers.
            </p>
            <li className="text-[18px]">Mobile App Design.</li>
            <li className="text-[18px]">Testing and QA.</li>
            <li className="text-[18px]">Maintenance and Support.</li>
            <li className="text-[18px]">Mobile App Modernization.</li>
            <li className="text-[18px]">Mobile App Integration.</li>
            <br />
            <br />
            <button className=" hover:bg-white hover:text-[#0652dd] bg-[#0652dd] border border-[#0652dd] rounded-full text-white h-10 transition-all duration-200 w-[240px]"><b>TALK TO OUR EXPERTS</b></button>
          </div>
          <img alt='MobileAppDevelopment' className="w-[550px] h-[485]" src={MobileAppDevelopment} />
        </div>
      </div>
      <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[198.8px] mt-[80px] md:ml-4 xl:ml-[300px] overflow-hidden">
        <div className="flex items-center gap-[0.5rem]">
          <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
          <h1 className="text-[24px] pb-[10px]"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
        </div>
        <div className="cont-no">
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
          <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" />
          <img alt='media.licdn' className="w-[250px] h-[150px]" src="https://media.licdn.com/dms/image/C4D0BAQHAMm5THEtwJA/company-logo_200_200/0/1676531835191/aurum_liv_app_logo?e=2147483647&v=beta&t=UxfwMjM0C61dIv-AO4HM2w5zzly638T1zVC3TPQCsqE" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[150px] h-[50px] bg-slate-300" src="https://www.abroadworks.com/v2/wp-content/themes/abroadworks-theme/assets/images-stage/footer-logo.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
        </div>
      </div>
      <div className="w-full h-auto xl:mb-[40px] bg-[#fff]">
        <div className="flex flex-col md:flex-row gap-8 items-center justify-center pt-12 mx-auto max-w-screen-xl">
          <div className="text-center md:text-left px-4">
            <h2 className="text-2xl md:text-4xl font-bold">
              Leading Mobile <span className="text-[#0652dd]">Application Development</span><br /> Company in India
            </h2>
            <p className="text-base md:text-lg leading-6 mt-4">
              At BitFront, we promise to create mobile apps that work on all platforms<br />
              while staying within your budget. Our goal is to help your business grow as<br />
              much as possible. We handle many projects in various industries worldwide.
            </p>
            <p className="text-base md:text-lg leading-6 mt-4">
              Being the best <b><u>Mobile App Development Company in India</u></b>, we have<br />
              proven expertise in crafting next-gen mobile solutions that deliver faster and<br />
              quality mobile apps. Choose your ideal, cost-effective, flexible hiring model<br />
              that matches your Mobile App Development needs. We use the newest<br />
              technologies to give you the best app services, even after your app is up<br />
              and running. Our team always goes the extra mile to create personalized<br />
              and <span className="text-[#0652dd]">customized app solutions</span> to make sure<span className="text-[#0652dd]"> our clients</span> are happy.
            </p>
            <p className="text-base md:text-lg leading-6 mt-4">
              BitFront is a team of digital transformation experts with diverse skill sets<br />
              delivering custom and world-class digital solutions to businesses of all <br />
              scales. From digital product design to development, we deliver remarkable<br />
              digital experiences.
            </p>
          </div>
          <img
            alt='LeadingMobileApplicationDevelopment'
            className="w-full md:w-1/2 h-auto max-w-xs mx-auto"
            src={LeadingMobileApplicationDevelopment}
          />
        </div>
      </div>
      <h1 className="text-[40px] text-center font-semibold mb-[40px] ">The Software Development Agency<span className="text-[#0652dd]"> with Multi-industry Experience</span></h1>
      <div className="flex flex-wrap gap-[2rem] justify-center items-center">
        {images.map((image, index) => (
          <img
            key={index}
            alt={image.alt}
            className="w-full max-w-[504px] h-auto sm:w-[400px] md:w-[300px] lg:w-[504px] object-cover"
            src={image.src}
          />
        ))}
      </div>
      <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
        <div className="w-full h-auto bg-[#fafafa] p-5">
          <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
            BitFront Reviews 5.0
            <span className="text-[#e62415] flex items-center">
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
            </span>
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-7">
            {reviews.map((review, index) => (
              <div key={index} className="w-full md:w-72 h-auto shadow-md bg-white rounded-lg p-6">
                <p className="text-lg flex gap-2 items-center">
                  {review.rating}
                  <span className="text-[#e62415] flex items-center">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </span>
                </p>
                <p className="text-base pt-4">
                  "{review.reviewText}"
                  <br /><br />
                  {review.author}
                  <br /><br />
                  {review.verified}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div> 
      <h1 className=" mb-[40px] text-4xl text-center mt-12 font-bold">Mobile Application Development Process
        <span className="text-[#0652dd]"> How do we do it?</span>
      </h1>
      <div className="flex gap-1 items-center justify-center mt-8">
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">1</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">2</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">3</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">4</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">5</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">6</div>
        <div className="w-[141px] h-[1px] bg-[#000]"></div>
        <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center pt-3 text-22">7</div>
      </div>
      <div className="xl:w-[1903px] xl:h-auto bg-[#fff] mt-[90px]">
        <h1 className="text-[40px] text-center pt-[70px]">Cross-platform mobile app development services</h1>
        <p className="text-[18px] text-center mb-[20px]">Our expert team specializes in creating robust and scalable mobile applications that
          redefine industry standards within the mobile-first landscape. Our<br />
          developers specialize in custom application development services that are aligned
          with the market needs, your company goals, and technological <br />advancements.</p>
        <div className="flex flex-wrap items-center justify-center gap-[6rem]">
          <div>
            <h1 className="text-[32px] font-medium ">Our iOS Mobile App Development<br /> Services</h1>
            <p className="text-[18px] leading-[25px]">
              We specialize in delivering a superior native experience through iOS<br />
              applications, ensuring feature-rich capabilities that align with strict<br />
              compliance standards, including those about email, device health, device<br />
              properties, and system security.
            </p>
            <br />
            <p className="text-[18px] leading-[25px]">
              Our <span className="text-[#0652dd]">iOS app development services </span> cover the following key aspects:
            </p>
            <li className="text-[18px] leading-[25px]">We focus on creating an engaging user interface and experience.</li>
            <li className="text-[18px] leading-[25px]">We offer tailored solutions to meet your specific requirements, ensuring<br /> your app stands out.</li>
            <li className="text-[18px] leading-[25px]">Our dedicated Quality Assurance team conducts thorough testing to<br />
              ensure the highest quality and performance.</li>
            <li className="text-[18px] leading-[25px]">We seamlessly integrate third-party services and custom APIs.</li>
            <li className="text-[18px] leading-[25px]">We ensure smooth deployment across a range of Apple devices,<br />
              making your app accessible.</li>
            <br />
            <br />
            <button
              className="w-[150px] h-[44px] font-semibold bg-[#0652dd] text-[#fff] rounded-[50px] border-[1px] border-[#0652dd] cursor-pointer sm:ml-[180px] hover:bg-[#fff] hover:text-[#0652dd] text-[17px]">
              Read More
            </button>
          </div>
          <img alt='plat from' className=" lg:w-[422px] lg:h-[440px] md:w-[292px] md:h-[300px]" src={platformmobileappdevelopmentservices} />
        </div>
        <div className="flex flex-wrap items-center justify-center gap-[6rem] mt-[70px]">
          <img alt='Cross mobile' className=" w-[292px] h-[500px]" src={Crossmobileappdevelopmentservices} />
          <div>
            <h1 className="text-[32px]">Our Android  Mobile App Development<br /> Services</h1>
            <p className="text-[18px] leading-[25px]">
              We specialize in delivering a superior native experience through iOS<br />
              applications, ensuring feature-rich capabilities that align with strict<br />
              compliance standards, including those about email, device health, device<br />
              properties, and system security.
            </p>
            <br />
            <p className="text-[18px] leading-[25px]">
              Our<span className="text-[#0652dd]">Android  app development services </span> cover the following key aspects:
            </p>
            <li className="text-[18px] leading-[25px]">We focus on creating an engaging user interface and experience.</li>
            <li className="text-[18px] leading-[25px]">We offer tailored solutions to meet your specific requirements, ensuring<br /> your app stands out.</li>
            <li className="text-[18px] leading-[25px]">Our dedicated Quality Assurance team conducts thorough testing to<br />
              ensure the highest quality and performance.</li>
            <li className="text-[18px] leading-[25px]">We seamlessly integrate third-party services and custom APIs.</li>
            <li className="text-[18px] leading-[25px]">We ensure smooth deployment across a range of Apple devices,<br />
              making your app accessible.</li>
            <br />
            <br />
            <button
              className="w-[150px] h-[44px] font-semibold bg-[#0652dd] text-[#fff] rounded-[50px] border-[1px] border-[#0652dd] cursor-pointer sm:ml-[180px] hover:bg-[#fff] hover:text-[#0652dd] text-[17px]">
              Read More
            </button>
          </div>
        </div>
      </div>
      <h1 className="text-center text-4xl mt-12 font-bold">
        <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
      <div className="flex flex-wrap justify-center gap-9 mt-11 ">
        <div>
          <h1 className="text-3xl font-bold">
            <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
            <span className="text-[#0652dd]">Digital Success Works</span>
          </h1>
          <p className="text-lg">
            With a proven track record in React Native app development,<br />
            we're the perfect fit for your project. Here's why:
          </p>
          <br />
          <h5 class="text-lg flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Global quality standards.</h5>
          <br />
          <h5 class="text-lg flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Monthly/yearly hiring.</h5>
          <br />
          <h5 class="text-lg flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Flexible office hours depend on the timezone.</h5>
          <br />
          <h5 class="text-lg flex items-center gap-2  ">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Daily/weekly/monthly reporting via email.</h5>
        </div>
        <div>
          <div className="lg:w-[600px]">
            <div className="faqs">
              {faqs.map((faq, index) => (
                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div class="bg-blue-700 mb-[40px] w-3/4 h-auto mx-auto text-white text-center pt-5 rounded-lg mt-10">
        <h3 class="text-center text-3xl font-bold"> How much does it cost to develop a mobile application?</h3>
        <p class="mb-2 sm:text-lg">The average cost of developing a
          <b>Mobile</b> app can be between <b>$3,000 to $35,000 &amp;
            may exceed sometimes</b> based on the number &amp; complexity
          of features, technology incorporation, number of platforms &amp; the location of the development agency.</p>
        <div>
          <button onClick={handleClickContactus} class="w-[140px] h-[42px] mb-4 bg-white text-[#0652dd] rounded-[20px] mt-[10px]"><b>Contact Us</b></button>
        </div>
      </div>
      <h1 className="text-center text-[40px] font-semibold mb-[15px] ">Operating on <span className="app-coli">Powerful Toolset</span></h1>
      <p className="text-center text-[18px] mb-[15px]">We pack your software with a future-ready and time-tested technology stack - an integration that ensures competitive advantage.</p>
      <div className="flex flex-wrap justify-center gap-[10rem] items-center mt-10 ">
        <div>
          <img alt='Swift' className="w-[50px] h-[50px] m-auto" src={Swift} />
          <h4 className="text-center">Swift</h4>
        </div>
        <div>
          <img alt='kotlin' className="w-[50px] h-[50px] m-auto" src={kotlin} />
          <h4 className="text-center">kotlin</h4>
        </div>
        <div>
          <img alt='AndroidJava' className="w-[50px] h-[50px] m-auto" src={AndroidJava} />
          <h4 className="text-center">Android Java</h4>
        </div>
        <div>
          <img alt='ReactNative' className="w-[50px] h-[50px] m-auto" src={ReactNative} />
          <h4 className="text-center">React Native</h4>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-[9rem] items-center mt-10 ">
        <div>
          <img alt='Flutter' className="w-[50px] h-[50px] m-auto" src={Flutter} />
          <h4 className="text-center">Flutter</h4>
        </div>
        <div>
          <img alt='DotNetMAUI' className="w-[50px] h-[50px] m-auto" src={DotNetMAUI} />
          <h4 className="text-center">Dot Net MAUI</h4>
        </div>
        <div>
          <img alt='iOS' className="w-[50px] h-[50px] m-auto" src={iOS} />
          <h4 className="text-center">Strada iOS</h4>
        </div>
        <div>
          <img alt='Androidmkl' className="w-[50px] h-[50px] m-auto" src={Androidmkl} />
          <h4 className="text-center">Strada Android</h4>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Mobile
