import React from 'react'

const DevOps = () => {
  return (
    <div>
      <div className="p-1">
        <h1 className="xl:text-[30px] lg:text-[30px] md:text-[20px] text-[30px] text-[#192734] leading-10 mb-5">It's time to consider opting for DevOps to improve your company’s productivity.</h1>
        <p className="xl:text-[17px] lg:text-[17px] md:text-[16px] text-[16px] text-[#4c5a67] font-normal mb-[30px]">As a DevOps development company, we combine skills in software development and automation to
          create a unified and better approach to both.</p>
        <div className="grid lg:grid-cols-2 gird sm:grid-cols-1">
          <div className='space-y-5'>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Managed DevOps Services</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Cloud Computing</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">DevOps Automation Service</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Security & Compliance</h1>
            </div>
          </div>
          <div className='space-y-5 ld:mt-0 md:mt-4 mt-4'>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">DevOps Infrastructure Automation</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Continuous Integration and Continuous Deployment (CI/CD)</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">DevOps Implementation service</h1>
            </div>
            <div className="flex items-center">
              <img alt='green tick' src="https://www.narolainfotech.com/wp-content/themes/narola-infotech/inc/img/check-svg.svg" className="w-[24px] h-[24px]" />
              <h1 className=" ml-[13px] cursor-pointer leading-[24px] underline hover:text-[#0652dd] text-[16px] text-[#4c5a67] md-[20px]">Microservices in DevOps</h1>
            </div>
          </div>
        </div>
        <button className=" font-medium w-[200px] h-[50px] border-[1px] border-[#0652dd] bg-[#0652dd] text-[#fff] rounded-[5px] mt-11">Get More Info</button>
      </div>
    </div>
  )
}

export default DevOps;
